import React from 'react';
import {getLocalized} from './../../../utilities/Utils.js';

export default function ImageWith1TextMediumCard(props) {
	
	const clicked = () => {
		props.clicked(props.val);
	}

	const {val} = props;
	return(
		<div className="IWSTLFC-wrapper" onClick = {clicked}>
			<div>
				<img src={val.entityData.displayLogo} className="IWSTLFC-img" alt={'product'}/>
			</div>
			<div className="card-text">{getLocalized(val.entityData.displayTitle)}</div>
			{(val.entityData.displaySubtext)?
			<div className="card-sub-text"><div dangerouslySetInnerHTML={{__html: getLocalized(val.entityData.displaySubtext)}}></div></div>:null
			}
		</div>
	)
}