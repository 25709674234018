export const getUTCTimeStamp = (time) => {
  const x = new Date(time);
  const UTCSeconds = x.getTime() + x.getTimezoneOffset() * 60 * 1000;
  return new Date(UTCSeconds);
};

export const timeIn12HourFormat = (timestamp) => {
  return new Intl.DateTimeFormat("en-IN", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(new Date(Number(timestamp)))
}

export const dateFormat = (timestamp) => {
  return new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }).format(new Date(Number(timestamp)))
}

export const SuccessDateFormat = (timestamp) => {
  return new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "2-digit",
  }).format(new Date(Number(timestamp)))

}