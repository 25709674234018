import { GA4React } from "ga-4-react";

const MID = process.env.REACT_APP_FIREBASE_MID;
console.log('MID>>>>', MID);
const ga4react = new GA4React("G-EZWEMSRQ8F");


const trackPathForAnalytics = (data) => {
    const { path, search, title } = data;
    try {
        setTimeout(_ => {
            ga4react.initialize()
            .then((ga) => {
                console.log(`connected to GA successfully`);
                ga.pageview(path, search, title);
            })
            .catch((err) => console.error(`Analytics failed: ${err}`));
        }, 4000);
      } catch (err) {
            console.error(err);
      }
};

export default trackPathForAnalytics;