import React, {useState, useEffect, useContext, useRef} from 'react';
import TypeAheadResult from './../common/TypeAheadResult.js';
import SearchBox from './SearchBox.js';
import Loader from './../common/Loader.js';
// import productSearchResponseList from './../../config/searchResponse.js';
import "./Style.css";
import SearchApiHandler from './SearchApiHandler';
import SearchResults from './SearchResults.js';
import {publishEvent, getAutosuggetBundle} from './../../utilities/ManageEvents.js';
import { AppContext } from '../../App.js';

// const groupBy = (xs, key) => {
// 	return xs.reduce((rv, x) => {
// 	    (rv[getLocalized(x[key])] = rv[getLocalized(x[key])] || []).push(x);
// 		return rv;
// 	}, {});
// };

export default function SearchPage() {
	const [productSearchResponseList, setProductSearchResponseList] = useState([]);
	const [productSearchCompleteList, setProductSearchCompleteList] = useState([]);
	const [loading, setLoading] = useState(false);
	const controllerRef = useRef();
	const context = useContext(AppContext)

	useEffect(() => {
		context.header.set({type: 'SEARCH_STORE_NAME', text: ''})

		publishEvent('ON_LOAD_SEARCH_SCREEN');
	}, [])

	const clearSuggestions = () => {
		setProductSearchResponseList([]);
		setProductSearchCompleteList([]);
	}

	const onSuggestionSelection = (item, index) => {
		publishEvent('ON_SUBMIT_AUTO_SUGGEST', {idx: index});
		window.location.assign('/product?jpin='+ item.jpin)
	}

	const onEnterInSearch = text => {
		publishEvent('ON_SUBMIT_SEARCH_QUERY', {qt: text});
		setLoading(true);
		SearchApiHandler.getSuggestions(text)
          	.then(resp => {
				clearSuggestions();
            	var products = resp['productDetailsList'][0]['browseEntityDetailsList'];
            	// var groupedProducts = groupBy(products, 'brand');
            	// let prArr = Object.keys(groupedProducts).map(i => {
            	// 	let obj = {};
            	// 	obj[i] = groupedProducts[i];
            	// 	return obj;
            	// })
            	setProductSearchCompleteList(products);
            }).catch(error => {
              console.log(error);
            }).finally(resp => {
            	setLoading(false);
            });     	
    }


	const onTextChange = text => {
		publishEvent('ON_TYPE_SEARCH_QUERY', {qt: text});
		let size = 0 ;
	    for (let i in productSearchCompleteList) {
	        if (productSearchCompleteList.hasOwnProperty(i)) size++;
	    }
		if(size > 0) {
			clearSuggestions();
		}
		if (text.length === 0 ) {
			clearSuggestions();
		} else {
			if (controllerRef.current) controllerRef.current.abort()
			const controller = new AbortController()
			controllerRef.current = controller
			setLoading(true)
		 	SearchApiHandler.getSuggestions(text, {
        signal: controllerRef.current?.signal,
      })
          	.then(resp => { 
            	if(productSearchCompleteList.length === 0) {
            		setProductSearchResponseList(resp['productDetailsList'][0]['browseEntityDetailsList']);
            		publishEvent('ON_LOAD_AUTO_SUGGEST', getAutosuggetBundle(resp));
							}
							setLoading(false)
            }).catch(error => {
							if (error.name !== "AbortError") {
								setLoading(false)
								console.log(error);
							}
						});
      	}
	}
		
	return(
		<div>
			<SearchBox onTextChange={onTextChange}  onEnter={onEnterInSearch}/>
			{(!loading) ?
				<div>
					{(!productSearchCompleteList || productSearchCompleteList.length === 0) ? <TypeAheadResult onSelection={onSuggestionSelection} items={productSearchResponseList}/> : null}
					<SearchResults items={productSearchCompleteList}/>
				</div> 
				: 
				<Loader />
			}
		</div>)
}