import React, {useState} from 'react';
import "./Style.css";
import { throttle, debounce } from "throttle-debounce";
import { publishEvent } from './../../utilities/ManageEvents.js';
import backIcon from "./../../assets/back-black.svg"

export default function SearchBox(props) {
	const [query, setQuery] = useState('');
	const callTextChanged = (query) => {
		props.onTextChange(query);
	}
	const autocompleteSearchDebounced = debounce(1000, callTextChanged);
    const autocompleteSearchThrottled = throttle(1000, callTextChanged);

	const textEntered = (e) => {
		let text = e.target.value;
		setQuery(text);
		if(text.length < 5) {
			autocompleteSearchThrottled(text)
		} else {
			autocompleteSearchDebounced(text)
		}
    }

    const handleKeyDown = (e) => {
    	if (e.key === 'Enter') {
	    	let q = e.target.value;
    		if (q.length > 0) {
		        props.onEnter(e.target.value);
		    }
		}
	}

	return(
		<div className="search-box-wrapper">
			<div className="search-box search-box-mobile">
				<img src={backIcon} alt="back" className="back" onClick={() => {
						window.history.back();
					} }/>
					<input type="text" 
					placeholder="Search groceries, essentials..." 
					onChange ={textEntered}
					onKeyDown={handleKeyDown} 
					onFocus={() => {publishEvent('ON_FOCUS_SEARCH_BOX')}}
					value={query}
					className="search-text"
					autoFocus={true}/>
			</div>
		</div>)
}