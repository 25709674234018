import React, { useContext } from "react";
import check from "../../assets/check.svg";
import cn from "classnames";
import { AppContext } from "../../App";

export default function Address({ address, selected, selectAddress }) {
  const { user } = useContext(AppContext);

  const selectThisAddress = () => {
    selectAddress(address);
  };
  const addressClass = cn("address-wrapper", {
    "selected-addr": selected && address.addressID === selected.addressID,
  });

  return (
    <>
      <div className={addressClass} onClick={selectThisAddress}>
        <div className="customer-main-info flex">{address.addressName} <img className="check" src={check} alt={"check"} /></div>
        {!user.get && <div className="address-attr">{address.phoneNumber}</div>}{" "}
        <div className="address-attr">{address.addressLine1}</div>
      </div>
    </>
  );
}
