const S4 = () => (((1+Math.random())*0x10000)|0).toString(16).substring(1);

export const RandomUUIDGenrator = () => {
	return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

export const getLocalized = str => {
	if(!str) return str;
	if(str.localizedText) {
		return str.localizedText;
	} else if(str.englishText) {
		return str.englishText;
	}
	return str;
}

export const formatToIndianCurrency = (number = 0) => {
  	return Number(number).toLocaleString("en-IN", {
    	maximumFractionDigits: 2,
    	style: "currency",
    	currency: "INR",
  	});
};

export const formatToIndianCurrencyRounded = (number = 0) => {
  	return Number(number).toLocaleString("en-IN", {
    	minimumFractionDigits: 0,
    	maximumFractionDigits: 0,
    	style: "currency",
    	currency: "INR",
  	});
};

export const formatDate = (date) => {
	const datefor = new Intl.DateTimeFormat('en-GB', { 
	   	month: 'short', 
	    day: '2-digit' ,
	    hour: '2-digit',
	    minute: '2-digit',
	    hour12: true
	});
  	return datefor.format(date);
}

export const getFormattedTime = (timeStamp) => {
  	let dateTime = new Date(timeStamp);
  	let hours = dateTime.getHours();
  	let minutes = "" + dateTime.getMinutes();

  	let ampm = hours >= 12 ? "PM" : "AM";
  	hours = hours % 12;
  	hours = hours ? hours : 12;
  	hours = "" + hours;
  	if (hours.length < 2) hours = "0" + hours;
  	if (minutes.length < 2) minutes = "0" + minutes;
  	return `${hours}:${minutes} ${ampm}`;
};

const applyOffer = (jtOfferPayout, qty, sellingPrice) => {
	switch(jtOfferPayout.offerPayoutType) {
		case 'PER_UNIT_ABSOLUTE_PRICE' :
			return (jtOfferPayout.payout * qty);
		case 'PER_UNIT_PERCENTAGE_DISCOUNT' :
			return ((sellingPrice - (sellingPrice * jtOfferPayout.payout)) * qty);
		case 'PER_UNIT_CASH_DISCOUNT' :
			return ((sellingPrice - jtOfferPayout.payout) * qty);
		case 'CASH_DISCOUNT' :
			return ((sellingPrice * qty) - jtOfferPayout.payout);
		case 'SHIPPING_DISCOUNT' :
		default : 
			return (sellingPrice * qty);
	}
}

const applyPriceCutOffer = (qty, ppc, i) => {
	switch(i.jtOfferCondition.offerConditionType) {
		case 'LISTING_VOLUME_OFFER' :
			if(qty >= i.jtOfferCondition.threshold) {
				return applyOffer(i.jtOfferPayout, qty, ppc);
			}
			break;
		case 'NONE' :
			return applyOffer(i.jtOfferPayout, qty, ppc);
		default :
			break;
	}
}

export const getOfferAndPriceData = product => {
	let listingDetails = product.listingDetailsMap[product.winningListingId];
	let offList = listingDetails.jtOfferBOList;
	let prices = {
		originalPrice: null,
		newPrice: listingDetails.sellingPrice,
	};
	let applliedOffers = {};
	let remaininOfferToBeApplied = null;
	if(offList && offList.length > 0) {
		offList.forEach(offer => {
			switch(offer.jtOfferType) {
				case 'PRICECUT':
					if(!applliedOffers['PRICECUT']) {
						let offerPrice = applyPriceCutOffer(1, listingDetails.sellingPrice, offer);
						prices.originalPrice = prices.newPrice;
						prices.newPrice = offerPrice;
						applliedOffers['PRICECUT'] = offer;
					}
					break;
				default :
					break;
			}
		})
	}
	let offerToShow = [];
	Object.keys(applliedOffers).forEach(key => {offerToShow.push(applliedOffers[key]);});
	return {prices: prices, appliedOffer: offerToShow};
}

export const isStoreOpen = (openTime, closingTime) => {
	let o = 0;
	let c = 0;
	if(openTime.includes('PM')) o = 12;
	if(closingTime.includes('PM')) c = 12;

	let openHour = Number(openTime.split(':')[0]);
	let closeHour = Number(closingTime.split(':')[0]);

	let currentTime = new Date();
	let currentOffset = currentTime.getTimezoneOffset();
	let ISTOffset = 330;   // IST offset UTC +5:30 
	let ISTTime = new Date(currentTime.getTime() + (ISTOffset + currentOffset)*60000);
	let hoursIST = ISTTime.getHours()	
	//let minutesIST = ISTTime.getMinutes()
	if(hoursIST >= (openHour + o) && hoursIST <= (closeHour + c)) {
		return true;
	}
	return false;
}