import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "./../../App.js";
import {
  formatToIndianCurrency,
  getOfferAndPriceData,
} from "./../../utilities/Utils.js";
import { productBundle, publishEvent } from "./../../utilities/ManageEvents.js";
import cn from "classnames";

const createCartItem = (product, existingCart, qty) => {
  let promiseTime = 0;
  let listing = getWinningListing(product);
  if (listing.qtyPromiseDetailsMap) {
    let keys = Object.keys(listing.qtyPromiseDetailsMap);
    if (keys.length > 0) {
      promiseTime = listing.qtyPromiseDetailsMap[keys[0]].promiseTime;
    }
  }
  let pricing = getOfferAndPriceData(product);
  return {
    cartID: existingCart.cartDetails.cartID,
    cartItemCreatedTime: new Date().getTime(),
    selectedQuantity: qty,
    finalPricePerUnit: pricing.prices.newPrice,
    listingDetails: listing,
    productDetails: product,
    originalDeliveryCharges: 0,
    deliveryCharges: 0,
    existingCartItemDiscount: 0,
    offers: pricing.appliedOffer,
    status: "ACTIVE",
    promiseTime: promiseTime,
    cartReferenceDetails: {
      referenceSelectedQuantity: qty,
      referenceFinalPricePerUnit: listing.sellingPrice,
      referencePromiseTime: promiseTime,
    },
  };
};

const isProductOutOfStock = (product) => {
  if (null != product.winningListingId && "" !== product.winningListingId) {
    var winningListing = product.listingDetailsMap[product.winningListingId];
    var availableQty = winningListing.availableQuantity;
    var minOrderQty = winningListing.minOrderQuantity;
    var maxOrderQty = winningListing.maxOrderQuantity;
    if (
      availableQty > 0 &&
      availableQty >= minOrderQty &&
      minOrderQty <= maxOrderQty
    ) {
      return false;
    }
  }
  return true;
};

const getPriceOfProduct = (product, cartDropdown) => {
  if (isProductOutOfStock(product)) return null;
  let pricing = getOfferAndPriceData(product);
  if (pricing.prices.originalPrice) {
    const pricesClass = cn({ "dropdown-prices": cartDropdown });
    return (
      <div className={pricesClass}>
        <span className="pricecut-price">
          {formatToIndianCurrency(pricing.prices.originalPrice)}
        </span>
        <span className="product-price">
          {formatToIndianCurrency(pricing.prices.newPrice)}
        </span>
      </div>
    );
  }
  return (
    <div className="product-price">
      {formatToIndianCurrency(pricing.prices.newPrice)}
    </div>
  );
};

const getWinningListing = (product) => {
  return isProductOutOfStock(product)
    ? null
    : product.listingDetailsMap[product.winningListingId];
};

const getMaxAvailableQuantity = (winningListing) => {
  return !winningListing
    ? 0
    : Math.min(
        winningListing.availableQuantity,
        winningListing.maxOrderQuantity
      );
};

export default function AddProductQty({
  product,
  inCartWebView,
  cartDropdown,
}) {
  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const { cart } = useContext(AppContext);
  const existingCart = cart.get;
  const [maxedOut, setMaxedOut] = useState(false);

  useEffect(() => {
    let found = false;
    for (let i in existingCart.cartDetails.activeCartItems) {
      if (
        existingCart.cartDetails.activeCartItems[i].productDetails.jpin ===
        product.jpin
      ) {
        setSelectedQuantity(
          existingCart.cartDetails.activeCartItems[i].selectedQuantity
        );
        found = true;
        break;
      }
    }
    if (!found) setSelectedQuantity(0);
  }, [existingCart]);

  const updateInCartItem = (qty) => {
    setSelectedQuantity(qty);
    let found = false;
    for (let i in existingCart.cartDetails.activeCartItems) {
      if (
        existingCart.cartDetails.activeCartItems[i].productDetails.jpin ===
        product.jpin
      ) {
        existingCart.cartDetails.activeCartItems[i].selectedQuantity = qty;
        existingCart.cartDetails.activeCartItems[
          i
        ].cartReferenceDetails.referenceSelectedQuantity = qty;
        if (qty === 0) {
          existingCart.cartDetails.activeCartItems.splice(i, 1);
        }
        found = true;
      }
    }
    if (!found && qty !== 0) {
      existingCart.cartDetails.activeCartItems.push(
        createCartItem(product, existingCart, qty)
      );
    }
    let total = existingCart.cartDetails.activeCartItems.reduce((sum, item) => {
      return sum + item.finalPricePerUnit * item.selectedQuantity;
    }, 0);
    existingCart.cartDetails.cartAmount = total;
    let newCart = { ...existingCart };
    cart.set(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
    let winningListing = getWinningListing(product);
    let maxAvailableQuantity = getMaxAvailableQuantity(winningListing);
    if (maxAvailableQuantity <= qty) {
      setMaxedOut(true);
    } else {
      setMaxedOut(false);
    }
  };

  const increaseQuantity = (e) => {
    if (e.target.innerHTML.startsWith("Add")) {
      publishEvent("ON_SUBMIT_ADD_PRODUCT", productBundle(product));
    } else {
      publishEvent("ON_SUBMIT_INCREASE_QUANTITY", productBundle(product));
    }
    e.stopPropagation();
    let winningListing = getWinningListing(product);
    if (!winningListing) return;
    let maxAvailableQuantity = getMaxAvailableQuantity(winningListing);
    if (maxAvailableQuantity <= selectedQuantity) {
      setMaxedOut(true);
      return;
    }
    if (selectedQuantity < winningListing.minOrderQty) {
      updateInCartItem(winningListing.minOrderQuantity);
    } else if (
      winningListing.minOrderQuantityMultiples &&
      selectedQuantity < maxAvailableQuantity
    ) {
      updateInCartItem(
        Math.min(
          maxAvailableQuantity,
          selectedQuantity + winningListing.minOrderQuantity
        )
      );
    } else if (selectedQuantity < maxAvailableQuantity) {
      updateInCartItem(
        Math.min(winningListing.maxOrderQuantity, selectedQuantity + 1)
      );
    }
  };

  const decreaseQuantity = (e) => {
    publishEvent("ON_SUBMIT_DECREASE_QUANTITY", productBundle(product));
    e.stopPropagation();
    let winningListing = getWinningListing(product);
    if (!winningListing) return;
    if (selectedQuantity > getMaxAvailableQuantity(winningListing)) {
      updateInCartItem(getMaxAvailableQuantity(winningListing));
    } else if (winningListing.minOrderQuantityMultiples) {
      updateInCartItem(
        Math.max(0, selectedQuantity - winningListing.minOrderQuantity)
      );
    } else {
      updateInCartItem(Math.max(0, selectedQuantity - 1));
    }
  };

  return (
    <div>
      {isProductOutOfStock(product) ? (
        <div className="sold-out right-text">SOLD OUT</div>
      ) : (
        <div className={inCartWebView ? "row-reverse align-center" : "row"}>
          <div className={"col-6 " + (inCartWebView && "centered")}>
            {getPriceOfProduct(product, cartDropdown)}
          </div>
          <div
            className={"col-6 " + (inCartWebView ? "centered" : "right-text")}
          >
            {selectedQuantity ? (
              <div className={"add-with-quantity"}>
                <button
                  type="button"
                  onClick={(event) => decreaseQuantity(event)}
                >
                  -
                </button>
                <input
                  type="text"
                  value={selectedQuantity}
                  onChange={() => console.log("input")}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                ></input>
                <button
                  type="button"
                  className={maxedOut ? "no-ivt" : ""}
                  onClick={(event) => increaseQuantity(event)}
                >
                  +
                </button>
                {maxedOut ? (
                  <div className="arrow_box" style={{ width: "130px" }}>
                    Max. Quantity Available
                  </div>
                ) : null}
              </div>
            ) : (
              <button
                type="button"
                className={"add"}
                onClick={(event) => increaseQuantity(event)}
              >
                Add +
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
