import React, { useEffect, useRef, useState } from "react"
import { useHistory } from 'react-router-dom';
import { throttle, debounce } from "throttle-debounce";
import SearchApiHandler from './SearchApiHandler';
import { publishEvent, getAutosuggetBundle } from '../../utilities/ManageEvents.js';
import "./Style.css";


export default function SearchBoxDesktop() {
  let history = useHistory();
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [enterPressed, setEnterPressed] = useState(false)
  const controllerRef = useRef()

  const clearSuggestions = () => {
    setSuggestions([]);
  }

  const onSuggestionSelection = (item, index) => {
    publishEvent('ON_SUBMIT_AUTO_SUGGEST', { idx: index });
    window.location.assign('/product?jpin=' + item.jpin)
  }

  useEffect(() => {
    if (!enterPressed) return;
    if (window.location.pathname === "/search") history.replace("/search", { searchQuery: query })
    history.push("/search", { searchQuery: query })
    console.log("enter pressed", enterPressed)
  }, [enterPressed])

  const onEnterInSearch = text => {
    publishEvent('ON_SUBMIT_SEARCH_QUERY', { qt: text });
    clearSuggestions();
    setEnterPressed(true);
  }

  const onTextChange = text => {
    publishEvent('ON_TYPE_SEARCH_QUERY', { qt: text });
    if (text.length === 0 || enterPressed) {
      clearSuggestions();
      setEnterPressed(false)
      return;
    }
    if (controllerRef.current) controllerRef.current.abort();
    const controller = new AbortController();
    controllerRef.current = controller;

    setLoading(true)
    SearchApiHandler.getSuggestions(text, {
      signal: controllerRef.current?.signal,
    })
      .then(resp => {
        setSuggestions(resp['productDetailsList'][0]['browseEntityDetailsList']);
        publishEvent('ON_LOAD_AUTO_SUGGEST', getAutosuggetBundle(resp));
        setLoading(false)
      }
    ).catch(error => {
      if (error.name !== "AbortError") {
        setLoading(false)
        console.log(error);
      }
      }).finally(() => {
        console.log(controllerRef.current?.signal)
      });
  }

  const autocompleteSearchDebounced = debounce(1000, onTextChange);
  const autocompleteSearchThrottled = throttle(1000, onTextChange);
  const textEntered = (e) => {
    let text = e.target.value;
    setQuery(text);
    if (text.length < 5) {
      autocompleteSearchThrottled(text)
    } else {
      autocompleteSearchDebounced(text)
    }
  }


  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      let q = e.target.value;
      if (q.length > 0) {
        onEnterInSearch(e.target.value);
      }
    }
  }

  return (
    <div className="search-box-wrapper search-box-desktop">
      <div className="search-box">
        <input type="text"
          placeholder="Search groceries, essentials..."
          onChange={textEntered}
          onKeyDown={handleKeyDown}
          onFocus={() => { publishEvent('ON_FOCUS_SEARCH_BOX') }}
          value={query}
          className="search-text"
          autoFocus={true} />
        {loading && <div className="spinner"></div>}
        <div className={"search-suggestions"}>
          {(!enterPressed && suggestions && suggestions.length > 0) ?
            <ul className="dropdown-menu rec-dropdown-new" style={{ display: 'block' }}>
              {suggestions.map((itm, index) => {
                return (<li className={"dropdown-data-rec "} key={index} onClick={() => onSuggestionSelection(itm, index)}>
                  <div className="dropdown-data-rec-inner">{itm.title.localizedText}</div>
                </li>)
              })}
            </ul> : null
          }
        </div>
      </div>

    </div>)
}