import React, { useContext, useEffect } from "react";
import { AppContext } from './../../App.js';
import ProductList from "./../product/ProductList";
import Cart from './../cart/Cart.js';
import "./../uinode/group/tabproductlist.css";
import { shouldShowItems } from './../../utilities/CartUtils.js';
import cn from "classnames"


export default function SearchResults({ items }) {
  const context = useContext(AppContext);
  const { cart, header, showWebView } = context

  useEffect(() => {
    header.set({
      type: showWebView ? 'SEARCH_STORE_NAME_DESKTOP' : 'SEARCH_STORE_NAME',
      text: ""
    })
  }, [showWebView])
  return (
    <div>
      <div className={cn("top-ten", { "bt-mar-55": shouldShowItems(cart.get) })}>
        <ProductList products={items} emptyMessage={"No products"} />
      </div>
      {!showWebView && <Cart />}
    </div>
  );
}
