import React, { useState, useContext, useEffect } from "react";
import serverApiHandler from "./../apiHandler/serverApiHandler.js";
import Loader from "./../common/Loader.js";
import { AppContext } from "./../../App.js";
import { NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  formatToIndianCurrencyRounded,
  formatDate,
} from "./../../utilities/Utils.js";
import {
  publishEvent,
  getMyOrdersBundle,
} from "./../../utilities/ManageEvents.js";
import OrderDetail from "./OrderDetail.js";
import cn from "classnames";

const Order = ({ order, isActive, navLink }) => {
  const { billNumber, id, invoiceTimestamp, totalAmount, origin } = order;
  const orderRowClass = cn("sales-invoice-row", { "selected-order": isActive });

  const orderRow = (
    <div className={orderRowClass}>
      <div className="row">
        <div className="col-6">
          <div>{billNumber ? billNumber : "-"}</div>
          <div>{formatDate(invoiceTimestamp)}</div>
        </div>
        <div className="col-5 right-text">
          {totalAmount ? formatToIndianCurrencyRounded(totalAmount) : "-"}
        </div>
        <div className="col-1 right-text">
          <i className="arrow right"></i>
        </div>
      </div>
    </div>
  );
  return navLink ? (
    <NavLink
      to={{
        pathname: "orderdetails",
        search: "?origin=" + origin + "&orderId=" + id,
        state: { billNum: billNumber },
      }}
    >
      {orderRow}
    </NavLink>
  ) : (
    orderRow
  );
};

export default function MyOrder() {
  const [orders, setOrders] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 10;
  const [totalPages, setTotalPages] = useState(0);
  const context = useContext(AppContext);
  const user = context.user.get;
  const { header, showWebView } = context;
  const [selectedOrder, setSelectedOrder] = useState({
    origin: null,
    id: null,
    order: {},
  });

  useEffect(() => {
    if (orders && orders.length)
      setSelectedOrder({
        origin: orders[0].origin,
        id: orders[0].id,
        order: orders[0],
      });
  }, [orders]);

  useEffect(() => {
    header.set({ type: "PAGE_NAME", text: "My Orders" });

    setLoading(true);
    let startTime = new Date().getTime();
    serverApiHandler
      .getPaginatedOrderList(getParams())
      .then((resp) => {
        let ttms = new Date().getTime() - startTime;
        //setOrders(resp.orders);
        //setPageNumber(pageNumber + 1);
        setOrders(resp.data);
        setTotalPages(resp.totalPages);
        publishEvent(
          "ON_LOAD_MY_ORDERS_PAGE",
          getMyOrdersBundle(resp.data),
          ttms
        );
      })
      .catch((error) => {
        let ttms = new Date().getTime() - startTime;
        console.log(error);
        //setHasMore(false);
        publishEvent("ON_LOAD_MY_ORDERS_PAGE", null, ttms);
      })
      .finally((resp) => {
        setLoading(false);
      });
  }, []);

  const getParams = () => {
    return (
      "?customerId=" +
      user.customerId +
      //"JC-1202588399" +
      "&phone=" +
      user.phoneNumber +
      //"9450010125" +
      "&pageSize=" +
      pageSize +
      "&pageNumber=" +
      pageNumber
    );
  };

  useEffect(() => {
    setLoading(true);
    let startTime = new Date().getTime();
    serverApiHandler
      .getPaginatedOrderList(getParams())
      .then((resp) => {
        setOrders(resp.data);
      })
      .catch((error) => {
        console.log(error);
        //setHasMore(false);
      })
      .finally((resp) => {
        setLoading(false);
      });
  }, [pageNumber]);

  const handlePageClick = (data) => {
    //onsole.log(data.selected);

    setPageNumber(data.selected + 1);
  };

  const myOrders = (
    <div>
      {loading ? <Loader /> : null}

      {orders && orders.length ? (
        <div>
          <div className="row my-orders-header">
            <div className="col-6">Bill no. & time</div>
            <div className="col-5 right-text">Bill Amount </div>
          </div>

          <div>
            {" "}
            {orders.map((o, i) => {
              return (
                <div
                  key={o.id}
                  onClick={() => {
                    setSelectedOrder({ origin: o.origin, id: o.id, order: o });
                  }}
                >
                  <Order
                    order={o}
                    navLink={!showWebView}
                    isActive={selectedOrder.id === o.id && showWebView}
                  />
                </div>
              );
            })}
          </div>
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      ) : !loading ? (
        <div className="no-orders-made">You have placed no orders</div>
      ) : null}
    </div>
  );

  if (!showWebView) return myOrders;

  const ordersClass = cn({ "orders-left": orders && orders.length });
  return (
    <div className="my-orders">
      <div className={ordersClass}>{myOrders}</div>
      <div className="selected-order-right">
        <OrderDetail
          billNumber={selectedOrder.order.billNumber}
          id={selectedOrder.id}
          origin={selectedOrder.origin}
        />
      </div>
    </div>
  );
}
