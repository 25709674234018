import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import 'swiper/swiper-bundle.css';
import {
    BrowserRouter,
    Switch,
    Route,
    Redirect,
    useLocation,
} from 'react-router-dom';
import Home from './screens/home/Home.js';
import StoreSelector from './screens/store/StoreSelector.js';
import Login from './screens/login/Login.js';
import NewAddress from './screens/address/NewAddress.js';
import SearchPage from './screens/search/SearchPage.js';
import ProductPage from './screens/product/ProductPage';
import BrowsePage from './screens/product/BrowsePage.js';
import ProductListPage from './screens/product/ProductListPage';
import ProductListViewPage from './screens/product/ProductListViewPage';
import CartPage from './screens/cart/CartPage.js';
import MyOrder from './screens/order/MyOrder.js';
import OrderDetail from './screens/order/OrderDetail.js';
import { debounce } from 'throttle-debounce';
import Header from './screens/header/Header';
import SearchPageDesktop from './screens/search/SearchPageDesktop';
import Greencard from './screens/greencard/Greencard';
import GreencardHistory from './screens/greencard/GreencardHistory';
import TargetScheme from './screens/targetschemes/TargetScheme';
import TargetDetails from './screens/targetschemes/TargetDetails';
import TransactionHistory from './screens/targetschemes/TransactionHistory';
import CurrentCredit from './screens/credit-details/components/current-credit';
import txn from './screens/transaction-details/components/transaction-detail';
import ClearedCredit from './screens/credit-details/components/cleared-credit';
import MyQr from './screens/myQr/MyQr';
import StoreOffer from './screens/offers/StoreOffer';

export const AppContext = React.createContext({});

function App() {
    const DESKTOP_BREAKPOINT = 768;

    let selectedStore = JSON.parse(localStorage.getItem('selectedStore'));
    let existingCart = JSON.parse(localStorage.getItem('cart'));
    let existingUser = JSON.parse(localStorage.getItem('user'));
    let existingSession = JSON.parse(localStorage.getItem('session'));

    const [store, setStore] = useState(selectedStore);
    const [user, setUser] = useState(existingUser);
    const [cart, setCart] = useState(existingCart);
    const [session, setSession] = useState(existingSession);
    const [header, setHeader] = useState({ type: '', text: '' });
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [showWebView, setShowWebView] = useState(
        document.documentElement.clientWidth >= DESKTOP_BREAKPOINT
    );

    useEffect(() => {
        setShowWebView(dimensions.width >= DESKTOP_BREAKPOINT);
    }, [dimensions]);

    useEffect(() => {
        const handleResize = debounce(100, () =>
            setDimensions({
                width: document.documentElement.clientWidth,
                height: document.documentElement.clientHeight,
            })
        );
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const basestate = {
        store: { get: store, set: setStore },
        user: { get: user, set: setUser },
        cart: { get: cart, set: setCart },
        session: { get: session, set: setSession },
        dimensions: { get: dimensions, set: setDimensions },
        header: { get: header, set: setHeader },
        showWebView,
    };

    return (
        <AppContext.Provider value={basestate}>
            <div className='App'>
                <BrowserRouter>
                    <Header type={header.type} pageText={header.text} />
                    <Switch>
                        <Route
                            exact
                            path='/store-offers'
                            component={StoreOffer}
                        />
                        <Route exact path='/'>
                            {!store ? (
                                <Redirect to='/storeselector' />
                            ) : (
                                <Home />
                            )}
                        </Route>
                        <Route exact path='/storeselector'>
                            <StoreSelector />
                        </Route>
                        <Route exact path='/login' component={Login} />
                        <Route
                            exact
                            path='/search'
                            render={(props) => {
                                if (!store) return <Redirect to='/' />;
                                if (dimensions.width >= DESKTOP_BREAKPOINT)
                                    return <SearchPageDesktop {...props} />;
                                return <SearchPage />;
                            }}
                        />
                        <Route exact path='/browse'>
                            {!store ? <Redirect to='/' /> : <BrowsePage />}
                        </Route>
                        <Route exact path='/product-list/:uinodeId'>
                            {!store ? <Redirect to='/' /> : <ProductListPage />}
                        </Route>
                        <Route exact path='/product'>
                            {!store ? <Redirect to='/' /> : <ProductPage />}
                        </Route>
                        <Route exact path='/list'>
                            {!store ? (
                                <Redirect to='/' />
                            ) : (
                                <ProductListViewPage />
                            )}
                        </Route>
                        <Route exact path='/cart'>
                            {!store ? <Redirect to='/' /> : <CartPage />}
                        </Route>
                        <Route exact path='/address'>
                            {!store ? <Redirect to='/' /> : <NewAddress />}
                        </Route>
                        <Route exact path='/order'>
                            {!user ? (
                                <Redirect push to='/login' />
                            ) : (
                                <MyOrder />
                            )}
                        </Route>
                        <Route exact path='/orderdetails'>
                            {!user ? (
                                <Redirect push to='/login' />
                            ) : (
                                <OrderDetail />
                            )}
                        </Route>
                        <Route exact path='/target-schemes'>
                            {!user ? (
                                <Redirect push to='/login' />
                            ) : (
                                <TargetScheme />
                            )}
                        </Route>
                        <Route exact path='/target-details'>
                            {!user ? (
                                <Redirect push to='/login' />
                            ) : (
                                <TargetDetails />
                            )}
                        </Route>
                        <Route path='/transaction-history'>
                            {!user ? (
                                <Redirect push to='/login' />
                            ) : (
                                <TransactionHistory />
                            )}
                        </Route>
                        <Route path='/qr'>
                            {!user ? <Redirect push to='/login' /> : <MyQr />}
                        </Route>
                        <Route exact path='/credit'>
                            {!user ? (
                                <Redirect push to='/login' />
                            ) : (
                                <CurrentCredit />
                            )}
                        </Route>
                        <Route exact path='/greencard' component={Greencard} />
                        <Route exact path='/txn' component={txn} />
                        <Route exact path='/clrd' component={ClearedCredit} />
                        <Route exact path='/greencard-history'>
                            {!user ? (
                                <Redirect to='/login' />
                            ) : (
                                <GreencardHistory />
                            )}
                        </Route>
                        <Redirect to='/' />
                    </Switch>
                </BrowserRouter>
            </div>
        </AppContext.Provider>
    );
}

export default App;
