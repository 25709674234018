import React, { useState, useEffect, useContext } from 'react';
import Loader from './../common/Loader.js';
import SearchApiHandler from './SearchApiHandler';
import SearchResults from './SearchResults.js';
import { AppContext } from '../../App.js';
import "./Style.css";
import ErrorScreen from '../common/ErrorScreen.js';

export default function SearchPageDesktop(props) {
  const searchQuery = (props.location && props.location.state)? props.location.state.searchQuery: null
  const [searchResults, setSearchResults] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false);
  const context = useContext(AppContext)

  useEffect(() => {
    context.header.set({ type: 'SEARCH_STORE_NAME_DESKTOP', text: '' })
  }, [])

  useEffect(() => {
    setLoading(true);
    SearchApiHandler.getSuggestions(searchQuery)
      .then(resp => {
        var products = resp['productDetailsList'][0]['browseEntityDetailsList'];
        setSearchResults(products);
      }).catch(error => {
        setError(true)
      }).finally(resp => {
        setLoading(false);
      });
  }, [searchQuery])

  if(error) return <ErrorScreen />
  return (
    <div>
      {(!loading) ?
        <SearchResults items={searchResults} />
        :
        <Loader />
      }
    </div>)
}