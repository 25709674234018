export const formatToIndianCurrency = (number = 0) => {
  return Number(number).toLocaleString("en-IN", {
    maximumFractionDigits: 2,
    style: "currency",
    currency: "INR",
  });
};

export const formatToIndianCurrencyRounded = (number = 0) => {
  return Number(number).toLocaleString("en-IN", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    style: "currency",
    currency: "INR",
  });
};
