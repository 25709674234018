import React, { useContext, useEffect } from "react";
import { AppContext } from "../../App";
import tick from "./../../assets/green-tick.svg";
import {
  publishEvent,
  getOrderBundle,
} from "./../../utilities/ManageEvents.js";
import { isStoreOpen } from "./../../utilities/Utils.js";
import "./Style.css";

export default function PostOrder({ orderSummary = {} }) {
  const context = useContext(AppContext);
  const { storeOpeningTime, storeClosingTime } = context.store.get;


  useEffect(() => {
    publishEvent("ON_LOAD_ORDER_CONFIRMATION", getOrderBundle(orderSummary));
    if(context.user.get) localStorage.removeItem("guestAddress")
  }, []);

  return (
    <div className="post-order">
      <div className={"post-up-wrapper"}>
        <div className="cong-text">
          <img
            src={tick}
            style={{ height: "20px", verticalAlign: "middle" }}
            alt={"tick"}
          />
          Yay! Order Confimed
        </div>
        <div className="row center">
          {isStoreOpen(storeOpeningTime, storeClosingTime) ? (
            <div className="del-text top-ten ">Delivery In 2 Hours</div>
          ) : (
            <div className="del-text del-tmr top-ten ">Delivery will be tomorrow</div>
          )}
        </div>
        <div className="ord-info top30">
          <div>Order Total - {orderSummary.orderAmount}</div>
          <div>No Of Items - {orderSummary.numberOfOrderItems}</div>
          <div>Payment - Cash / UPI pay on delivery</div>
        </div>
      </div>
      <div className="bottom-go-home">
        <div className="gm-bt">
          <a
            href="/"
            onClick={() => {
              return publishEvent("ON_SUBMIT_GO_TO_HOME");
            }}
          >
            <button className="go-hm-btn">Go Home</button>
          </a>
        </div>
      </div>
    </div>
  );
}
