import React, { useState, useEffect } from "react";
import Loader from "./../common/Loader.js";
import UINode from "./../uinode/UINode.js";
import ProductListPage from "./../product/ProductListPage.js";
import serverApiHandler from "./../apiHandler/serverApiHandler.js";
import { publishEvent } from "./../../utilities/ManageEvents.js";

const getUiNodeRender = (node) => {
  switch (node.pageType) {
    case "BROWSE":
      return <UINode node={node.browseResponse} />;
    case "PRODUCT":
      return <ProductListPage list={node.productListResponse} />;
    default:
      return <UINode node={node.browseResponse} />;
  }
};

export default function HomeUiNode() {
  const [node, setNode] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    let params = "?node=HOME";
    let startTime = new Date().getTime();
    serverApiHandler
      .getBrowseResponse(params)
      .then((resp) => {
        //console.log(resp);
        let ttms = new Date().getTime() - startTime;
        publishEvent("ON_LOAD_HOME_PAGE_UINODE", null, ttms);
        console.log(resp);
        setNode(resp);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally((resp) => {
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <div>{node ? getUiNodeRender(node) : null}</div>
      <div>{loading ? <Loader /> : null}</div>
    </div>
  );
}
