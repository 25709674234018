import React, { useState, useEffect, useContext } from 'react';
import serverApiHandler from './../apiHandler/serverApiHandler.js';
import Cart from './../cart/Cart.js';
import Loader from './../common/Loader.js';
import ErrorScreen from './../common/ErrorScreen.js';
import Product from './Product.js';
import {getLocalized} from './../../utilities/Utils.js';
import {publishEvent} from './../../utilities/ManageEvents.js';
import { AppContext } from '../../App.js';

export default function ProductListViewPage() {
	const [productList, setProductList] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [pageName, setPagename] = useState('Product List');
	const context = useContext(AppContext)
	const {header, showWebView} = context

	useEffect(() => {
		header.set({ 
			type: showWebView ? 'SEARCH_STORE_NAME_DESKTOP' : 'PAGE_NAME',
			text: pageName })
	}, [showWebView])

	useEffect(() => {
		let params = window.location.search;
		setLoading(true);
		let startTime = new Date().getTime();
		serverApiHandler.getProductListResponse(params)
			.then(response => {
				let ttms = new Date().getTime() - startTime;
				if(response && response.productDetailsList.length > 0) {
					setProductList(response.productDetailsList[0].browseEntityDetailsList);
					let name = getLocalized(response.productListTitle);
					setPagename(name);
					publishEvent('ON_LOAD_PRODUCT_LIST_SCREEN', {lt: name, totitl: response.productDetailsList[0].browseEntityDetailsList.length, url: window.location.href, ltvid: window.location.search}, ttms)
				} else {
					publishEvent('ON_LOAD_PRODUCT_LIST_SCREEN', {lt: '', totitl: 0, url: window.location.href, ltvid: window.location.search}, ttms)
					setProductList([]);
				}
			}).catch(error => {
				let ttms = new Date().getTime() - startTime;
				publishEvent('ON_FAILURE_LOAD_PRODUCT_LIST_SCREEN', null, ttms)
				setError(true);
			}).finally(() => {
				setLoading(false);
			})
	}, []);

	return(<div>
		{(productList) ? <div className="product-list" style={{padding: '15px'}}>{productList.map((product,i) => <Product product={product} />)}</div> : null}
		{(loading) ? <Loader /> : null}
		{(error) ? <ErrorScreen /> : null}
		{!showWebView && <Cart />}
	</div>)	
}
