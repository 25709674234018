import React, { useEffect }  from 'react';
import marker from './../../assets/marker.svg';
import './store.css';
import desktopBanner from './../../assets/J24-Desktop-Banner.jpg';
import SearchBoxDesktop from '../search/SearchBoxDesktop.js';

const desktopCoverUrl = "https://j24storelogoandcovers.s3.ap-southeast-1.amazonaws.com/coverpages/j24-cov.jpg";

const handleScroll = (event) => {
    let scrollTop = document.documentElement.scrollTop;
    let element = document.getElementById('xyz');
    let searchBox = document.getElementById('cover-search-box');
    if(element) {
	    if(scrollTop < 90) {
	        element.style.backgroundColor = 'rgba(98, 176, 77, '+((scrollTop)/50)+')';
	        element.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.2)';
	        
	    } else {
	       	element.style.backgroundColor = 'rgba(98, 176, 77, 1)';
	       	element.style.boxShadow = 'none';
	       	
	    }
    }

    if(searchBox) {
    	 if(scrollTop < 150) {
    	 	searchBox.style.opacity = 0;
    	} else {
    	 	searchBox.style.opacity = (scrollTop - 150)/80;
    	 }
    }	
}

const StoreCover = ({store}) => {

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return() => {
			window.removeEventListener('scroll', handleScroll);
		}
	}, [])


	let coverUrl = store && store.storeCoverUrl ? store.storeCoverUrl : desktopCoverUrl;
	return(
		<>
		<div style={{backgroundImage: 'url('+coverUrl+')', position: 'relative'}} className={'store-cover'}>
			<div className="cover-overlay"></div>
			<div style={{position: 'absolute', bottom: '50px', width: '100%'}}>
				<div className="header-search">
					<SearchBoxDesktop />
				</div>
			</div>
		</div>
		<div id="cover-search-box" style={{position: 'sticky', top: '84px', backgroundColor: '#62B04D', zIndex: '20', opacity: '0', marginTop: '-61px'}}>
			<div className="header-search">
				<SearchBoxDesktop />
			</div>
		</div>
	</>
	)
}

export default StoreCover;