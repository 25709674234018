import React, { useState, useEffect, useContext } from "react";
import Cart from "./Cart.js";
import AckMsg from "./AckMsg.js";
import Product from "./../product/Product.js";
import { AppContext } from "./../../App.js";
import emptycart from "./../../assets/emptycart.svg";
// import {products} from './../product/Json.js';
import Loader from "./../common/Loader.js";
import ErrorScreen from "./../common/ErrorScreen.js";
import PostOrder from "./../order/PostOrder.js";
import serverApiHandler from "./../apiHandler/serverApiHandler.js";
import { formatToIndianCurrency } from "./../../utilities/Utils.js";
import {
  shouldShowItems,
  makeSyncCartRequest,
  makeCartFromSyncResponse,
  createOrderRequest,
} from "./../../utilities/CartUtils.js";
import {
  publishEvent,
  getCartBundle,
  getOrderBundle,
} from "./../../utilities/ManageEvents.js";
import SelectAddress from "../address/SelectAddress.js";
import { Link } from "react-router-dom";

const PaymentDetails = ({ details }) => {
  let originalCartAmount = 0;
  details.activeCartItems.forEach(
    (cartItem) =>
      (originalCartAmount +=
        cartItem.listingDetails.sellingPrice * cartItem.selectedQuantity)
  );

  return (
    <div>
      <div className="payment-header">Payment Details</div>
      <div className="payment-details-box top-ten">
        <div className="payment-itm row">
          <div className="col-6">MRP total</div>
          <div className="col-6 right-text">
            {formatToIndianCurrency(originalCartAmount)}
          </div>
        </div>
        <div className="payment-itm row">
          <div className="col-6">Discount</div>
          <div className="col-6 right-text">
            - {formatToIndianCurrency(originalCartAmount - details.cartAmount)}
          </div>
        </div>
        <div className="payment-itm bigg row">
          <div className="col-6">Total</div>
          <div className="col-6 right-text">
            {formatToIndianCurrency(details.cartAmount)}
          </div>
        </div>
      </div>
    </div>
  );
};

const NoItemAdded = () => {
  return (
    <div style={{ position: "relative", height: "100vh" }}>
      <div className="vertical-center full-width">
        <div className="no-item-box">
          <div>
            <img src={emptycart} alt={"crt"} />
          </div>
          <div>You have not added any item to you cart</div>
          <div className="top-twenty">
            <a href="/">
              <button className="go-home-btn"> Browse Products </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function CartPage(props) {
  const [notes, setNotes] = useState("");
  const [loading, setLoading] = useState(false);
  const [ackMessage, setAckMessage] = useState([]);
  const [error, setError] = useState(null);
  const context = useContext(AppContext);
  const cart = context.cart.get;
  const user = context.user.get;
  const addressExists =
    (user && user.addressSummaries.length > 0) ||
    localStorage.getItem("guestAddress");
  const { showWebView } = context;
  const [address, setAddress] = useState(null);
  const [orderSummary, setOrderSummary] = useState(null);

  const manageCartResponse = (response) => {
    let cart = makeCartFromSyncResponse(response);
    localStorage.setItem("cart", JSON.stringify(cart));
    context.cart.set(cart);
    let ackMsgs = [];
    for (let i = 0; i < cart.cartDetails.activeCartItems.length; i++) {
      for (
        let j = 0;
        j < cart.cartDetails.activeCartItems[i].ackMessages.length;
        j++
      ) {
        if (
          cart.cartDetails.activeCartItems[i].ackMessages[j].type !==
          "pricedecrease"
        ) {
          ackMsgs.push({
            item: cart.cartDetails.activeCartItems[i],
            message: cart.cartDetails.activeCartItems[i].ackMessages[j],
          });
        }
      }
    }
    setAckMessage(ackMsgs);
  };

  const ackAction = () => {
    setAckMessage([]);
  };

  const manageCartFailure = () => {
    setError(
      "Cart Sync Failed. You can retry or go home and try again in some time."
    );
  };

  const syncCart = () => {
    if (cart && cart.cartDetails) {
      setLoading(true);
      let req = makeSyncCartRequest(cart);
      let startTime = new Date().getTime();      
      serverApiHandler
        .syncCart(req)
        .then((response) => {
          let ttms = new Date().getTime() - startTime;
          manageCartResponse(response);
          publishEvent("ON_LOAD_CART_DETAILS_SCREEN", getCartBundle(cart), ttms);
        })
        .catch((error) => {
          let ttms = new Date().getTime() - startTime;
          publishEvent("ON_FAILURE_CART_VIEW", null, ttms);
          manageCartFailure();
          console.log(error);
        })
        .finally((resp) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    context.header.set({ type: "CART", text: "" });
    syncCart();
  }, []);

  const handleOrderResponse = (response) => {
    if (response.success) {
      setOrderSummary(response.orderSummary);
      context.cart.set(null);
      context.session.set(response.cartDetails.customerID);
      localStorage.removeItem("cart");
      localStorage.setItem(
        "session",
        JSON.stringify(response.cartDetails.customerID)
      );
    } else {
      setError(response.error);
    }
  };

  const handleOrderFailure = (err) => {
    if (err) {
      console.log(err);
      if (err.error) {
        if (err.error.error) setError(err.error.error);
        else {
          setError(err.error);
        }
      }
    } else {
      setError(
        "Order Placement Failed due to some reason. Please try after sometime."
      );
    }
  };

  const placeOrder = () => {
    publishEvent("ON_SUBMIT_PLACE_ORDER", getCartBundle(cart));
    let request = createOrderRequest(cart, address);
    setLoading(true);
    let startTime = new Date().getTime();
    serverApiHandler
      .placeOrder(request)
      .then((resp) => {
        let ttms = new Date().getTime() - startTime;
        publishEvent("ON_SUCCESS_ORDER", getOrderBundle(resp.orderSummary), ttms);
        handleOrderResponse(resp);
      })
      .catch((err) => {
        let ttms = new Date().getTime() - startTime;
        publishEvent("ON_FAILURE_ORDER", null, ttms);
        handleOrderFailure(err);
      })
      .finally((resp) => {
        setLoading(false);
      });
  };

  const retryOrder = () => {
    setError(null);
  };

  const deliveryNotes = () => {
    return (
      <div className="top-twenty">
        <input
          type="text"
          className="delivery-notes"
          placeholder="+ Add Delivery Notes"
          value={notes}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
          onFocus={() => {
            publishEvent("ON_CLICK_DELIVERY_NOTE");
          }}
        />
      </div>
    );
  };

  const items = (showWebView) => {
    return (
      <div className="top-twenty">
        <div className="payment-header">Order Items</div>
        <div className="top-ten">
          {cart.cartDetails.activeCartItems.map((item, i) => (
            <Product
              key={item.cartItemID + i}
              product={item.productDetails}
              inCartWebView={showWebView}
            />
          ))}
        </div>
      </div>
    );
  };

  const getMarginBottom = () => {
    if (showWebView) return "0px";
    if (addressExists) return "150px";
    return "45px";
  };
  if (orderSummary) return <PostOrder orderSummary={orderSummary} />;
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {error ? (
            <ErrorScreen text={error} action={syncCart} />
          ) : (
            <div className="page-wrapper">
              {ackMessage.length > 0 ? (
                <AckMsg msgs={ackMessage} action={ackAction} />
              ) : null}
              {shouldShowItems(cart) ? (
                <div
                  className="pad-12 cart-page"
                  style={{ marginBottom: getMarginBottom() }}
                >
                  <div className="cart-page-right">
                    <PaymentDetails details={cart.cartDetails} />
                    {deliveryNotes()}
                    {showWebView && addressExists && (
                      <SelectAddress setOrderAddress={setAddress} />
                    )}
                    {addressExists ? (
                      <button
                        className="cart-btn place-order"
                        onClick={placeOrder}
                      >
                        Place Order
                      </button>
                    ) : user ? (
                      <a href="/address">
                        <button className="cart-btn place-order">
                          Proceed
                        </button>
                      </a>
                    ) : (
                      <Link
                        className="cart-btn place-order"
                        to={{
                          pathname: "/login",
                          state: { from: "cartpage" },
                        }}
                      >
                        Proceed
                      </Link>
                    )}
                  </div>
                  <div className="cart-page-left">{items(showWebView)}</div>
                  {!showWebView ? (
                    <>
                      {addressExists && (
                        <SelectAddress setOrderAddress={setAddress} />
                      )}
                      <Cart handleCheckOut={addressExists && placeOrder} />
                    </>
                  ) : null}
                </div>
              ) : (
                <NoItemAdded />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}