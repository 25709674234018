import React, { useContext, useEffect, useState, useRef } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import SearchLocation from "./SearchAddress.js";
import AddAdress from "./AddAddress.js";
import Loader from "../common/Loader.js";
import pin from "../../assets/pin.svg";
import { AppContext } from "./../../App.js";
import PostOrder from "./../order/PostOrder.js";
import ErrorScreen from "./../common/ErrorScreen.js";
import serverApiHandler from "./../apiHandler/serverApiHandler.js";
import { createOrderRequest } from "./../../utilities/CartUtils.js";
import {
  publishEvent,
  getCartBundle,
  getOrderBundle,
} from "./../../utilities/ManageEvents.js";
// import {mapStyles} from "./MapStyles.js"
import "./Style.css";

const libraries = ["places"];
const options = {
  disableDefaultUI: true,
  gestureHandling: "greedy",
  clickableIcons: false,
  // styles: mapStyles
};
const center = { lat: 12.9716, lng: 77.5946 };

const getCoordinates = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, {
      timeout: 5000,
    });
  });
};

export default function NewAddress() {
  const [curPos, setCurPos] = useState({ lat: null, lng: null, address: null });
  const [searchPos, setSearchPos] = useState({
    lat: null,
    lng: null,
    address: null,
  });
  const [showSearchScr, setShowSearchScr] = useState(false);
  const [locationError, setLocationError] = useState(null);
  const [orderError, setOrderError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orderSummary, setOrderSummary] = useState(null);
  const context = useContext(AppContext);
  let cart = context.cart.get;
  const { header } = context;
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
    libraries,
  });
  const mapRef = useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  // header
  useEffect(() => {
    header.set({ type: "PAGE_NAME", text: "Select Delivery location" });
  }, []);

  useEffect(() => {
    if (!isLoaded) return;
    if (
      curPos.lat &&
      curPos.lng &&
      curPos.lat === searchPos.lat &&
      curPos.lng === searchPos.lng
    )
      return;

    const fetchDetails = async () => {
      try {
        setLoading(true);
        // get lat/lng
        const position = await getCoordinates();
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;
        // reverse geocode
        const geocoder = new window.google.maps.Geocoder();

        const location = { lat: latitude, lng: longitude };

        geocoder.geocode({ location }, (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              setSearchPos({
                lat: latitude,
                lng: longitude,
                address: results[0].formatted_address,
              });
            } else {
              throw new Error("Address not found");
            }
          } else {
            throw new Error("Geocoder failed");
          }
        });
      } catch (err) {
        setLocationError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [isLoaded, curPos]);

  const handleLocationSelection = (obj) => {
    setSearchPos({ ...obj });
    setShowSearchScr(false);
  };

  const handleCurrentLocation = () => {
    setCurPos({ lat: null, lng: null, address: null });
    setShowSearchScr(false);
  };

  const getCenter = () => {
    return {
      lat: searchPos.lat || center.lat,
      lng: searchPos.lng || center.lng,
    };
  };

  // place order
  const handleOrderResponse = (response) => {
    if (response.success) {
      setOrderSummary(response.orderSummary);
      context.cart.set(null);
      context.session.set(response.cartDetails.customerID);
      localStorage.removeItem("cart");
      localStorage.setItem(
        "session",
        JSON.stringify(response.cartDetails.customerID)
      );
    } else {
      setOrderError(response.error);
    }
  };

  const handleOrderFailure = (err) => {
    if (err) {
      console.log(err);
      if (err.error) {
        if (err.error.error) setOrderError(err.error.error);
        else {
          setOrderError(err.error);
        }
      }
    } else {
      setOrderError(
        "Order Placement Failed due to some reason. Please try after sometime."
      );
    }
  };

  const placeOrder = (address) => {
    publishEvent("ON_SUBMIT_PLACE_ORDER", getCartBundle(cart));
    let request = createOrderRequest(cart, address);
    setLoading(true);
    let startTime = new Date().getTime();
    serverApiHandler
      .placeOrder(request)
      .then((resp) => {
        let ttms = new Date().getTime() - startTime;
        publishEvent("ON_SUCCESS_ORDER", getOrderBundle(resp.orderSummary), ttms);
        handleOrderResponse(resp);
      })
      .catch((err) => {
        let ttms = new Date().getTime() - startTime;
        publishEvent("ON_FAILURE_ORDER", null, ttms);
        handleOrderFailure(err);
      })
      .finally((resp) => {
        setLoading(false);
      });
  };

  const retryOrder = () => {
    setOrderError(null);
  };

  return (
    <>
      {loading || !isLoaded ? (
        <Loader />
      ) : (
        <>
          {orderSummary ? (
            <PostOrder orderSummary={orderSummary} />
          ) : (
            <>
              {orderError || loadError ? (
                <ErrorScreen
                  text={orderError || "Could not load Map"}
                  action={orderError ? retryOrder : null}
                />
              ) : (
                <div className="new-address relative">
                  <GoogleMap
                    id="map"
                    mapContainerStyle={{
                      width: "100vw",
                    }}
                    zoom={17}
                    center={getCenter()}
                    options={options}
                    onLoad={onMapLoad}
                  >
                    <Marker position={getCenter()} />
                    {locationError && (
                      <div className="location-error">
                        Please provide location access.
                      </div>
                    )}
                  </GoogleMap>
                  <div className="delivery-info-card">
                    <div className="location-row">
                      <div>
                        <img
                          src={pin}
                          alt={"pin"}
                          width="10px"
                          className="back-btn"
                        />
                        <span className="current-location">
                          {(searchPos && searchPos.address) ||
                            (locationError && "Location error") ||
                            "loading..."}
                        </span>
                      </div>
                      <div>
                        <button
                          className="change-address-btn"
                          onClick={() => setShowSearchScr(true)}
                        >
                          Change
                        </button>
                      </div>
                    </div>
                    <AddAdress
                      address={searchPos.address || ""}
                      checkout={placeOrder}
                    />
                  </div>
                  {showSearchScr && (
                    <SearchLocation
                      handleCurrentLocation={handleCurrentLocation}
                      handleLocationSelection={handleLocationSelection}
                      open={setShowSearchScr}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
