import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductList from "../../product/ProductList";
import Loader from "./../../common/Loader.js";
import serverApiHandler from "../../apiHandler/serverApiHandler";
import { getLocalized } from "./../../../utilities/Utils.js";
import {
  publishEvent,
  getSubCategoryBundle,
} from "./../../../utilities/ManageEvents.js";
import "./tabproductlist.css";
import { AppContext } from "../../../App";

function Tab({ tab, active }) {
  return (
    <div className={`tab-option ${active ? "active" : ""}`}>
      <span>{getLocalized(tab.name)}</span>
    </div>
  );
}

function Tabs({ tabs, selected, onSelectTab }) {
  const [swiper, setSwiper] = useState(null);
  const context = useContext(AppContext);
  const header = context.header.get;

  if (selected > -1 && swiper) {
    //.slideTo triggers a sliding effect to the given index
    swiper.slideTo(selected - 1);
  }

  const clickedTab = (i) => {
    publishEvent("ON_CLICK_SUB_CATEGORY", getSubCategoryBundle(tabs[i]));
    onSelectTab(i);
  };

  const toBeShown = (limit) => (tabs.length > limit ? limit : tabs.length);
  const style = {
    top: header.type === "SEARCH_STORE_NAME_DESKTOP" ? "120px" : "55px",
  };

  //used hasProducts property to check if the subcategory has any products or not
  return (
    <div className={"tab"} style={style}>
      <Swiper
        effect="fade"
        slidesPerView={1}
        breakpoints={{
          320: { slidesPerView: toBeShown(3) },
          576: { slidesPerView: toBeShown(4) },
          768: { slidesPerView: toBeShown(5) },
        }}
        onSwiper={(swiper) => setSwiper(swiper)} //::: console displaying a warning
        onSlideChange={(swiper) => console.log(swiper.realIndex)}
      >
        {tabs.map((tab, i) =>
          tab.hasProducts ? (
            <SwiperSlide
              key={i}
              onClick={() => {
                clickedTab(i);
              }}
            >
              <Tab key={i} tab={tab} active={selected === tab.index} />
            </SwiperSlide>
          ) : null
        )}
      </Swiper>
    </div>
  );
}

function TabContent({ uinode, active, adjustHeight, idx, checkHasProducts }) {
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(null);
  const { params } = uinode;

  useEffect(() => {
    if (!products) {
      setLoading(true);
      let startTime = new Date().getTime();
      serverApiHandler
        .getProductListResponse("?" + params)
        .then((resp) => {
          let ttms = new Date().getTime() - startTime;
          setProducts(resp.productDetailsList[0].browseEntityDetailsList);
          if (resp.productDetailsList[0].browseEntityDetailsList.length !== 0) {
            checkHasProducts(idx);
          }
          adjustHeight(idx);
          publishEvent(
            "ON_LOAD_PRODUCT_LIST_RESPONSE",
            {
              params: params,
              products: resp.productDetailsList[0].browseEntityDetailsList,
            },
            ttms
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .finally((resp) => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <div style={{ minHeight: "70vh" }}>
      {loading ? (
        <Loader />
      ) : (
        <ProductList products={products} emptyMessage={"No products"} />
      )}
    </div>
  );
}

function createTabObjs(nodes) {
  return nodes.map((node, index) => {
    let name = node.entityData.displayTitle;
    return { index, name, hasProducts: false };
  });
}

export default function TabProductList({ uinode }) {
  const { childNodes } = uinode;
  const [selected, setSelected] = useState(0);
  const [swiper, setSwiper] = useState(null);
  const [autoHeight, setAutoHeight] = useState(false);
  const [tabs, setTabs] = useState(createTabObjs(uinode.childNodes));

  const slideChanged = (i) => {
    if (i !== selected) {
      publishEvent("ON_SLIDE_PRODUCT_LIST", { cidx: i });
      setSelected(i);
    }
  };

  useEffect(() => {
    if (swiper) {
      setTimeout(() => {
        swiper.update();
      }, 100);
    }
  }, [autoHeight]);

  const tabSelected = (index) => {
    setSelected(index);
    swiper.slideTo(index);
  };

  const adjustHeight = (idx) => {
    if (idx == 0) {
      setAutoHeight(true);
    }
  };

  const checkHasProducts = (i) => {
    //console.log(i, "checkHasProducts");
    let t = [...tabs];
    t[i].hasProducts = true;
    setTabs(t);
  };

  return (
    <div>
      <Tabs tabs={tabs} selected={selected} onSelectTab={tabSelected} />

      <div className="top-twenty"></div>
      <Swiper
        autoHeight={true}
        effect="fade"
        slidesPerView={1}
        onSwiper={(swiper) => {
          if (swiper != null) setSwiper(swiper);
        }}
        onSlideChange={(swiper) => {
          slideChanged(swiper.realIndex);
        }}
      >
        {childNodes.map((child, i) => (
          <SwiperSlide key={i}>
            <TabContent
              key={i}
              uinode={child}
              adjustHeight={adjustHeight}
              idx={i}
              checkHasProducts={checkHasProducts}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
