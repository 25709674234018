import React from "react";
import "./Style.css";

export default function CustomProgress({width, backColor, lineColor}) {

  const getWidth = () => {
    console.log(width);
    if(width < 100) {
      return width + "%";
    }
    return "100%";
  }

    return (
      <div>
        <div className="back-bar-small" style={{ backgroundColor: backColor }}></div>
        <div className="inner-bar-small" style={{width: getWidth(), backgroundColor: lineColor}}></div>
      </div>
    );
}
