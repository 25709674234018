import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import CoreCard from './../card/CoreCard.js';

export default function FullWidthCarousalWithoutIndicator({val}) {
	const {childNodes} = val;
	return(
		<div className="group">
			<div className="fwcwi-children">
			<Swiper effect="fade" slidesPerView={1}>
  				{childNodes.map((val, i) => { 
 					return <SwiperSlide key={i}><CoreCard val = {val} /></SwiperSlide>
  				})}
			</Swiper>	
			</div>
		</div>
	)
}
