import React from 'react';
import CoreCard from './../card/CoreCard.js';

export default function GridWith3Columns({val}) {
	const {childNodes} = val;
	return(
		<div className="group gw3c">
			<div className="group-left-title">{val.entityData.displayTitle.englishText}</div>
			<div className="gw3c-children">
  					{childNodes.map((val, i) => { 
 						return <CoreCard val = {val} key={i}/>
  					})}
			</div>
		</div>
	)
}
