import React, { useEffect }  from 'react';
import SearchDisplay from './../search/SearchDisplay.js';
import {publishEvent} from './../../utilities/ManageEvents.js';
import call from './../../assets/call.svg';
import whatsappgreen from './../../assets/whatsapp_green.svg';
import blackmenu from './../../assets/black_menu.svg';

const mobileCoverUrl = "https://j24storelogoandcovers.s3.ap-southeast-1.amazonaws.com/coverpages/j24-mob.jpg"

const StoreContactInfo = ({store}) => {
	return(
		<>
		<div className="store-contact-wrapper">
			<div className="row">
				<div className="col-9">
					<div className="store-info-name dotted-text" style={{fontWeight: '500'}}>{store.storeName}</div>
					<div className="top-six store-s-address" style={{color: '#8F8F8F'}}>{store.storeShortAddress}</div>
				</div>
				<div className="col-3">
					<div className="row">
						<div className="col-6 centered"> 
							{(store && store.phoneNumber) 
								?
								<a href={'tel:'+store.phoneNumber} rel="noopener noreferrer" onClick={() => {return publishEvent('ON_CLICK_CALL_ICON', {num: store.phoneNumber})}}><img src={call} alt={'call'} className={'back-btn'}/></a>
								:
								null
							}
						</div>
						<div className="col-6 centered">
							{(store && store.phoneNumber) 
								?
								<a href={'https://wa.me/'+store.phoneNumber} rel="noopener noreferrer" onClick={() => {return publishEvent('ON_CLICK_WHATSAPP_ICON', {num: store.phoneNumber})}}target="_blank"><img src={whatsappgreen} alt={'whatsapp'} className={'back-btn'}/></a>
								:
								null
							}	
						</div>
					</div>
				</div>
			</div>
		</div>
		</>
	);
}

const StoreCoverMobile = ({store}) => {
	let coverUrl = store && store.storeMobileCoverUrl ? store.storeMobileCoverUrl : mobileCoverUrl;

	return (<div>
		<div className = {'store-cover-mob'} style={{backgroundImage : 'url('+coverUrl+')', height: '250px'}}>
		<div className="cover-overlay"></div>
			<SearchDisplay />
		</div>
		{store ? <StoreContactInfo store={store}/>:null}
	</div>);

}

export default StoreCoverMobile;