import React from "react";
import "./Style.css";

const numfor = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
  minimumFractionDigits: 0,
});

export default function TransactionTransaction({txn}) {

  return (
    <div className="target-transaction row">
      <div className="jt-col-75">
        <div className="target-txn-label">{txn.labelString}</div>
      </div>
      <div className="jt-col-25">
        <div className="target-txn-amount pull-right">
          <div className={txn.transactionType === "CREDIT" ? "credit-text" : "debit-text"}>{numfor.format(txn.value)}</div>
        </div>
      </div>
    </div>
  );
}

