import React from "react";
import TitleOnly from "./TitleOnly.js";
import PageWithTabs from "./PageWithTabs.js";
import ProductListLayout from "./ProductListLayout.js";
import {publishEvent, getBrowseBundle} from './../../../utilities/ManageEvents.js';

function getPage(val) {
  if (!val) return <div>loading page</div>;
  switch (val.layoutType) {
    case "TO":
      return <TitleOnly val={val} />;
    case "PWT":
      return <PageWithTabs val={val} />;
    case "PL":
      return <ProductListLayout uinode={val} />;
    default : 
      publishEvent('ON_ERROR_UNKNOWN_BROWSE_LAYOUT', getBrowseBundle(val));
      return '';
  }
};

export default function CorePage(props) {
  return (<div>{getPage(props.val)}</div>)
}
