import React from "react";
import CorePage from "./page/CorePage.js";
import CoreGroup from "./group/CoreGroup.js";
import CoreCard from "./card/CoreCard.js";

const getBrowseRender = (node) => {
  if (!node) return <div>loading uinode</div>;
  switch (node.nodeType) {
    case "PAGE":
      return <CorePage val={node} />;
    case "GROUP":
      return <CoreGroup val={node} />;
    case "CARD":
      return <CoreCard val={node} />;
    default :
      return <CorePage val={node} />; 
  }
}

export default function UINode({node}) {
  return <div className="uinode"> {getBrowseRender(node)}</div>;
}
