import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import serverApiHandler from "./../apiHandler/serverApiHandler.js";
import { formatToIndianCurrencyRounded } from "./../../utilities/Utils.js";
import "./Style.css";

export default function MoneySaved() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [saved, setSaved] = useState(null);

  useEffect(() => {
    setLoading(true);
    serverApiHandler
      .getLoyaltySavings()
      .then((resp) => {
        setSaved(resp);
      })
      .catch((error) => {
        setError(true);
      })
      .finally((resp) => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="money-saved">
      {loading || error ? (
        loading ? (
          <>calculating...</>
        ) : (
          <span className="red-text">
            Something went wrong. Please try again.
          </span>
        )
      ) : (
        <>
          <div className="gc-txt-md fw-700">{formatToIndianCurrencyRounded(saved)}</div>
          <div className="flex justify-content-between gc-txt-sm">
            <div>
              {saved
                ? "saved so far. That's cool!"
                // : "worth Jumbocoins spent at J24 stores"}
                //  CODE:J24DEMO
                : "worth Jumbocash spent at J24 stores"}
            </div>
            {saved > 0 && (
              <Link to="/greencard-history" className="view fw-700">
                {" View > "}
              </Link>
            )}
          </div>
        </>
      )}
    </div>
  );
}
