import React, { useEffect, useState, useContext } from "react";
import serverApiHandler from "../../apiHandler/serverApiHandler";
import BusinessCredit from "./business-credit";
import Button from "@material-ui/core/Button";
import AutoDebitDetailsCard from "./auto-debit-details-card";
import arrow from "../../../assets/right-arrow.svg";
import Payment from "./payments.js";
import { NavLink } from "react-router-dom";
import "./CreditStyle.css";
import { sendEventToAlooma } from "../../../utilities/ManageEvents";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { PaymentResponse } from "./payment-response.js";
import UnlockCredit from "./unlock-credit.js";
import ErrorScreen from "../../error/error";
import { removeParams } from "../../../utilities/urlUtils";
import { AppContext } from "../../../App";
import Loader from "../../common/Loader";

function CurrentCredit() {
  const context = useContext(AppContext);
  const [loader, setloader] = useState(false);
  const [errorcame, setErrorCame] = useState(false);
  const [appData, setAppData] = useState({
    creditLineDetails: null,
    creditUnlockReasons: null,
    showCIBIL: false,
    dialogeOpen: false,
    loading: true,
    snackbarOpen: false,
  });

  useEffect(() => {
    context.header.set({ type: "PAGE_NAME", text: "JPay Later" });
    document.title = "JPay Later";
    fetchTargets();
    console.log(context);
  }, []);

  function Transition(props) {
    return (
      <Slide
        direction="up"
        {...props}
        style={{
          overflow: "visible",
        }}
      />
    );
  }
  const handleDialogClose = () => {
    setAppData({
      dialogeOpen: false,
    });
    const params = [
      "showPaymentResponse",
      "isPaymentSuccessful",
      "paymentOrderId",
      "paymentTransactionId",
      "paymentError",
      "paymentSignature",
      "timestamp",
      "paymentAmount",
    ];
    window.history.replaceState(
      null,
      null,
      removeParams(params, window.location.href)
    );
  };

  const getClearedPayments = () => {
    return (
      <div>
        <NavLink
          to={{
            pathname: "/clrd",
            state: {
              provider: appData.creditLineDetails.creditProductName,
            },
          }}
        >
          <div className="clearedNextPage">
            <div className="row">
              <div className="jt-col-94">Recently cleared payments</div>
              <div className="jt-col-6">
                <div className="sider" style={{ top: "70%" }}>
                  <img src={arrow} width="30px" alt=">" />
                </div>
              </div>
            </div>
          </div>
        </NavLink>
      </div>
    );
  };

  const sortShowingOrderAndSetState = (
    creditLine,
    showCIBIL,
    creditUnlockReasons
  ) => {
    window.creditLineData = creditLine;
    window.cibil = showCIBIL;
    window.unlockReasons = creditUnlockReasons;
    if (creditLine != null) {
      creditLine.transactionGroupsList.sort(function (a, b) {
        return a.displayOrder - b.displayOrder;
      });
      // document.title = creditLine.headerText;
      document.title = "Jpay Later";
    }
    setAppData({
      creditLineDetails: creditLine,
      creditUnlockReasons: creditUnlockReasons,
      showCIBIL: showCIBIL,
    });
  };

  const callGoHome = () => {
    window.Android.goHome();
  };

  const callAndroid = () => {
    window.Android.stopLoader();
  };

  const sendOrderNowEvent = () => {
    var eventName = "ON_CLICK_ORDER_NOW";
    var eventData = {
      business_id: window.bzid,
    };
    sendEventToAlooma(eventName, eventData);
  };

  const sendEvent = (bzid, startTime, status) => {
    var eventName = "LATENCY_CREDIT_PAGE_RESPONSE";
    var endTime = new Date().getTime();
    var loadtime = endTime - startTime;
    var eventData = {
      business_id: bzid,
      load_time: loadtime,
      current_status: status,
      timestamp: endTime,
    };
    sendEventToAlooma(eventName, eventData);
  };
  const sendFirstEvent = (bzid, eventName) => {
    var endTime = new Date().getTime();
    var eventData = {
      business_id: bzid,
      timestamp: endTime,
    };
    sendEventToAlooma(eventName, eventData);
  };
  const sendErrorEvent = (bzid, startTime, message) => {
    var eventName = "ON_LOAD_ERROR";
    var endTime = new Date().getTime();
    var loadtime = endTime - startTime;
    var eventData = {
      business_id: bzid,
      load_time: loadtime,
      timestamp: endTime,
      errorMessage: message,
    };
    sendEventToAlooma(eventName, eventData);
  };

  const showPGFeatureUI = () => {
    const crdtLineDetails = appData.creditLineDetails;
    if (
      !crdtLineDetails ||
      !crdtLineDetails.transactionGroupsList ||
      !crdtLineDetails.transactionGroupsList.length
    )
      return false;
    const delayedGrp = crdtLineDetails.transactionGroupsList.find(
      (x) => x.groupType === "DELAYED"
    );
    if (!delayedGrp) return false;
    return true;
  };

  function fetchTargets() {
    // https://prod.api.jumbotail.com/credit/details/BZID-1304438756
    var startTime = new Date().getTime();
    setloader(true);
    serverApiHandler
      //.getCreditDetails("BZID-1304438756")
      .getCreditDetails(context.user.get.bzId)
      .then((resp) => {
        console.log(resp);
        const {
          isEligibleForCibilCheck,
          creditLineAndTransactionsDetailsList: crdtLineRsp,
          creditUnlockReasons,
        } = resp;
        var creditLine = crdtLineRsp.length
          ? crdtLineRsp[0].creditLineDetails
          : null;
        sendFirstEvent(
          // bzid,
          context.user.get.bzId,
          isEligibleForCibilCheck
            ? "ON_LOAD_CREDIT_UNLOCK_PAGE"
            : "ON_LOAD_CREDIT_PAGE"
        );
        //console.log(creditLine)
        sortShowingOrderAndSetState(
          creditLine,
          isEligibleForCibilCheck,
          creditUnlockReasons
        );
      })
      .catch((error) => {
        setAppData({ errorcame: true });
        sendErrorEvent(context.user.get.bzId, startTime, error.error);
        // sendErrorEvent(bzid, startTime, error.error);
      })
      .finally(() => {
        // this.setState({ loading: false });
        // callAndroid();
        setloader(false);
      });
  }

  var payments;
  if (appData.creditLineDetails != null) {
    payments = appData.creditLineDetails.transactionGroupsList.map(
      (payment, index) => {
        return (
          <Payment
            payment={payment}
            provider={appData.creditLineDetails.creditProductName}
            key={index}
            //bzid={window.bzid}
            bzid={context.user.get.bzId}
          />
        );
      }
    );
  }

  if (loader) {
    return <Loader />;
  }

  console.log(appData);
  if (appData.showCIBIL) {
    // return (
    //   <UnlockCredit
    //     reasons={appData.creditUnlockReasons}
    //     language={window.language}
    //   />
    // );
    return (<div>This feature is not available for now</div>)
  }

  //window.bzid
  return (
    <div style={{ backgroundColor: "#f6f6f6", minHeight: "100vh" }}>
      {context.user.get.bzId && appData.creditLineDetails ? (
        <div>
          <BusinessCredit credit={appData.creditLineDetails} />

          {showPGFeatureUI() && (
            <div className="pg-banner">
              Click on a transaction below to complete your payment.
            </div>
          )}
          {/* <AutoDebitDetailsCard
            attributes={appData}
          ></AutoDebitDetailsCard> */}

          <div> {payments} </div>
          <div>{getClearedPayments()}</div>
          {appData.creditLineDetails &&
            appData.creditLineDetails.transactionGroupsList.length === 0 ? (
            <div>
              <div className="centralize no-trnsc">
                <div className="use-credit-next">
                  Pay by credit in your next delivery
                </div>
                <div className="order-now" onClick={callGoHome}>
                  <Button
                    variant="contained"
                    size="medium"
                    className="order-now-btn"
                    onClick={sendOrderNowEvent}
                  >
                    ORDER NOW
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
          {window.showPaymentResponse == "true" && (
            <Dialog
              open={appData.dialogeOpen}
              TransitionComponent={Transition}
              maxWidth="xs"
              classes={{ paperWidthXs: "MuiDialog-paper" }}
              onClose={handleDialogClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              className="payment-response-diag"
            >
              <PaymentResponse close={handleDialogClose} cta={null} />
            </Dialog>
          )}
        </div>
      ) : null}
      {errorcame && !appData.creditLineDetails ? (
        <div>
          <ErrorScreen />
        </div>
      ) : null}
    </div>
  );
}

export default React.memo(CurrentCredit);
