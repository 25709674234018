import React, { useContext } from 'react';
import Cart from './Cart.js';
import Product from './../product/Product.js';
import { AppContext } from './../../App.js';
import { shouldShowItems } from './../../utilities/CartUtils.js';
import EmptyCart from './EmptyCart';
import { makeCartFromSyncResponse} from './../../utilities/CartUtils.js';



export default function CartDropdown() {
  const context = useContext(AppContext);
  let cart =  makeCartFromSyncResponse(context.cart.get)

  const items = () => {
    const style = {
      padding: "10px",
      marginBottom: "0",
      borderRadius: "0",
      border: "none",
    }

    return cart.cartDetails.activeCartItems.map((item, i) => <Product dropdown={true} key={item.listingDetails.jpin} product={item.productDetails} inCartWebView={true} cartDropdown={true} style={style} />)
  }

  return (<div className="cart-dropdown">
      {shouldShowItems(cart) ?
        <div>
          <div className="cart-dropdown-inner">{items()}</div>
          <Cart relativePos />
        </div>
        :
        <EmptyCart />
      }
  </div>)
}