import React, {useState, useContext} from 'react';
import BackDrop from './../drawer/BackDrop.js';
import {AppContext} from './../../App.js';
import {getLocalized} from './../../utilities/Utils.js';
import './Style.css';


const getPromiseDate = (listing) => {
	if(!listing.qtyPromiseDetailsMap) return new Date().getTime();
	let keys = Object.keys(listing.qtyPromiseDetailsMap);
	return listing.qtyPromiseDetailsMap[keys[0]].promiseTime;
}

export default function Cart({msgs, action}) {
	const [current, setCurrent] = useState(0);
	const [msg, setNotes] = useState(msgs[0]);

	const context = useContext(AppContext);
    const cart = context.cart.get;

	const close = () => {}

	const getIndex = () => {
		for(let i in cart.cartDetails.activeCartItems) {
			if(cart.cartDetails.activeCartItems[i].cartItemID === msg.item.cartItemID) {
				return i;
			}
		}
		return -1;
	}

	const updateCart = () => {
		let total = cart.cartDetails.activeCartItems.reduce((sum, item) => {
			return sum + (item.finalPricePerUnit * item.selectedQuantity)
		}, 0);
		cart.cartDetails.cartAmount = total;
		context.cart.set(cart);
		localStorage.setItem('cart', JSON.stringify(cart));
	}


	const acceptCurrentCondition = () => {
		let type = msg.message.type;
		let i = getIndex();
        switch (type) {
        	case 'priceincrease':
        	case 'pricedecrease':
        		cart.cartDetails.activeCartItems[i].cartReferenceDetails.referenceFinalPricePerUnit = cart.cartDetails.activeCartItems[i].finalPricePerUnit;
        		updateCart();
                break;
            case 'quantitydecrease':
            	cart.cartDetails.activeCartItems[i].cartReferenceDetails.referenceSelectedQuantity = cart.cartDetails.activeCartItems[i].selectedQuantity;
            	updateCart();
                break;
            case 'promisechanged':
            	let promiseTime = getPromiseDate(cart.cartDetails.activeCartItems[i].listingDetails);
            	cart.cartDetails.activeCartItems[i].cartReferenceDetails.referencePromiseTime = promiseTime;
            	updateCart();
                break;
            default :
            	break;
        }
    }

    const removeCartItem = () => {
    	let i = getIndex();
    	cart.cartDetails.activeCartItems.splice(i, 1);
    	updateCart();
    }

	const btnClicked = (ops) => {
		switch(ops) {
			case 'remove' :
				removeCartItem();
				break;
			case 'accept' :
				acceptCurrentCondition();
				break;
			default :
            	acceptCurrentCondition();
            	break;
		}
		if(msgs.length - 1 === current) {
			action();
		} else {
			setNotes(msgs[current + 1]);
			setCurrent(current + 1);
		}
	}

	const getButtons = () => {
		if(msg.message.type === 'outofstock') {
			return <div><button className={'remove-itm'} onClick={() => {btnClicked('remove')}}>X Remove</button></div>
		}
		return <div> <button className={'accept-itm'} onClick={() => {btnClicked('accept')}}> Accept</button> <button className={'remove-itm'} style={{marginLeft: '10px'}} onClick={() => {btnClicked('remove')}}>X Remove</button> </div>
	}

	return(<div>
		<BackDrop onClick={close}/>
		<div className={'accck'}>
			<div><img src={msg.item.productDetails.imageUrls[0].thumbnail} alt={'product'} className={'alrt-pr-img'}/></div>
			<div className={"top-ten"}>{getLocalized(msg.item.title)}</div>
			<div>
				<div className={"alrt-title top-ten"}>{msg.message.title}</div>
				<div className={"alrt-msg top-ten"}>{msg.message.message}</div>
			</div>
			<div className={'top-twenty'}>{getButtons()}</div>
		</div>
	</div>)
}
