import React, { useState, useEffect } from "react";
import CustomProgress from "./CustomProgress.js";

import greyFlag from "./../../assets/grey-flag.svg";
import whiteFlag from "./../../assets/white-flag.svg";

import greenFlag from "./../../assets/green-flag.svg";
import greenStanding from "./../../assets/standing-green.svg";
import greenAchieved from "./../../assets/achieved-green.svg";

import blackFlag from "./../../assets/flag-black.svg";
import blackStanding from "./../../assets/standing-black.svg";
import blackAchieved from "./../../assets/achieved-black.svg";

import lightBlackFlag from "./../../assets/light-black-flag.svg";
import lightBlackStanding from "./../../assets/standing-light-black.svg";
import lightBlackAchieved from "./../../assets/achieved-light-black.svg";

import lightGreenFlag from "./../../assets/light-green-flag.svg";
import lightGreenStanding from "./../../assets/standing-light-green.svg";
import lightGreenAchieved from "./../../assets/achieved-light-green.svg";

import jumbocoin from "./../../assets/jumbocoin.svg";
import "./Style.css";


const numfor = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
  minimumFractionDigits: 0,
});

export default function TargetBar({schemeMemberTarget, page}) {
  const [confs, setConfs] = useState({
    RUNNINGMAIN: {
      image: "green",
      lineColor: "#58a159",
      backColor: "#d8d8d8",
      unAchivedFlag: "gray",
      completedColor: "#58a159",
      completeImage: "green",
      achievedMileTextColor: "#58a159",
      textColor: "#111111",
      cashBackColor: "#f5a623",
    },
    PASTMAIN: {
      image: "black",
      lineColor: "#606060",
      backColor: "#d8d8d8",
      unAchivedFlag: "gray",
      completedColor: "#58a159",
      completeImage: "green",
      achievedMileTextColor: "#111111",
      textColor: "#111111",
      cashBackColor: "#f5a623",
    },
    RUNNINGDETAIL: {
      image: "lightGreen",
      lineColor: "#6acc6c",
      backColor: "#ffffff",
      unAchivedFlag: "white",
      completedColor: "#6acc6c",
      completeImage: "lightGreen",
      achievedMileTextColor: "#6acc6c",
      textColor: "#ffffff",
      cashBackColor: "#fff",
    },
    PASTDETAIL: {
      image: "lightBlack",
      lineColor: "#afafaf",
      backColor: "#ffffff",
      unAchivedFlag: "white",
      completedColor: "#6acc6c",
      completeImage: "lightBlack",
      achievedMileTextColor: "#ffffff",
      textColor: "#ffffff",
      cashBackColor: "#fff",
    }
  });

  const [flag, setFlag] = useState({
    gray: greyFlag,
    white: whiteFlag,
    green: greenFlag,
    black: blackFlag,
    lightGreen: lightGreenFlag,
    lightBlack: lightBlackFlag});

  const [images, setImages] = useState({
    green: {
      standing: greenStanding,
      running: greenStanding,
      achieved: greenAchieved,
      flag: greenFlag,
    },
    black: {
      standing: blackStanding,
      running: blackStanding,
      achieved: blackAchieved,
      flag: blackFlag,
    },
    lightGreen: {
      standing: lightGreenStanding,
      running: lightGreenStanding,
      achieved: lightGreenAchieved,
      flag: lightGreenFlag,
    },
    lightBlack: {
      standing: lightBlackStanding,
      running: lightBlackStanding,
      achieved: lightBlackAchieved,
      flag: lightBlackFlag,
    }
  });

  const [targetAchieved, setTargetAchieved] = useState(false);
  const [targetMaxValue, setTargetMaxValue] = useState(0);
  const [wid, setWid] = useState(0);
  const [rightWid, setRightWid] = useState(0);
  const [milestones, setMilestone] = useState([]);
  const [atAchieved, setAtAchieved] = useState(false);
  const [lastMile, setLastMile] = useState(null);
  const [conf, setConf] = useState(confs[page]);

  useEffect(() => {
    console.log(schemeMemberTarget);
    console.log(page);
    if (schemeMemberTarget && schemeMemberTarget.targetSchemeBO && schemeMemberTarget.targetSchemeBO.milestoneBOs) {
      let milestones = schemeMemberTarget.targetSchemeBO.milestoneBOs;
      milestones.sort(function (a, b) {
        return a.atValue > b.atValue ? 1 : b.atValue > a.atValue ? -1 : 0;
      });
      setMilestone(milestones);
      let tMaxValue = milestones[milestones.length - 1].atValue;

      if (schemeMemberTarget.currentValue >= tMaxValue) {
        setTargetAchieved(true);
      } else {
        if (schemeMemberTarget.currentValue > 0) {
          let widt = (schemeMemberTarget.currentValue / tMaxValue) * 100;
          setRightWid(100 - widt);
          console.log("widt", widt);
          setWid(widt);
        } else {
          setWid(0);
        }
      }
      setTargetMaxValue(tMaxValue);
    }
  }, [])

  const showPayout = (payout, atValue) => {
    const conf = confs[page];
    if (payout.payoutMode === "JUMBOCOIN") {
      if (payout.payoutType === "ABSOLUTE") {
        return (
          <div className="pull-right">
            <img src={jumbocoin} alt="jumbocoin" style={{ width: "16px" }} align="left"/>{" "}
            &nbsp; {payout.value}
          </div>
        );
      } else if (payout.payoutType === "PERCENTAGE") {
        return (
          <div className="pull-right">
            <img src={jumbocoin} alt="jumbocoin" style={{ width: "16px" }} align="left"/>{" "}
            &nbsp; {payout.value} %
          </div>
        );
      }
    } else if (payout.payoutMode === "CASHBACK") {
      var mar = getMargin(atValue);
      var number = payout.value.toString().length;
      var len = mar / 2;
      while (number > 1) {
        len -= 2;
        number -= 1;
      }
      var clen = 0;
      if (mar > 15) {
        clen = mar - 15;
      }

      if (payout.payoutType === "ABSOLUTE") {
        return (
          <div>
            <div className="golden-cashback-amount" style={{ marginRight: len + "px", color: conf.cashBackColor }}>
              {numfor.format(payout.value)}
            </div>
            <div className="golden-cashback-text" style={{ marginRight: clen + "px", color: conf.cashBackColor }}>
              {payout.payoutMode}
            </div>
          </div>
        );
      } else if (payout.payoutType === "PERCENTAGE") {
        return (
          <div>
            <div className="golden-cashback-amount" style={{ marginRight: len + "px", color: conf.cashBackColor }}>
              {payout.value}%
            </div>
            <div className="golden-cashback-text" style={{ marginRight: clen + "px", color: conf.cashBackColor }}>
              {payout.payoutMode}
            </div>
          </div>
        );
      }
    }
    return null;
  }

  const getMargin = val => {
    var margin = 12;
    var len = val.toString().length - 3;
    if (len > 0) {
      margin = 12 + 3 * len;
    }
    return margin;
  }

  const getWidth = (width, val) => {
    var margin = getMargin(val);
    return "calc(" + width + "% + " + margin + "px)";
  }

  const miles = () => {  
    return milestones.map((mile, index) => {
      let width = (mile.atValue / targetMaxValue) * 100;
      let flag = greyFlag;
      if (mile.atValue < schemeMemberTarget.currentValue) {
        flag = flag[conf.image];
      } else if (mile.atValue > schemeMemberTarget.currentValue) {
        flag = flag[conf.unAchivedFlag];
      } else {
        atAchieved = true;
        return null;
      }
      return (
        <img className="milestone-flag" src={flag} alt="achieved" style={{ left: "calc(" + width + "% - 2px)" }}key={index}/>
      );
    });
  }

    //Deciding value and rewards per milestone
  const valueAndReward = () => {
    return milestones.map((mile, index) => {
      let width = (mile.atValue / targetMaxValue) * 100;
      if (width === 100) {
        // setLastMile(mile);
      }
      let pay = showPayout(mile.payoutBOs[0], mile.atValue);
      return (<div style={{ width: getWidth(width, mile.atValue) }} className="values-and-points-box"key={index}>
          <div className="stone" style={{color: mile.atValue <= schemeMemberTarget.currentValue? conf.achievedMileTextColor: conf.textColor,}}>
            {numfor.format(mile.atValue)}
          </div>
          <div className="reward" style={{ color: conf.textColor, marginTop: "4px" }}>
            {pay}
          </div>
        </div>
      )
    });
  }

  return (
    <>
    {!conf ?
    <div>
    </div>
    :
    <div>
      {!targetAchieved ? (
        <div className="progress-target" style={{ marginTop: "65px", marginRight: "15px" }}>
          <div>
            <div className="flags">
             {schemeMemberTarget.currentValue === 0 ? (
                <img className="milestone-statue" src={images[conf.image].standing} alt="yet to start" style={{ left: "0" }} />
              ) : null}
              {schemeMemberTarget.currentValue > 0 && !atAchieved ? (
                conf.image !== "black" && conf.image !== "lightBlack" ? (
                  <img className="milestone-statue" src={images[conf.image].standing}alt="standing"
                    style={{left: wid > 5 ? "calc(" + wid + "% - 10px)" : 0,}}/>
                ) : (
                  <img className="milestone-statue" src={images[conf.image].standing}
                    alt="standing" style={{left: wid > 4 ? "calc(" + wid + "% - 10px)" : 0,}}/>
                )
              ) : null}
              {atAchieved ? (
                <img className="milestone-statue" src={images[conf.image].achieved} alt="at achieved"
                  style={{ left: wid > 5 ? "calc(" + wid + "% - 10px)" : 0 }}/>
              ) : null}
              {miles}
              {schemeMemberTarget.currentValue > 0 ? (
                <div className="current-indicator" style={{left: wid < 20 ? 0 : null,
                    right: rightWid < 80 ? "" + rightWid + "%" : null, backgroundColor: conf.lineColor,}}>
                  <div>{numfor.format(schemeMemberTarget.currentValue)}</div>
                </div>
              ) : null}
            </div>
            <CustomProgress width={wid} backColor={conf.backColor} lineColor={conf.lineColor}/>
          </div>
          <div className="values-and-rewards">
            <div className="pull-left">
              <div className="stone" style={{color: 0 < schemeMemberTarget.currentValue ? conf.achievedMileTextColor: conf.textColor,}}>
                {numfor.format(0)}
              </div>
            </div>
            {valueAndReward()}
          </div>
        </div>
      ) : (
        <div className="achieved-target" style={{ marginTop: "15px" }}>
          <div style={{ marginTop: "10px" }}>
            <div className="row">
              <div className="jt-col-75">
                <div style={{ position: "absolute", bottom: "4px" }}>
                  <div className="cong-text" style={{ color: conf.textColor }}>
                    Congratulations! You have won
                  </div>
                  <div className="cong-reward">
                    <span style={{
                        border: "1px solid",
                        float: "left",
                        padding: "3px 8px",
                        borderColor: conf.completedColor,
                        color: conf.completedColor,
                      }}>
                      {//showPayout(lastMile.payoutBOs[0], lastMile.atValue)}
                    }
                    </span>
                  </div>
                </div>
              </div>
              <div className="jt-col-25">
                <img src={images[conf.completeImage].achieved} alt="achieved" className="achieved-target-img"/>
              </div>
            </div>
            <CustomProgress width="100" backColor={conf.backColor} lineColor={conf.completedColor}/>
          </div>
        </div>
      )}
    </div>
  }</>
  );
}
