import React from "react";
import warning from "../../assets/warning.svg"; 
import './Style.css';

export default function ErrorScreen({text, name, action}) {

    return (
      <div>
        <div className="error-box vertical-center">
          <div>
            <img src={warning} width="90px" height="80px" alt="error" />
          </div>
          <div className="error-text">
            {text ? text : "Some problem occcured, Please retry later.."}
          </div>
          <div style={{ marginTop: "60px" }}>
            <a href="/"><button className="error-gohome-btn"> GO HOME </button></a>
            {action ? <button className="error-retry-btn" style={{marginLeft : '10px'}} onClick={action}> {name ? name : 'RETRY' }</button> : null }
          </div>
        </div>
      </div>
    )
}