import React, { useState, useEffect, useContext } from 'react';
import AddProductQty from './AddProductQty.js';
import Loader from './../common/Loader.js';
import ErrorScreen from './../common/ErrorScreen.js';
import Cart from './../cart/Cart.js';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Pagination} from 'swiper';
import {getLocalized} from './../../utilities/Utils.js';
import serverApiHandler from './../apiHandler/serverApiHandler.js';
import {productBundle, publishEvent} from './../../utilities/ManageEvents.js';
import "../../App.css";
import { AppContext } from '../../App.js';

SwiperCore.use([Pagination]);

const ProductBannerCard = ({img}) => {
    const {fullImage} = img;
    return(<div>
        <div className="centered">
            <img src={fullImage} alt="product" className="product-img"/>
        </div>
    </div>)    
}

const ProductBanners = ({images}) => {
    useEffect(() => {
        images.forEach((img, idx) => {publishEvent('ON_LOAD_PRODUCT_IMAGE', {imgurl: img.fullImage, imgidx: idx, imgtype: 'fullImage'})});
    }, [images])

    return(
    <div className="product banners" >
        <div className="product-banners">
            <Swiper effect="fade" slidesPerView={1} spaceBetween={20} pagination={{clickable: true }} 
            style={{paddingTop: '12px', paddingBottom: '25px',marginBottom: '12px'}}>
            {images.map((img, i) => { 
               return <SwiperSlide key={i}><ProductBannerCard img={img} /></SwiperSlide>
            })}
            </Swiper>   
        </div>
    </div>);
}

const ProductInfo = ({product}) => {
    return (<div className="product-info">
        <div className="product-title">
            {getLocalized(product.title)}
        </div>
        <div className="price-qty">
            <AddProductQty product={product} quantity={1}/>
        </div>
    </div>)
}

const ProductPage = props => {
    const [productResponse, setProductResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const context = useContext(AppContext)
    const {showWebView, header} = context

    useEffect(() => {
		header.set({ 
			type: showWebView ? 'SEARCH_STORE_NAME_DESKTOP' : 'SEARCH_PAGE_NAME',
			text: "Product Details" })
	}, [showWebView])

    useEffect(() => {
        setLoading(true);
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let jpin = params.get('jpin');
        if(jpin){
            let startTime = new Date().getTime();
    		serverApiHandler.getByJpin(jpin)
    		.then(response => {
                let ttms = new Date().getTime() - startTime;
                publishEvent('ON_LOAD_PRODUCT_SCREEN', productBundle(response), ttms);
                setProductResponse(response)
    		}).catch(error => {
                let ttms = new Date().getTime() - startTime;
                publishEvent('ON_ERROR_PRODUCT_NOT_FOUND', null, ttms);
                setError(true)
    		}).finally(response => {
                setLoading(false)
    		})
        }
	}, [])
    
    const getProuctPage = (productResponse) => {
        return (<div className={'product-wrapper'}>
            <ProductBanners images={productResponse.imageUrls}/>
            <ProductInfo product={productResponse} />
            {
            // <div className="prod-desc-wrapper">
            //     <div className="payment-header">About Product</div>
            //     <div className="product-desc">{getLocalized(productResponse.description)}</div>
            // </div>
            }
        </div>);                
    }

	return(
        <React.Fragment>
			{(productResponse) ? getProuctPage(productResponse) : null}
			{(loading) ? <Loader /> : null}
			{(error) ? <ErrorScreen /> : null}
            {!showWebView && <Cart />}
        </React.Fragment>
        )
}

export default ProductPage;