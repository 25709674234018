import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App';
import QRCode from 'react-qr-code';
import Qr from './../../assets/qr.png';
import { Helmet } from 'react-helmet';

const icons = {
    qr: Qr,
};

export default function MyQr() {
    const context = useContext(AppContext);
    const { header } = context;
    const user = context.user.get;

    useEffect(() => {
        context.header.set({ type: 'PAGE_NAME', text: 'MY QR' });

        header.set({ type: 'PAGE_NAME', text: 'MY QR' });
        console.log(user);
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <meta charSet='utf-8' />
                <title>My Profile QR</title>
                <meta name='robots' content='noindex' />
            </Helmet>
            <div style={{ backgroundColor: '#' }} className='gc-wrapper'>
                <div
                    className='relative container'
                    style={{ paddingBottom: '100vh', marginTop: '30%' }}
                >
                    <div className='gc-txt-md centered'>
                        <h2>{user.firstName}</h2>
                        <p>{user.customerId}</p>
                    </div>
                    <br />
                    <div className='centered'>
                        <QRCode
                            style={{ borderRadius: '6px' }}
                            value={`username:${user.phoneNumber}`}
                        ></QRCode>

                        <br />
                        <p>Please use this to login at J24 Store</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
