import React from 'react';

export default function CIOLC(props) {

	const clicked = () => {
		props.clicked(props.val);
	}

	const {val} = props;
	return(<div className="CIOLC-wrapper" onClick={clicked}>
			<img src={val.entityData.displayLogo} alt={val.entityData.englishText} className="CIOLC-img"/>
		</div>)
}