// import React, { Component } from "react";
// import warning from "../../assets/warning.svg";
// import Button from "@material-ui/core/Button";
// import { sendEventToAlooma } from "../../utilities/ManageEvents";

// export default class ErrorScreen extends Component {
//   // constructor(props) {
//   // 	super(props);
//   // };

//   goHome() {
//     var eventName = "ON_CLICK_GO_HOME";
//     var eventData = {
//       business_id: window.bzid,
//     };
//     sendEventToAlooma(eventName, eventData);
//     window.Android.goHome();
//   }

//   render() {
//     return (
// <div>
//   <div className="error-box vertical-middle">
//     <div>
//       <img src={warning} width="90px" height="80px" alt="error" />
//     </div>
//     <div className="error-text">
//       Some problem occcured, Please retry later..
//     </div>
//     <div style={{ marginTop: "60px" }}>
//       <Button
//         variant="contained"
//         size="medium"
//         className="error-retry-btn"
//         onClick={this.goHome}
//       >
//         GO HOME
//       </Button>
//     </div>
//   </div>
// </div>
//     );
//   }
// }

import React, { useContext } from "react";
import warning from "../../assets/warning.svg";
import Button from "@material-ui/core/Button";
import { sendEventToAlooma } from "../../utilities/ManageEvents";
import { AppContext } from "../../App";

function ErrorScreen() {
  const context = useContext(AppContext);
  const goHome = () => {
    var eventName = "ON_CLICK_GO_HOME";
    var eventData = {
      //business_id: 'BZID-1304438756',
      business_id: context.user.get.bzId,
    };
    sendEventToAlooma(eventName, eventData);
    window.Android.goHome();
  };
  return (
    <div>
      <div className="error-box vertical-middle">
        <div>
          <img src={warning} width="90px" height="80px" alt="error" />
        </div>
        <div className="error-text">
          Some problem occcured, Please retry later..
        </div>
        <div style={{ marginTop: "60px" }}>
          <Button
            variant="contained"
            size="medium"
            className="error-retry-btn"
            onClick={goHome}
          >
            GO HOME
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ErrorScreen;
