import React, { useEffect, useState, useContext } from "react";
import TransactionTransaction from "./TransactionTransaction.js";
import qs from "query-string";
import serverApiHandler from "./../apiHandler/serverApiHandler.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./Style.css";
import { AppContext } from "../../App";

export default function TransactionHistory(props) {
  const [transactions, setTransactions] = useState(null);
  const [errorCame, setErrorCame] = useState(false);

  const context = useContext(AppContext)

  useEffect(() => {
    context.header.set({ type: "PAGE_NAME", text: "Transaction History" });
    const {transactions} = window.location;
    if (transactions) {
      setTransactions(transactions);
    } else {
      let smtId = qs.parse(window.location.search).schemeTargetId;
      if (!smtId) {
        smtId = qs.parse(props.location.pathname)["/transaction-history/?schemeTargetId"];
      }
      let currentTime = new Date().getTime();
      if (smtId) {
        serverApiHandler.getTransactionsForSmtIdAndTime(smtId, currentTime)
          .then(resp => {
            setTransactions(resp.dailyTransactionResponseList)
          }).catch(error => {
            setErrorCame(true)
          });
      } else {
        setTransactions([]);
      }
    }
  }, [])

  const getTxns = () => {
    if (transactions) {
      return transactions.map((txn, index) => {
        return <TransactionTransaction txn={txn} key={index} />
      });
    }
  }
    
  return (
    <div className="included-transactions">
      <div className="label">Amount contributed towards the scheme</div>
      <div className="transactions">{getTxns()}</div>
      {!transactions && !errorCame ? (
        <div className="vertical-middle centralize" style={{ width: "100%" }}>
          <CircularProgress style={{ color: "#000", textAlign: "center" }} size={60} thickness={4}/>
        </div>
      ) : null}
    </div>
  );
}
