import React, { useContext } from 'react';
import { AppContext } from './../../App.js';
import StoreSelector from './../store/StoreSelector.js';
import HomePage from './HomePage.js';
import { Helmet } from 'react-helmet';

export default function Home() {
    const context = useContext(AppContext);
    const store = context.store.get;

    return (
        <React.Fragment>
            <Helmet>
                <meta charSet='utf-8' />
                <title>J24 Stores - Your Store Next Door</title>
                <meta
                    name='description'
                    content='Network of Modern Convenience Grocery stores solving for your daily needs.'
                />
            </Helmet>
            {!store ? <StoreSelector /> : <HomePage />}
        </React.Fragment>
    );
}
