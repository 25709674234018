import React, { useState, useEffect, useContext } from "react";
import TargetBar from "./TargetBar.js";
import orangeArrow from "./../../assets/orange-arrow.png";
import whiteArrow from "./../../assets/white-arrow.png";
import Button from "@material-ui/core/Button";
import { NavLink, useLocation } from "react-router-dom";
import jumbocoin from "./../../assets/jumbocoin.svg";
import serverApiHandler from "./../apiHandler/serverApiHandler.js";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import ProductDialog from "./ProductDialog.js";
import { publishEvent } from "./../../utilities/ManageEvents.js";
import "./Style.css";
import { AppContext } from "../../App";

const numfor = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
  minimumFractionDigits: 0,
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export default function TargetDetails(props) {
  const [target, setTarget] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [dialogeOpen, setDialogeOpen] = useState(false);
  const [includedProducts, setIncludedProducts] = useState(null);
  const [excludedProducts, setExcludedProducts] = useState(null);
  const [productsToShow, setProductsToShow] = useState(null);
  const [productsTitle, setProductsTitle] = useState(null);
  const [backColor, setBackColor] = useState(null);
  const [errorCame, setErrorCame] = useState(false);
  const [jcEarnedMessage, setJcEarnedMessage] = useState(null);

  const context = useContext(AppContext)

  let location = useLocation();

  useEffect(() => {
    context.header.set({ type: "PAGE_NAME", text: "Scheme Details" });
    let params = new URLSearchParams(location.search);
    let businsess = context.user.get.bzId;
    // let businsess = "BZID-1304471262";
    //let businsess = "BZID-1304438756";
    let alTarget;
    sendFirstEvent(businsess);
    if (location.state) {
      alTarget = location.state.target;
      sendLoadEvent(null, alTarget.schemeMemberTargetBO.targetSchemeBO, 0);
    }
    if (alTarget) {
      setTarget(alTarget);
    } else {

      //<!----------------------------- API calls for getting target details -----------------------------!>
      let targetDetail = window.targetDetail;
      if (!targetDetail) {
        let smtId = params.get('smtId');
        if (smtId) {
          serverApiHandler.getTargetDetailsBySmtId(smtId)
            .then(resp => {

              setTarget(resp);
              sendLoadEvent("POST_ORDER", resp.schemeMemberTargetBO.targetSchemeBO, 0);
            }).catch(error => {
              errorCame(true);
            });
        } else {
          console.log(params);
          let targetId = params.get('targetId');
          serverApiHandler.getTargetDetailsByIdAndMember(targetId, businsess)
            .then(resp => {
              setTarget(resp);
              sendLoadEvent("POST_ORDER", resp.schemeMemberTargetBO.targetSchemeBO, 0);
            }).catch(error => {
              setErrorCame(true);
            });
        }
      } else {
        setTarget(targetDetail);
      }
    }
    if (alTarget && !transactions) {
      fetchTransactions(alTarget);
      fetchProductsList(alTarget);
    }

  }, [])

  useEffect(() => {

    if (target) {
      let milestones = target.schemeMemberTargetBO.targetSchemeBO.milestoneBOs;

      if (milestones && milestones.length > 0) {
        let jumbocash_earned = 0;
        for (let i = 0; i < milestones.length; i++) {
          if (milestones[i].achievedMilestoneBO) {
            jumbocash_earned += milestones[i].achievedMilestoneBO.payoutDoneValue;
          }
        }
        let message = "Total JumboCash earned: " + jumbocash_earned;

        if (jumbocash_earned > 0) {
          setJcEarnedMessage(message);
        }

      }
    }

  }, [target]);

  //<!--------------------------------- Alooma Events ---------------------------------!>
  const sendLoadEvent = (source, target, ttms) => {
    let eventName = "ON_LOAD_SCHEME_DETAILS";
    let days_left = parseInt(
      Math.ceil((target.endTime - new Date().getTime) / 86400000),
      10
    );
    if (days_left >= 0 && !source) {
      source = "RUNNING_SCHEMES";
    } else if (days_left < 0 && !source) {
      source = "PAST_SCHEMES";
    }
    let eventData = {
      bzid: window.bzid,
      source: source,
      status: target.status,
      item_id: target.targetSchemeId,
      days_left: days_left,
      ttms: ttms,
      timestamp: new Date().getTime(),
    };
    publishEvent(eventName, eventData);
  }

  const sendClickEvent = (item_type) => {
    let eventName = "ON_CLICK_SCHEME_DETAILS_PAGE";
    let trgt = target.schemeMemberTargetBO.targetSchemeBO;
    let days_left = parseInt(
      Math.ceil((trgt.endTime - new Date().getTime) / 86400000),
      10
    );
    let eventData = {
      bzid: window.bzid,
      item_type: item_type,
      status: trgt.status,
      item_id: trgt.targetSchemeId,
      days_left: days_left,
      timestamp: new Date().getTime(),
    };
    publishEvent(eventName, eventData);
  }

  const sendShowProductEvent = () => {
    let eventName = "ON_CLICK_VIEW_PROUCTS";
    let trgt = target.schemeMemberTargetBO.targetSchemeBO;
    let days_left = parseInt(
      Math.ceil((trgt.endTime - new Date().getTime) / 86400000),
      10
    );
    let eventData = {
      bzid: window.bzid,
      status: trgt.status,
      item_id: trgt.targetSchemeId,
      days_left: days_left,
      timestamp: new Date().getTime(),
    };
    publishEvent(eventName, eventData);
  }

  const sendFirstEvent = bzid => {
    if (!bzid) {
      bzid = window.bzid;
    }
    let eventName = "ON_SCHEME_DETAILS_UI_RENDER";
    let eventData = {
      bzid: bzid,
      page_name: "SCHEME_DETAILS_PAGE",
      timestamp: new Date().getTime(),
    };
    publishEvent(eventName, eventData);
  }

  //<!--------------------------------- Creating request for Fetching Products ---------------------------------!>
  const getFetchJpinsRequest = entry => {
    let request;
    if (entry) {
      let productList = entry["JPIN"];
      if (productList) {
        let jpins = productList.map((jpin) => {
          return jpin.id;
        });
        request = {
          jpins: jpins,
        };
      } else {
        return null;
      }
    }
    return request;
  }

  //<!--------------------------------- Fetching Included Products on the basis of JPINS (Server Call) ---------------------------------!>
  const fetchIncludedProductList = target => {
    let inclusions = getFetchJpinsRequest(
      target.schemeMemberTargetBO.targetSchemeBO.schemeItemRules.includedEntries
    );
    if (inclusions && !includedProducts) {
      serverApiHandler.getProductListByUsingJpins(inclusions)
        .then(data => {
          setIncludedProducts(data.productInfos);
        }).catch(error => {
          console.log(error);
        });
    }
  }

  //<!--------------------------------- Fetching Excluded Products on the basis of JPINS (Server Call) ---------------------------------!>
  const fetchExcludedProductList = target => {
    let exclusions = getFetchJpinsRequest(
      target.schemeMemberTargetBO.targetSchemeBO.schemeItemRules.excludedEntries
    );
    if (exclusions && !excludedProducts) {
      serverApiHandler.getProductListByUsingJpins(exclusions)
        .then(data => {
          setExcludedProducts(data.productInfos);
        }).catch(error => {
          console.log(error);
        });
    }
  }

  //<!--------------------------------- Fetching Products on the basis of JPINS  ---------------------------------!>
  const fetchProductsList = target => {
    fetchIncludedProductList(target);
    fetchExcludedProductList(target);
  }

  //<!--------------------------------- Fetching transactions for this target (Server call) ---------------------------------!>
  const fetchTransactions = (target) => {
    let smtId = target.schemeMemberTargetBO.schemeMemberTargetId;
    if (smtId && !transactions) {
      let currentTime = new Date().getTime();
      serverApiHandler.getTransactionsForSmtIdAndTime(smtId, currentTime)
        .then(resp => {
          setTransactions(resp.dailyTransactionResponseList)
        }).catch(error => {
          console.log(error);
        });
    }
  }

  //<!--------------------------------- Managing Dialoge for JPINs ---------------------------------!>
  const showIncludedJpins = () => {
    sendShowProductEvent();
    productsTitle = "Eligible Items";
    backColor = "#2c552d";
    if (!includedProducts) {
      fetchIncludedProductList(target);
    }
    let toShow = [];
    let cappedItems = target.schemeMemberTargetBO.targetSchemeBO
      .contributionCapBOs;
    let cappedItemsMap = {};
    for (let i in cappedItems) {
      cappedItemsMap[cappedItems[i].entityId] = cappedItems[i];
    }
    for (let j of includedProducts) {
      if (!cappedItemsMap[j.jpin]) {
        toShow.push(j);
      }
    }
    productsToShow = toShow;
    setDialogeOpen(true);
  }

  const showExcludedJpins = () => {
    productsTitle = "Not Eligible Items";
    backColor = "#e47556";
    if (!excludedProducts) {
      fetchExcludedProductList(target);
    }
    productsToShow = excludedProducts;
    setDialogeOpen(true);
  }

  const handleDialogClose = () => {
    setDialogeOpen(false);
  }

  //<!--------------------------------- Get Reward in showing format (Render) ---------------------------------!>
  const getReward = (schemeBO) => {
    if (schemeBO) {
      let milestones = schemeBO.targetSchemeBO.milestoneBOs;
      milestones.sort(function (a, b) {
        return a.atValue > b.atValue ? 1 : b.atValue > a.atValue ? -1 : 0;
      });
      let lastMile = milestones[milestones.length - 1];
      let payout = lastMile.payoutBOs[0];

      if (payout.payoutMode === "JUMBOCOIN") {
        return (
          <div> Maximum reward{" "} <img
            src={jumbocoin}
            alt="jumbocoin"
            style={{ width: "16px" }}
            align="center"
          />{" "}
            <span className="detail-reward">
              {payout.value} {payout.payoutType === "PERCENTAGE" ? "%" : ""}
            </span>
          </div>
        );
      } else if (payout.payoutMode === "CASHBACK") {
        if (payout.payoutType === "ABSOLUTE") {
          return (
            <div>
              Maximum reward{" "}
              <span className="detail-reward">
                {numfor.format(payout.value)}
              </span>{" "}
              cashback
            </div>
          );
        } else if (payout.payoutType === "PERCENTAGE") {
          return (
            <div>
              Maximum reward{" "}
              <span className="detail-reward">
                {numfor.format((payout.value * lastMile.atValue) / 100)}
              </span>{" "}
              cashback
            </div>
          );
        }
      }
    }
    return null;
  }

  //<!--------------------------------- Get Included Scheme Rules (Render) ---------------------------------!>
  const getInclusion = () => {
    let inclusion;
    let included = target.schemeMemberTargetBO.targetSchemeBO.schemeItemRules.includedEntries;
    let cappedItems = target.schemeMemberTargetBO.targetSchemeBO.contributionCapBOs;
    if (included != null) {
      let includedKeys = Object.keys(included);
      let index = includedKeys.indexOf("JPIN");
      if (index > -1) {
        includedKeys.splice(index, 1);
        includedKeys.push("JPIN");
      }
      inclusion = includedKeys.map((entry, index) => {
        let entryData = included[entry];
        let dst;
        if (entry === "JPIN") {
          let cappedItemsMap = {};
          for (let i in cappedItems) {
            cappedItemsMap[cappedItems[i].entityId] = cappedItems[i];
          }
          let nonCappedItemInners = entryData.map((data, key) => {
            if (!cappedItemsMap[data.id]) {
              return data;
            }
            return null;
          });
          if (nonCappedItemInners.length > 2) {
            dst = (<div className="row" style={{ borderBottom: "1px solid #c6c6c6" }} key={index}>
              <div className="jt-col-50" onClick={showIncludedJpins()}>
                <div className="rule-text underlined">{nonCappedItemInners.length} products</div>
              </div>
              <div className="jt-col-50">
                <div className="rule-include-text">Eligible</div>
              </div>
            </div>
            );
          } else {
            dst = nonCappedItemInners.map((data, key) => {
              return (
                <div className="row" key={index} style={{ borderBottom: "1px solid #c6c6c6" }}>
                  <div className="jt-col-50">
                    <div className="rule-text">{data.name}</div>
                  </div>
                  <div className="jt-col-50">
                    <div className="rule-include-text">Eligible</div>
                  </div>
                </div>
              );
            });
          }
        } else {
          dst = entryData.map((data, key) => {
            for (let i in cappedItems) {
              if (cappedItems[i].entityId === data.id) {
                return null;
              }
            }
            return (
              <div
                className="row"
                key={index}
                style={{ borderBottom: "1px solid #c6c6c6" }}
              >
                <div className="jt-col-50">
                  <div className="rule-text">{data.name}</div>
                </div>
                <div className="jt-col-50">
                  <div className="rule-include-text">Eligible</div>
                </div>
              </div>
            );
          });
        }
        return dst;
      });
    }
    return inclusion;
  }

  //<!--------------------------------- Get Excluded Scheme Rules (Render) ---------------------------------!>
  const getExclusion = () => {
    let exclusion;
    let excluded = target.schemeMemberTargetBO.targetSchemeBO.schemeItemRules.excludedEntries;
    if (excluded != null) {
      let excludedKeys = Object.keys(excluded);
      if (excludedKeys.length > 0) {
        exclusion = excludedKeys.map((entry, key) => {
          let dst;
          let entryData = excluded[entry];
          if (entry === "JPIN" && entryData.length > 2) {
            dst = (
              <div className="row" style={{ borderBottom: "1px solid #c6c6c6" }}>
                <div className="jt-col-50" onClick={showExcludedJpins}>
                  <div className="rule-text underlined">{entryData.length} products</div>
                </div>
                <div className="jt-col-50">
                  <div className="rule-exclusion-text">Not Eligible</div>
                </div>
              </div>
            );
          } else {
            dst = entryData.map((data, key) => {
              return (
                <div
                  className="row"
                  key={key}
                  style={{ borderBottom: "1px solid #c6c6c6" }}
                >
                  <div className="jt-col-50">
                    <div className=" rule-text">{data.name}</div>
                  </div>
                  <div className="jt-col-50">
                    <div className=" rule-exclusion-text">Not Eligible</div>
                  </div>
                </div>
              );
            });
          }
          return dst;
        });
      }
    }
    return exclusion;
  }

  //<!--------------------------------- Get Capped Items in Scheme Rules (Render) ---------------------------------!>
  const getCapped = () => {
    let capped;
    let cappedItems = target.schemeMemberTargetBO.targetSchemeBO.contributionCapBOs;
    if (cappedItems != null) {
      capped = cappedItems.map((entry, key) => {
        return (<div
          className="row"
          key={key}
          style={{ borderBottom: "1px solid #c6c6c6" }}
        >
          <div className="jt-col-50"><div className="rule-text">{entry.entityName}</div></div>
          <div className="jt-col-50">
            <div className="rule-include-text">Eligible upto {entry.capValue}</div>
          </div>
        </div>
        );
      });
    }
    return capped;
  }

  //<!--------------------------------- Method for open the CAMPAIN or UINODE on view click event ---------------------------------!>
  const openCampaign = () => {
    sendClickEvent("VIEW_PRODUCTS_PAGE");
    let type = target.displayData.nextPageType;
    let param = target.displayData.params;
    let externalUrl = "http://jumbotail.com/";

    if (type === "PRODUCTLIST") {
      externalUrl += "product-list?" + param;
      window.Android.openExternalUrl(externalUrl);
    } else if (type === "BROWSE") {
      if (param === "node=UINODE-11386154388") {
        window.Android.goHome();
      } else {
        externalUrl += "browse?" + param;
        window.Android.openExternalUrl(externalUrl);
      }
    }
  }

  const showType = () => {
    let showType;
    let currentTime = new Date().getTime();
    if (target) {
      let schemeEndDate = target.schemeMemberTargetBO.targetSchemeBO.endTime;
      if (schemeEndDate < currentTime) {
        showType = "PASTDETAIL";
      } else {
        showType = "RUNNINGDETAIL";
      }
    }
    return showType;
  }

  let alterResponseWithJumboCoinsToJumboCash = (resText) => {
    let wordArray = resText.split(" ");
    let modifiedText = "";
    for (var i = 0; i < wordArray.length; i++) {
      if (wordArray[i] === "Jumbocoin" || wordArray[i] === "Jumbocoins") {
        wordArray[i] = "Jumbocash";
      }
    }
    return wordArray.join(" ").trim();
  }
  //<!--------------------------------- Main Render Method ---------------------------------!>

  return (
    <div>
      {target ? (
        <div>
          <div className="details-outer">
            <div className="scheme-name centralize">
              {target.displayData.title}
            </div>
            <div className="scheme-description centralize">
              {/*{target.displayData.description}CODE:J24DEMO*/}
              {alterResponseWithJumboCoinsToJumboCash(target.displayData.description)}
            </div>
            <div className="scheme-validity centralize">
              {target.displayData.subtitle}
            </div>
            <div style={{ display: "flow-root", marginTop: "10px" }}>
              <TargetBar schemeMemberTarget={target.schemeMemberTargetBO} page={showType()}
              />
            </div>
          </div>
          <div className="target-details">
            <div className="details-reward">
              {jcEarnedMessage ?
                (<div>{jcEarnedMessage}</div>)
                : (<div>{getReward(target.schemeMemberTargetBO)}</div>)
              }
            </div>
            {target.schemeMemberTargetBO.currentValue ? (
              <NavLink to={{ pathname: "/transaction-history", state: { transactions: transactions }, search: "?schemeTargetId=" + target.schemeMemberTargetBO.schemeMemberTargetId }} style={{ textDecoration: "none" }}>
                <div className="trns-history row" onClick={() => sendClickEvent("VIEW_TXN_HISTORY")}>
                  <div className="jt-col-85">Transaction History</div>
                  <div className="jt-col-15" style={{ color: "#ec6318" }}> View <img src={orangeArrow} alt="arrow" height="16px" align="right" /></div>
                </div>
              </NavLink>
            ) : null}

            <div className="scheme-rules">
              <div className="scheme-rule-label">Scheme rules</div>
              <div className="scheme-rule-tag">
                Following company/brand/category/products are eligible
              </div>
              <div style={{ border: "1px solid #c6c6c6", borderBottom: "none" }}>
                {getInclusion()}
                {getCapped()}
              </div>
              {getExclusion() ? (
                <div>
                  <div className="scheme-rule-tag">Except</div>
                  <div
                    style={{
                      border: "1px solid #c6c6c6",
                      borderBottom: "none",
                    }}
                  >
                    {getExclusion()}
                  </div>
                </div>
              ) : null}
              {
                // <Dialog fullScreen open={dialogeOpen} onClose={handleDialogClose} TransitionComponent={Transition}>
                //  <ProductDialog title={productsTitle} products={productsToShow} closeDialog={handleDialogClose}/>
                // </Dialog>
              }
              <Dialog
                open={dialogeOpen}
                TransitionComponent={Transition}
                maxWidth="xs"
                classes={{ paperWidthXs: "MuiDialog-paper" }}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <ProductDialog
                  title={productsTitle}
                  products={productsToShow}
                  backColor={backColor}
                  closeDialog={handleDialogClose}
                />
              </Dialog>
            </div>

            {target.displayData.nextPageType &&
              target.displayData.nextPageType !== "NONE" ? (
              <div className="top-scheme-item row">
                <div
                  className="jt-col-75 title"
                  onClick={showIncludedJpins}
                >
                  Top items for this scheme
                </div>
                <div className="jt-col-25">
                  <Button
                    letiant="contained"
                    size="medium"
                    className="view-top-btn pull-right"
                    onClick={openCampaign.bind(this)}
                  >
                    View{" "}
                    <img
                      src={whiteArrow}
                      alt="arrow"
                      align="right"
                      style={{ height: "12px", marginLeft: "10px" }}
                    />
                  </Button>
                </div>
              </div>
            ) : null}
            <div style={{ height: "20px" }}></div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

