import React, { useState, useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import CoreCard from './../card/CoreCard.js';
import {AppContext} from './../../../App.js';
import leftDip from './../../../assets/left-dip.svg';
import rightDip from './../../../assets/right-dip.svg';

export default function CarouselWithOutIndicator({val}){
	const [index, setIndex] = useState(0);
	const [swiper, setSwiper] = useState(null);
	const context = useContext(AppContext);
	const {childNodes} = val;
	const {showWebView} = context

	let slidesPerView = showWebView ? 1 : 1.2;

	const onLeftClick = () => {
		if(index > 0) {
			swiper.slideTo(index-1);
			setIndex(index-1);
		}
	}

	const onRightClick = () => {
		if(index < childNodes.length - 1) {
			swiper.slideTo(index+1);
			setIndex(index+1);
		}
	}

	return (<div className="group cwoi">
		<div className="row" style={{position: 'relative'}}>
			{(showWebView)?
			<div className="left-bx" onClick={onLeftClick}>
				<div className={index == 0 ? "side-button  non-usable" : "side-button"}><img src={leftDip}/></div>
			</div>:null
			}
			<div className="col-12">
				<div className="cwoi-children">
					<Swiper effect="fade" 
					slidesPerView={slidesPerView} 
					spaceBetween={20} 
					onSwiper={(swiper) => setSwiper(swiper)}>
  						{childNodes.map((val, i) => { 
  							return <SwiperSlide key={i}><CoreCard val = {val} /></SwiperSlide>
  						})}
					</Swiper>	
				</div>
			</div>
			{(showWebView)?
			<div className="right-bx" onClick={onRightClick}>
				<div className={index == (childNodes.length - 1) ? "side-button  non-usable" : "side-button"}><img src={rightDip}/></div>
			</div>:null
			}
		</div>
	</div>);
}
