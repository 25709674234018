import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from './../../App.js';
import { RandomUUIDGenrator } from './../../utilities/Utils.js';
import InputFloating from './../common/InputFloating.js';
import serverApiHandler from './../apiHandler/serverApiHandler.js';
import { publishEvent } from './../../utilities/ManageEvents.js';
import './style.css';

const validateNumber = (phoneNumber) => {
    if (
        phoneNumber &&
        phoneNumber.length === 10 &&
        !phoneNumber.startsWith('0')
    ) {
        return true;
    }
    return false;
};

export default function Login({ location }) {
    const [numberScreen, setNumberScreen] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otpId, setOtpId] = useState(null);
    const [otp1, setOtp1] = useState('');
    const [otp2, setOtp2] = useState('');
    const [otp3, setOtp3] = useState('');
    const [otp4, setOtp4] = useState('');
    const [incorrectOtp, setIncorrectOtp] = useState(false);

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);

    const context = useContext(AppContext);
    const user = context.user.get;
    const history = useHistory();
    const from = location.state && location.state.from;

    useEffect(() => {
        document.title = 'Login | Signup';
        document.head.innerHTML += `
        <meta name='description' content='Log in to access special offers and benefits as a J24 Greencard member.'/>`;

        context.header.set({ type: 'PAGE_NAME', text: 'Login / SignUp' });
        publishEvent('ON_LOAD_LOGIN_SCREEN');
    }, []);

    useEffect(() => {
        if (!user) return;
        handleLoginRedirect();
    }, [user]);

    const generateOtp = () => {
        publishEvent('ON_SUBMIT_PHONE_NUMBER', { phNo: phoneNumber });
        if (validateNumber(phoneNumber)) {
            let req = {
                requestID: RandomUUIDGenrator(),
                phoneNumber: phoneNumber,
            };
            let startTime = new Date().getTime();
            serverApiHandler
                .generateOtp(req)
                .then((response) => {
                    let ttms = new Date().getTime() - startTime;
                    publishEvent('ON_GENERATED_OTP', null, ttms);
                    setOtpId(response.otpID);
                    setNumberScreen(false);
                    publishEvent('ON_LOAD_OTP_SCREEN');
                    ref1.current.focus();
                })
                .catch((error) => {})
                .finally((resp) => {});
        } else {
            publishEvent('ON_INVALID_PHONE_NUMBER', { phNo: phoneNumber });
        }
    };

    const handleLoginRedirect = () => {
        history.goBack();
    };

    const handleLoginResponse = (resp) => {
        localStorage.setItem('user', JSON.stringify(resp));
        let cart = JSON.parse(localStorage.getItem('cart'));
        if (cart) {
            cart.cartDetails.customerID = resp.customerId;
            localStorage.setItem('cart', JSON.stringify(cart));
        }
        context.user.set(resp);
    };

    const login = () => {
        let otp = otp1 + otp2 + otp3 + otp4;
        publishEvent('ON_SUBMIT_MANUAL_OTP');
        if (otp.length === 4) {
            let req = {
                phoneNumber: phoneNumber,
                otp: otp,
                otpID: otpId,
            };
            console.log('req to backend for OTP verify is ', req);
            let startTime = new Date().getTime();
            serverApiHandler
                .validateOtp(req)
                .then((response) => {
                    let ttms = new Date().getTime() - startTime;
                    publishEvent('ON_SUCCESS_OTP_VERIFICATION', null, ttms);
                    handleLoginResponse(response);
                    console.log('Response after validation is ', response);
                })
                .catch((error) => {
                    let ttms = new Date().getTime() - startTime;
                    setIncorrectOtp(true);
                    publishEvent('ON_FAILURE_OTP_VERIFICATION', null, ttms);
                })
                .finally((resp) => {});
        }
    };

    const resendOtp = () => {
        let req = {
            otpID: otpId,
            phoneNumber: phoneNumber,
            requestID: RandomUUIDGenrator(),
        };
        let startTime = new Date().getTime();
        serverApiHandler
            .resendOtp(req)
            .then((response) => {
                let ttms = new Date().getTime() - startTime;
                publishEvent('ON_RESEND_OTP', null, ttms);
            })
            .catch((error) => {
                let ttms = new Date().getTime() - startTime;
                publishEvent('ON_FAILURE_RESEND_OTP', null, ttms);
            })
            .finally((resp) => {});
    };

    const numberUpdated = (e) => {
        setPhoneNumber(e.target.value);
    };

    const focusPhoneNumber = (e) => {
        publishEvent('ON_FOCUS_PHONE_NUMBER');
    };

    const getPhoneNumberRendering = () => {
        return (
            <div className='login-form'>
                <InputFloating
                    type='number'
                    label='Enter Phone Number'
                    onChange={numberUpdated}
                    value={phoneNumber}
                    onFocus={focusPhoneNumber}
                />
                <div className='top30'>
                    <button
                        onClick={generateOtp}
                        name='generateOtp'
                        className='btn-login'
                    >
                        GET OTP
                    </button>
                </div>
                {from === 'cartpage' && (
                    <div className='top30'>
                        <a href='/address' className='guest-checkout'>
                            Checkout as guest
                        </a>
                    </div>
                )}
            </div>
        );
    };

    const getVal = (itm) => {
        if (!itm) return itm;
        let k = itm.split('');
        return k[k.length - 1];
    };

    const otpUpdate = (e) => {
        setIncorrectOtp(false);
        switch (e.target.name) {
            case 'otp1':
                setOtp1(getVal(e.target.value));
                if (e.target.value) ref2.current.focus();
                break;
            case 'otp2':
                setOtp2(getVal(e.target.value));
                if (e.target.value) ref3.current.focus();
                break;
            case 'otp3':
                setOtp3(getVal(e.target.value));
                if (e.target.value) ref4.current.focus();
                break;
            case 'otp4':
                setOtp4(getVal(e.target.value));
                if (e.target.value) ref4.current.blur();
                break;
            default:
                break;
        }
    };

    const getOtpRendering = () => {
        return (
            <div style={{ textAlign: 'center' }}>
                <div className='otp-lbl'>Enter OTP</div>
                <div className='top-twenty'>
                    <input
                        type={'number'}
                        className={
                            incorrectOtp ? 'otp-box errorText' : 'otp-box'
                        }
                        name={'otp1'}
                        min='0'
                        max='9'
                        value={otp1}
                        onChange={otpUpdate}
                        ref={ref1}
                    />
                    <input
                        type={'number'}
                        className={
                            incorrectOtp ? 'otp-box errorText' : 'otp-box'
                        }
                        name={'otp2'}
                        min='0'
                        max='9'
                        value={otp2}
                        onChange={otpUpdate}
                        ref={ref2}
                    />
                    <input
                        type={'number'}
                        className={
                            incorrectOtp ? 'otp-box errorText' : 'otp-box'
                        }
                        name={'otp3'}
                        min='0'
                        max='9'
                        value={otp3}
                        onChange={otpUpdate}
                        ref={ref3}
                    />
                    <input
                        type={'number'}
                        className={
                            incorrectOtp ? 'otp-box errorText' : 'otp-box'
                        }
                        name={'otp4'}
                        min='0'
                        max='9'
                        value={otp4}
                        onChange={otpUpdate}
                        ref={ref4}
                    />
                </div>
                {incorrectOtp ? (
                    <div className='top30'>
                        <div className='errorText'>
                            Entered OTP is incorrect
                        </div>
                        <div className='top-twenty'>
                            <span
                                style={{
                                    color: '#027DD7',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                }}
                                onClick={resendOtp}
                            >
                                Resend OTP
                            </span>
                        </div>
                    </div>
                ) : (
                    <div className='top30'>
                        <div>
                            Did not receive?{' '}
                            <span
                                style={{
                                    color: '#027DD7',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                }}
                                onClick={resendOtp}
                            >
                                Resend OTP
                            </span>
                        </div>
                    </div>
                )}

                <div className='top30'>
                    <button
                        onClick={login}
                        name='generateOtp'
                        className='btn-login'
                    >
                        Login
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div>
            <div style={{ position: 'relative', height: '90vh' }}>
                <div className='vertical-center' style={{ width: '100%' }}>
                    {numberScreen
                        ? getPhoneNumberRendering()
                        : getOtpRendering()}
                </div>
            </div>
        </div>
    );
}
