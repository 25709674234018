import React from 'react';
import './Style.css';

export default function Loader() {
	return(<div>
			<div className="loader-wrapper">
				<div className="loader-box vertical-center">
					<div className="loader"/>
				</div>
			</div>
		</div>)
}