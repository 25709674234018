import {RandomUUIDGenrator} from './Utils.js';

export function makeSyncCartRequest(existingCart) {
	let cart = JSON.parse(JSON.stringify(existingCart));
	cart.requestID = RandomUUIDGenrator();
	cart.cartDetails.totalPayableAmount = cart.cartDetails.cartAmount;
	cart.cartDetails.originalPayableAmount = cart.cartDetails.cartAmount;
	for(let i in cart.cartDetails.activeCartItems) {
		cart.cartDetails.activeCartItems[i].title = null;
		cart.cartDetails.activeCartItems[i].productDetails.title = null;
		cart.cartDetails.activeCartItems[i].productDetails.brand = null;
		cart.cartDetails.activeCartItems[i].productDetails.description = null;
		cart.cartDetails.activeCartItems[i].productDetails.listingDetailsMap = null;
		for(let j in cart.cartDetails.activeCartItems[i].listingDetails.qtyPromiseDetailsMap) {
			let promiseTime = cart.cartDetails.activeCartItems[i].listingDetails.qtyPromiseDetailsMap[j].promiseTime;
			cart.cartDetails.activeCartItems[i].listingDetails.qtyPromiseDetailsMap[j] = {promiseTime: promiseTime}
		}
		for(let j in cart.cartDetails.activeCartItems[i].listingDetails.jtOfferBOList) {
			cart.cartDetails.activeCartItems[i].listingDetails.jtOfferBOList[j].displayData.displayTitle = null;
			cart.cartDetails.activeCartItems[i].listingDetails.jtOfferBOList[j].displayData.label = null;
			cart.cartDetails.activeCartItems[i].listingDetails.jtOfferBOList[j].displayData.displaySubtext = null;
			cart.cartDetails.activeCartItems[i].listingDetails.jtOfferBOList[j].displayData.description = null;
		}
		for(let j in cart.cartDetails.activeCartItems[i].offers) {
			cart.cartDetails.activeCartItems[i].offers[j].displayData.displayTitle = null;
			cart.cartDetails.activeCartItems[i].offers[j].displayData.label = null;
			cart.cartDetails.activeCartItems[i].offers[j].displayData.displaySubtext = null;
			cart.cartDetails.activeCartItems[i].offers[j].displayData.description = null;
		}
	}
	return cart;
}

export function makeCartFromSyncResponse(response) {
	let cart = JSON.parse(JSON.stringify(response));

	for(let i in cart.cartDetails.activeCartItems) {
		let listingId = cart.cartDetails.activeCartItems[i].listingDetails.listingId;
		cart.cartDetails.activeCartItems[i].productDetails.listingDetailsMap = {};
		cart.cartDetails.activeCartItems[i].productDetails.listingDetailsMap[listingId] = cart.cartDetails.activeCartItems[i].listingDetails;
		cart.cartDetails.activeCartItems[i].productDetails.winningListingId = listingId;
		cart.cartDetails.activeCartItems[i].listingDetails.jtOfferBOList = cart.cartDetails.activeCartItems[i].offers;
	}
	return cart;
}

export function shouldShowItems(cart) {
	return (cart && cart.cartDetails && cart.cartDetails.activeCartItems.length > 0);
}

export const createOrderRequest = (cart, address) => {
	let reqCart = makeSyncCartRequest(cart);
	let cartRequest = {
		requestID : RandomUUIDGenrator(),
    	orderPlacedTimestamp : new Date().getTime(),
    	boltOrderType : 'J24',
   		cartDetails : reqCart.cartDetails
	};

	return {
		phoneNumber : address.phoneNumber,
    	addressDetails : address,
    	addressId : address.addressID,
    	placeOrderRequestCart : cartRequest
	};
}