import React from 'react'
import arrow from "../../../assets/right-arrow.svg"
import done from "../../../assets/Done.svg";
import cleared from "../../../assets/Cleared.svg";
import bounced from "../../../assets/Bounced.svg";
import notCleared from "../../../assets/Not-cleared.svg";
import partial from "../../../assets/Partial-payment.svg";
import { sendEventToAlooma } from "../../../utilities/ManageEvents";
import { NavLink } from "react-router-dom";
function TxnState(props) {
  let status = {
    "Cheque Collected": done,
    "Submitted to bank": done,
    "Altered Cheque": done,
    "Cheque Can be Represented By Bank": done,
    "Nach Initiated": done,
    Repaid: cleared,
    Bounced: bounced,
    "Partially Repaid": partial,
    "Partially Paid": partial,
    "Completely repaid": cleared,
    Cleared: cleared,
    "Dpn Collected": done,
    "Repayment delayed": bounced,
    CHEQUE: "Cheque No.",
    DPN: "DPN id : ",
    setUpAutoPayWebURL: null,
  };
  const clickPenalty = () => {
    var eventName = "PENALTY_DETAILS";
    var eventData = {
      business_id: window.bzid,
      pi_type: this.props.pi.paymentInstrumentType,
      pi_status: this.props.pi.paymentInstrumentStatus,
      position: "TXN_LIFECYCLE",
    };
    sendEventToAlooma(eventName, eventData);
  }
  const datefor = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
  const numfor = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });
  console.log(props.state.faded)
  const initialDuDate = props.initialDuDate;
  return (
    <div className="txn-cycle-phase-outer">
      <div className="txn-cycle-phase">
        {!props.state.faded ? (
          <div className="row">
            <div className="slider-line"></div>
            <div className="jt-col-15">
              <img
                src={status[props.state.statusName]}
                width="24px"
                height="24px"
                alt="img"
              />
              {props.showSideLine ? (
                <div className="connector-inside"></div>
              ) : null}
              {props.showFadedLine ? (
                <div className="connector-inside-faded"></div>
              ) : null}
            </div>
            <div className="jt-col-85">
              <div className="flow">
                <div className="pull-left">
                  <span className="txn-state-name">
                    {props.state.statusName}
                  </span>
                </div>
                <div className="pull-right">
                  <div className="txn-state-date">
                    {datefor.format(props.state.statusTransitionDate)}
                  </div>
                </div>
              </div>
              {props.state.showPiDetails ? (
                <React.Fragment>
                  <div className="cheque-details ">
                    <div className="flow">
                      <div className="pull-left">
                        <div className="cheque-no">
                          {status[props.pi.paymentInstrumentType]}{" "}
                          {props.pi.piRefId}{" "}
                          <span className="bank-name"></span>
                        </div>
                        <div className="state-due-date">
                          <span>Due Date : </span>
                          <span className="DueDate" >
                            {datefor.format(initialDuDate)}
                            {/* {requestExtension.length !== 0 ? (<del>{datefor.format(initialDuDate)}</del>) : <React.Fragment>{datefor.format(initialDuDate)}</React.Fragment>} */}
                          </span>
                        </div>
                      </div>
                      <div className="pull-right">
                        <div className="cheque-amount">
                          {numfor.format(props.pi.chequeAmount)}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* {requestExtension.length !== 0 ? (
                    <div className="row" style={{ marginTop: '10px' }}>
                      <div className="js-col-reqext">
                        {requestExtension.length !== 0 ? (<img
                          src={this.status[this.props.state.statusName]}
                          width="24px"
                          height="24px"
                          alt="img"
                        />) : ''}

                      </div>
                      <div className="jt-col-100 margin10">
                        <div className="flow">
                          <div className="pull-left">
                            <span className="txn-state-name">{requestExtension.length !== 0 ? 'Extension Request' : ''}</span>
                          </div>
                          <div className="pull-right">
                            <div className="txn-state-date"></div>
                          </div>
                        </div>
                        {requestExtension.map((item) => {
                          return (
                            <React.Fragment>
                              <div className="cheque-details ">
                                <div className="flow">
                                  <div className="pull-left">
                                    <div className="cheque-no">
                                      Request ID -{item.holdReferenceId}{" "}
                                      <span className="bank-name"></span>
                                    </div>
                                    <div className="state-due-date">
                                      <span>Due Date : </span>
                                      <span style={{ fontWeight: "500" }}>
                                        {item.status==='ACTIVE'?(<span>{datefor.format(item.dueDate)}</span>):(<del>{datefor.format(item.dueDate)}</del>)}
                                       </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </div>
                  ) : null} */}

                  {/* {checkHoldAndCancell} */}
                </React.Fragment>
              ) : null}

              {props.state.statusName === "Bounced" ? (
                <div>
                  {this.props.pi.bounceReason ? (
                    <div className="top-ten">
                      <div className="bounced-reason-box-state">
                        <span style={{ color: "#646464" }}>Reason : </span>
                        <span style={{ fontWeight: "500" }}>
                          {props.pi.bounceReason}
                        </span>
                      </div>
                    </div>
                  ) : null}
                  {props.pi.penalty &&
                    props.pi.penalty > 0 &&
                    (props.pi.repaidPenalty == null ||
                      props.pi.txnStatus === "PARTIALLY_REPAID") ? (
                    <div
                      className="flow top-ten"
                      onClick={props.onnShowPenalty}
                    >
                      <div
                        className="state-penalty pull-left"
                        onClick={this.clickPenalty.bind(this)}
                      >
                        <span className="penalty-applicable">
                          Penalty applicable{" "}
                        </span>
                        <span className="cheque-amount">
                          {" "}
                          {numfor.format(props.pi.penalty)}
                        </span>
                        <span className="outer-slider">
                          <span className="sider-new" style={{ top: "12px" }}>
                            <img src={arrow} width="24px" alt=">" />
                          </span>
                        </span>
                      </div>
                    </div>
                  ) : null}
                  {props.pi.repaidPenalty !== null &&
                    props.pi.txnStatus !== "PARTIALLY_REPAID" ? (
                    <div className="flow top-ten">
                      <div
                        className="state-penalty-paid pull-left"
                        onClick={clickPenalty()}
                      >
                        <span className="penalty-applicable">
                          Penalty Paid{" "}
                        </span>
                        <span className="cheque-amount">
                          {" "}
                          {numfor.format(props.pi.repaidPenalty)}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}

              {props.state.repaymentInstrumentName &&
                props.state.repaymentAmount ? (
                <div className="paid">
                  <div className="flow">
                    <div className="pull-left">
                      <div className="cheque-no">
                        {props.state.repaymentInstrumentName}
                      </div>
                    </div>
                    <div className="pull-right">
                      <div className="cheque-amount">
                        {numfor.format(props.state.repaymentAmount)}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div>
            <div className="row">
              <div className="jt-col-15">
                <img src={notCleared} width="24px" height="24px" alt="img" />
                {props.showFadedLine ? (
                  <div className="connector-inside-faded"></div>
                ) : null}
              </div>
              <div className="jt-col-85">
                <div className="flow">
                  <div className="pull-left">
                    <span className="txn-state-name-faded">
                      {props.state.statusName}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}


        {/* <div className="row">
          <div className="jt-col-15"></div>
          <div className="jt-col-85">
            <div className="checkDetails-btn">
              <NavLink
                to={{
                  pathname: "/RequestExtension",
                  state: {
                    noOdDays: daysVsHoldFeeMap,
                    paymentId: Payment_id,
                    paymentType: payment_type,
                    bzid: this.props.bzid,
                    RepaymentDate: RepaymentDate,
                  },
                }}
                className="pull-right checkDetails-button" style={
                  // paymentInstrumentEligibleForHold
                  true
                    ? // true
                      null
                    : { pointerEvents: "none" }
                }
              >
                Request Extension
              </NavLink>
              <NavLink
                to={{
                  pathname: "/ClearPayment",
                  state: {
                    total_Amount: total_Amount,
                    Cheque_img: cheque_img,
                    base_Amount: base_Amount,
                    interest_Amount: interest_Amount,
                    paymentId: Payment_id,
                    paymentType: payment_type,
                    bzid: this.props.bzid,
                  },
                }}
                className="pull-left checkDetails-button"
                style={canBePaidOnline ? null : { pointerEvents: "none" }}
              >
                Clear Payment Now
              </NavLink>
            </div>
          </div>
        </div> */}

      </div>
    </div>
  );
}

export default React.memo(TxnState)


