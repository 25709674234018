import React, { useEffect } from 'react';
import {publishEvent} from './../../utilities/ManageEvents.js';
import "./Style.css";

// const handleScroll = (event) => {
//     let scrollTop = document.documentElement.scrollTop;
//     if(scrollTop < 90) {
//         document.getElementById('ser-b-d').style.backgroundColor = 'rgba(98, 176, 77, '+((scrollTop)/50)+')';
//     } else {
//        	document.getElementById('ser-b-d').style.backgroundColor = 'rgba(98, 176, 77, 1)';
//     }	
// }

export default function SearchDisplay() {

	// useEffect(() => {
	// 	window.addEventListener('scroll', handleScroll);
	// 	return() => {
	// 		window.removeEventListener('scroll', handleScroll);
	// 	}
	// }, [])

	return (<a href="/search" onClick={() => {publishEvent('ON_SUBMIT_SEARCH')}}>
			<div id="ser-b-d" className="search-display-wrapper">
				<div className="search-display-box">Search groceries, essentials...</div>
			</div>
		</a>)
}
