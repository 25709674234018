import React, { Component } from "react";

export default class Progress extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (this.props.width < 100) {
      this.state.width = this.props.width + "%";
    } else {
      this.state.width = "100%";
    }
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <div className="back-bar"></div>
        <div
          className="inner-bar"
          id="innerBar"
          style={{ width: this.state.width }}
        ></div>
      </div>
    );
  }
}
