import apiHandler from '../../utilities/apiHandler.js';

const SearchApiHandler = {

    getSuggestions: function (keyWord, abortSignal) {
        const URL = "product-details/v3/list/search?text=" + keyWord + "&language=en"
        return apiHandler.get(URL, abortSignal);
    }
}

export default SearchApiHandler;