import React from "react";
import Product from "./Product";
import "./style.css";

// todo emptyMesage should accept a render function
export default function ProductList({ products, emptyMessage }) {
  return (
    <div className={"product-list"}>
      {products ? (
        products.map((product, i) => <Product key={i} product={product} />)
      ) : (
        <div>{emptyMessage}</div>
      )}
    </div>
  );
}
