import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../App';
import Loader from '../common/Loader';
import MoneySaved from './MoneySaved';
import ErrorScreen from '../common/ErrorScreen';
import serverApiHandler from './../apiHandler/serverApiHandler.js';
import { formatToIndianCurrencyRounded } from './../../utilities/Utils.js';
import giftbox from './../../assets/giftbox.svg';
import coins from './../../assets/coins.png';
import charity from './../../assets/charity.png';
import discount from './../../assets/discount.png';
import jumbocoin from './../../assets/jumbocoin.png';
import './Style.css';
import { Helmet } from 'react-helmet';

const gcSteps = [
    // { img: coins, alt: "coins", text: "Earn Jumbocoins on every purchase" },
    //  CODE:J24DEMO
    { img: coins, alt: 'coins', text: 'Earn Jumbocash on every purchase' },
    { img: charity, alt: 'charity', text: 'Use them to pay at any J24 store' },
    {
        img: discount,
        alt: 'discount',
        text: 'Enjoy special discounts & offers',
    },
];

export default function Greencard() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [jcInfo, setJCInfo] = useState({
        balance: null,
        pointToRupee: null,
    });
    const context = useContext(AppContext);
    const { header } = context;
    const user = context.user.get;

    useEffect(() => {
        header.set({ type: 'MENU', text: null });
        if (!user) return;
        setLoading(true);
        serverApiHandler
            .getLoyaltyBalance()
            .then((resp) => {
                const { balance, pointToRupee } = resp;
                setJCInfo({ balance, pointToRupee });
            })
            .catch((error) => {
                setError(true);
            })
            .finally((resp) => {
                setLoading(false);
            });
    }, [user]);

    const getGCMain = () => {
        // guest user
        if (!user)
            return (
                <div className='col center gc-pitch'>
                    <div className='gc-txt-md gc-pitch-title'>
                        Get exclusive offers and discounts!
                    </div>
                    <img src={giftbox} alt='giftbox' className='giftbox' />
                    <div className='gc-txt-sm px-10 fw-500'>
                        Signup for J24 greencard membership to avail discounts
                        on purchase
                    </div>
                </div>
            );

        // logged in
        return (
            <>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <div className='col center gc-pitch'>
                            <div className='gc-txt-lg'>
                                <div>Hello there,</div>
                                {/*<div>Your Jumbocoins balance is</div>CODE:J24DEMO*/}
                                <div>Your Jumbocash balance is</div>
                            </div>
                            <div className='gc-txt-xl row center fw-700 py-10'>
                                <span>{jcInfo.balance}</span>
                                <img
                                    src={jumbocoin}
                                    alt='jumbocoin'
                                    width='40px'
                                />
                            </div>
                            <div className='gc-txt-lg row center'>
                                <span className='fw-700'>1</span>
                                <img
                                    src={jumbocoin}
                                    alt='jumbocoin'
                                    width='20px'
                                />
                                <span style={{ padding: '0 5px 0 2px' }}>
                                    equals
                                </span>
                                <span className='fw-700'>
                                    {formatToIndianCurrencyRounded(
                                        jcInfo.pointToRupee || 1
                                    )}
                                </span>
                            </div>
                        </div>
                    </>
                )}
                <MoneySaved />
            </>
        );
    };

    if (error) return <ErrorScreen />;
    return (
        <React.Fragment>
            <Helmet>
                <meta charSet='utf-8' />
                <title>Greencard</title>
                <meta
                    name='description'
                    content='As a member of Greencard (J24 Loyalty Programme), you will get access to special offers and rewards for regularly shopping with us.'
                />
            </Helmet>

            <div className='gc-wrapper'>
                <div
                    className='lg-bg relative container'
                    style={{ paddingBottom: user ? '2em' : '1.5em' }}
                >
                    {getGCMain()}
                </div>
                <div
                    className='container'
                    style={{ paddingTop: user ? '1.75em' : '0' }}
                >
                    <div className='gc-txt-md centered'>
                        How to get greencard discounts?
                    </div>
                    <div className='gc-steps'>
                        {gcSteps.map((step, ind) => {
                            return (
                                <div className='gc-step' key={ind}>
                                    <img
                                        className='gc-step-img'
                                        src={step.img}
                                        alt={step.alt}
                                    />
                                    <div className='gc-step-txt gc-txt-sm'>
                                        {step.text}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                {!user && (
                    <Link
                        className='btn gc-btn'
                        to={{
                            pathname: '/login',
                            state: { from: 'greencard' },
                        }}
                    >
                        Sign up for Greencard
                    </Link>
                )}
            </div>
        </React.Fragment>
    );
}
