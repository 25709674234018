import React from 'react';
import { isStoreOpen } from "../../utilities/Utils.js"
import './store.css';


export default function StoreOpeningStatusIndicator({openTime, closingTime}) {
	return (
		<>
			{(isStoreOpen(openTime, closingTime)) ?
				<div className="store-open">Open</div>
				:
				<div className="store-closed">Closed</div>
			}
		</>)
}