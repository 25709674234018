import React, { useEffect, useRef } from 'react';

export default function InputFloating(props) {
	const inp = useRef(null);
	const lbl = useRef(null);

	useEffect(() => {
		updateClasses();
	}, [])

	useEffect(() => {
		if(props.value) {
			onfocused()
		}
	}, [props])

	const updateClasses = () => {
		if(props.type === 'date') {
			lbl.current.setAttribute("class", "fl-lbl-up lll");
		}

		if(props.value) {
			lbl.current.setAttribute("class", "fl-lbl-up lll");
		}
	}

	const onfocused = (e) => {
		lbl.current.setAttribute("class", "fl-lbl-up entered lll");
		inp.current.setAttribute("class", "in-mob-box entered-box");
		if(props.onFocus) {
			props.onFocus(e);
		}
	}

	const clicked = (e) => {
		inp.current.focus(e);
		onfocused();
	}

	

	const blured = (e) => {
		if(!e.target.value && props.type !== 'date') {
			lbl.current.setAttribute("class", "fl-lbl lll");
		} else {
			lbl.current.setAttribute("class", "fl-lbl-up lll");
		}
		inp.current.setAttribute("class", "in-mob-box");
		if(props.onBlur) {
			props.onBlur(e);
		}
	}

	const onClick = (e) => {
		if(props.onClick) {
			props.onClick(e);
		}
	}

	const onChange = (e) => {
		if(props.onChange) {
			props.onChange(e);
		}
	}

	const onKeyUp = (e) => {
		if(props.onKeyUp) {
			props.onKeyUp(e);
		}
	}

	const {type, value, label, name, id} = props;
	return(
		<div>
			<div style={{position: 'relative'}}>
				<input type={type} className="in-mob-box" id={id} name={name} onFocus={onfocused} onBlur={blured} onClick={onClick} onChange={onChange} onKeyUp={onKeyUp} value={value} ref={inp}/>
				<label className="fl-lbl lll" onClick={clicked} ref={lbl}>{label}</label>
			</div>
		</div>
	)
}