import React, { useState, useEffect, useContext } from "react";
import Button from "@material-ui/core/Button";
import { NavLink, Redirect } from "react-router-dom";
import serverApiHandler from "../../apiHandler/serverApiHandler";
import phoneWithTickMark from "../../../assets/phoneWithTickMark.png";
import dangerYellow from "../../../assets/danger-yellow.svg";
import { AppContext } from "../../../App";
//import ErrorScreen from "../../error/error.js";

function AutoDebitDetailsCard(props) {
  const context = useContext(AppContext);
  const [autoDebit, setautoDebit] = useState({
    cardView: "question",
    nachAccountId: null,
    nachAccountStatus: false,
    nachUser: false,
    setUpAutoPayWebURL: null,
    cdNum: "08045654565",
  });
  useEffect(() => {
    let { nachAccountId, nachAccountStatus, nachUser } =
      props.attributes.creditLineDetails;
    setautoDebit({
      nachAccountId: nachAccountId,
      nachAccountStatus: nachAccountStatus,
      nachUser: nachUser,
    });
    if (nachAccountId && nachAccountStatus === "ACTIVE") {
      setautoDebit({
        cardView: "completed",
      });
    } else if (nachUser) {
      setautoDebit({
        cardView: "nonPdc",
      });
    } else if (!nachUser) {
      setautoDebit({
        cardView: "question",
      });
    } else {
      setautoDebit({
        cardView: "showNothing",
      });
    }
  }, []);
  useEffect(() => {}, []);
  const handleSetUpAutoPay = (e) => {
    console.log("Clicked Set up Auto Pay Btn");
    serverApiHandler
      .setUpAutoPay({
        bzid: context.user.get.bzId,
        //"bzid": 'BZID-1304438756'
      })
      .then((res) => {
        console.log("Response for E-mandate is ", res);
        let setUpAutoPayWebURL = res.registrationUrl;
        setautoDebit({
          setUpAutoPayWebURL: res.registrationUrl,
        });
        // this.setState({
        //     setUpAutoPayWebURL : res.registrationUrl
        // });
        if (
          setUpAutoPayWebURL &&
          window.Android &&
          window.Android.startPayment
        ) {
          window.Android.openExternalUrl(setUpAutoPayWebURL);
        }
      });
  };
  let { cardView } = autoDebit;
  let { nachAccountId, nachAccountStatus, nachUser } = autoDebit;
  console.log(cardView);
  console.log(props);
  if (cardView === "showNothing") {
    return null;
  } else {
    return cardView === "question" ? (
      // !nachUser && nachAccountId == null && !nachAccountStatus ?
      <div className={"autoDebitViewCardContainer"}>
        <React.Fragment>
          <div className={"autoDebitQuestionCardInnerContainer"}>
            <div className={"autoDebitQuestionCardView"}>
              <div className={"questionCardTop"}>
                <div className={"autoDebitViewCardHalf"}>
                  <h4>Tired of using cheques?</h4>
                  <div className={"txn-label"}>
                    Activate auto-pay feature to clear all
                  </div>
                  <div className={"txn-label"}>your dues automatically</div>
                  <NavLink
                    className={"autoDebitLinkText"}
                    style={{ marginTop: "4%" }}
                    to={{
                      pathname: "/setup-auto-pay",
                      state: {
                        transaction: props.txn,
                        provider: props.provider,
                        type: props.type,
                        bzid: props.bzid,
                      },
                    }}
                  >
                    Know more
                  </NavLink>
                </div>
                <div className={"autoDebitImageHalf"}>
                  <img src={phoneWithTickMark}></img>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "5%" }}>
            {/*<Button variant="contained" className={"autoDebitBtn"} onClick={this.handleSetUpAutoPay}>Set up auto-pay</Button>*/}
            <Button
              variant="contained"
              className={"autoDebitBtn"}
              onClick={handleSetUpAutoPay}
            >
              Set up auto-pay
            </Button>
          </div>
        </React.Fragment>
      </div>
    ) : cardView === "processing" ? (
      // nachUser && nachAccountId != null && nachAccountStatus?
      <div className={"autoDebitViewCardContainer"}>
        <React.Fragment>
          <div className={"autoDebitQuestionCardInnerContainer"}>
            <div className={"autoDebitQuestionCardView"}>
              <div className={"questionCardTop"}>
                <div
                  className={"autoDebitViewCardHalfProcessing"}
                  style={{ width: "70%" }}
                >
                  <div
                    className={"autoDebitProcessingDialogue"}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <div id="image" style={{ display: "inline" }}>
                      <img src={dangerYellow}></img>
                    </div>
                    <h4
                      style={{
                        display: "inline",
                        whiteSpace: "nowrap",
                        marginLeft: "1%",
                      }}
                    >
                      Auto-pay processing
                    </h4>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                      marginTop: "3%",
                    }}
                  >
                    <div>
                      <div className={"txn-label"}>
                        We are currently processing your E-Nach
                      </div>
                      <div className={"txn-label"}>
                        application.Please check again in few
                      </div>
                      <div className={"txn-label"}>days!</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        className={"txn-label"}
                        style={{ paddingRight: "5px" }}
                      >
                        Need help?
                      </div>
                      <a
                        href={"tel:" + autoDebit.cdNum}
                        className={"autoDebitLinkText"}
                      >
                        Contact Customer Support
                      </a>
                    </div>
                  </div>
                </div>
                <div className={"autoDebitImageHalf"} style={{ width: "30%" }}>
                  <img src={phoneWithTickMark}></img>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </div>
    ) : cardView === "completed" ? (
      // nachAccountId != null && nachAccountStatus === 'ACTIVE' ?
      <div className={"autoDebitViewCompletedCardContainer"}>
        <React.Fragment>
          <div className={"autoDebitCompletedContainer"}>
            <div className={"autoDebitCompletedImageDiv"}>
              <img src={phoneWithTickMark}></img>
            </div>
            <div className={"autoDebitCompletedDialogueDiv"}>
              <h4 style={{ color: "#8bc34a", marginBottom: "5px" }}>
                Congratulations!
              </h4>
              <span style={{ marginTop: "0%" }} className={"txn-label"}>
                Auto-pay feature is now active for your account!
              </span>
            </div>
          </div>
        </React.Fragment>
      </div>
    ) : cardView === "nonPdc" ? (
      // nachUser && nachAccountId == null ?
      <div className={"autoDebitViewCardContainer"}>
        <React.Fragment>
          <div className={"autoDebitQuestionCardInnerContainer"}>
            <div className={"autoDebitQuestionCardView"}>
              <div className={"questionCardTop"}>
                <div className={"autoDebitViewCardHalf"}>
                  <h4>Hassle Free Payments</h4>
                  <div className={"txn-label"}>
                    Activate auto-pay feature to pay all
                  </div>
                  <div className={"txn-label"}>your dues automatically</div>
                  <NavLink
                    className={"autoDebitLinkText"}
                    style={{ marginTop: "4%" }}
                    to={{
                      pathname: "/setup-auto-pay",
                      state: {
                        bzid: this.props.bzid,
                      },
                    }}
                  >
                    Know more
                  </NavLink>
                </div>
                <div className={"autoDebitImageHalf"}>
                  <img src={phoneWithTickMark}></img>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Button
              variant="contained"
              className={"autoDebitBtn"}
              onClick={handleSetUpAutoPay}
            >
              Set up auto-pay
            </Button>
          </div>
        </React.Fragment>
      </div>
    ) : null;
  }
}

export default React.memo(AutoDebitDetailsCard);
