import React, { useContext } from 'react';
import BackDrop from './BackDrop.js';
import CONF from './../../config/config.js';
import cartDark from './../../assets/cart-dark.svg';
import storeDark from './../../assets/store-dark.svg';
import myOrder from './../../assets/my-order.svg';
import userIcon from './../../assets/user-dark.svg';
import logout from './../../assets/logout.svg';
import star from './../../assets/star.svg';
import { AppContext } from './../../App.js';
import { publishEvent } from './../../utilities/ManageEvents.js';
import './Style.css';
import Qr from './../../assets/qr.png';

export default function Drawer(props) {
    const { open } = props;
    let drawerClasses = (open) ? 'side-drawer open' : 'side-drawer';
    const context = useContext(AppContext);
    const user = context.user.get;

    const icons = {
        cartDark: cartDark,
        myOrder: cartDark,
        storeDark: storeDark,
        user: userIcon,
        logout: logout,
        star,
        qr:Qr,
    };

    const itmClicked = (item) => {
        console.log(item)
        props.close();

        publishEvent(item.event);
        props.clicked(item);
    }

    const menuItemHref = (item) => {
        return (<a href={item.href} onClick={() => { return publishEvent(item.event) }}><div className="side-menu-item row">
            <div className="col-3"><img width={"20"} src={icons[item.icon]} alt={'icon'} /></div>
            <div className="col-9">{item.title}</div>
        </div></a>)
    }

    const menuItemOnClick = (item) => {
        return (<div className="side-menu-item row" onClick={() => { itmClicked(item) }}>
            <div className="col-3"><img src={icons[item.icon]} alt={'icon'} /></div>
            <div className="col-9">{item.title}</div>
        </div>)
    }

    const getMenuItems = () => {
        let response = [];
        for (let i in CONF.DRAWER_MENU) {
            let item = CONF.DRAWER_MENU[i];
            switch (item.id) {
                case 'LOGIN':
                    if (!user) response.push(<div key={i}>{menuItemHref(item)}</div>);
                    break;
                case 'LOG_OUT':
                    if (user) response.push(<div key={i}>{menuItemOnClick(item)}</div>);
                    break;
                case 'MY_ORDERS':
                    if (user) response.push(<div key={i}>{menuItemHref(item)}</div>);
                    break;
                case 'TARGET_SCHEMES':
                    if (user) response.push(<div key={i}>{menuItemHref(item)}</div>);
                    break;
                case 'CREDIT':
                    if (user) response.push(<div key={i}>{menuItemHref(item)}</div>);
                    break;
                default:
                    response.push(<div key={i}>{(item.href) ? menuItemHref(item) : menuItemOnClick(item)}</div>);
            }
        }
        return response;
    }

    return (
        <div>
            {(open) ? <BackDrop onClick={props.close} /> : null}
            <div className={drawerClasses}>
                <div className="user-wel">
                    <div>Hello {(user && user.name) ? user.name : 'J24 User'}</div>
                    <div>Welcome!</div>
                    {user ? <div className="font-14 top-ten">{user.phoneNumber}</div> : null}
                </div>
                <div className="side-menu">
                    {getMenuItems()}

                </div>
            </div>
        </div>);
}
