import React from "react"
import emptycart from './../../assets/emptycart.svg';

export default function EmptyCart() {
    return (<div className="row empty-cart">
        <div className="no-item-box">
            <div><img src={emptycart} alt={"empty cart"}/></div>
            <div>You have not added any item to your cart</div>
            <div className="top-twenty"><a href="/"><button className="go-home-btn"> Browse Products </button></a></div>
        </div>
    </div>);
}