import React from 'react'
import success from "../../../assets/payment-success.svg";
import fail from "../../../assets/payment-failed.svg";
import cross from "../../../assets/close.svg";
import { SuccessDateFormat } from "../../../utilities/timeUtils";
import { formatToIndianCurrency } from "../../../utilities/currencyFormatter";
import { sendEventToAlooma } from "../../../utilities/ManageEvents";
const timeDate = (timestamp) => SuccessDateFormat(timestamp);
export function PaymentResponse({ close, cta }) {
    const onClickClose = (eventName) => {
        sendResponseEvents(eventName);
        close();
    };

    const sendResponseEvents = (eventName) => {
        var eventData = {
            business_id: window.bzid,
            bzid: window.bzid,
            timestamp: new Date().getTime(),
        };
        sendEventToAlooma(eventName, eventData);

    };
    let Remarks = window.clientRemarks;
    let clientRemarks = Remarks.split(/(\d+)/);
    let Remark_Str_One = `${clientRemarks[0]}`;
    let ClientRemarkMsg = Remark_Str_One.split('.')
    let Remark_Str_two = `${clientRemarks[1]}${clientRemarks[2]}${clientRemarks[3]}${clientRemarks[4]}${clientRemarks[5]}`
    let Remarkmessage = (<React.Fragment>{ClientRemarkMsg[0]}.<br></br>{ClientRemarkMsg[1]}<span style={{ color: '#6BB46C', marginTop: '3px' }}>{Remark_Str_two}</span></React.Fragment>)

    if (window.isPaymentSuccessful === "true") {
        return (
            <div className="success-diag">

                <div
                    class="red-x big-x close_responce"
                    onClick={() => onClickClose("ON_CLICK_CREDIT_PAYMENT_SUCCESS_CLOSE")}
                >
                    &#10005;
                </div>
                <img src={success} className="success-diag-icon" />
                <div className="success-header">
                    <div className="title">PAYMENT SUCCESS!</div>
                    <div className="sub-title">
                        {window.clientRemarks ? Remarkmessage : ''}

                        {/* {window.clientRemarks} */}
                        {/* Your extension request is granted.<br></br>Revised due date is{" "} */}
                        {/* <span style="{{color:'#6BB46C'}}">{window.date}</span>. */}
                    </div>

                </div>
                <div className="success-footer">
                    <div>
                        <span>Amount Paid:</span>
                        <span>
                            {formatToIndianCurrency(Number(window.paymentAmount / 100))}
                        </span>
                    </div>
                    <div>
                        <span>Transaction ID:</span>
                        <span>{window.paymentTransactionId}</span>
                    </div>
                    <div>

                        <span>Date:</span>
                        <span>{timeDate(window.timestamp)}</span>
                    </div>
                </div>
                {/* <div className="close-btn" onClick={() => onClickClose("ON_CLICK_CREDIT_PAYMENT_SUCCESS_CLOSE")}>
          CLOSE
        </div> */}

            </div>
        );
    }
    return (
        <div className="success-diag">
            <img src={fail} className="success-diag-icon" />
            <img
                src={cross}
                className="cross-diag-icon"
                onClick={() => onClickClose("ON_CLICK_CREDIT_PAYMENT_FAILURE_CLOSE")}
            />


            <div className="failure-header">
                <div className="title">PAYMENT FAILED</div>
                <div className="body">{window.paymentError}</div>
            </div>
        </div>
    );
}


