import React from 'react';
import './Style.css';

export default function TypeAheadResult({items, onSelection}) {

	const itemSelected = (itm, index) => {
		onSelection(itm, index);
	}

	return <div>
	{(items && items.length > 0) ?
		<ul className="dropdown-menu rec-dropdown-new" style={{display: 'block'}}>
			{items.map((itm, index) => { return  (<li className={"dropdown-data-rec "} key={index} onClick={() => itemSelected(itm, index)}>
				<div className="dropdown-data-rec-inner">{itm.title.localizedText}</div>
			</li>)})}
		</ul>:null
	}
	</div>
}


// export default class TypeAheadResult extends Component {

// 	constructor(props) {
// 		super(props);
// 	}

// 	onSelection = (itm)  => {
// 		this.props.onSelection(itm);
// 	}

// 	render() {
// 		return (<div>
// 			<ul className="dropdown-menu rec-dropdown-new" style={{display: 'block'}}>
// 				{this.props.items.map((itm, index) => 
// 						{ return (						
// 								<li className={"dropdown-data-rec "} key={index} onClick={this.onSelection}>
// 											<div className="dropdown-data-rec-inner">{itm.title}</div>
// 								</li>
// 							)
// 						}
// 					)
// 				}
// 			</ul>
// 		</div>);
// 	}
// }
// >>>>>>> 70f7f11758bf8c19cc5c09f5e6bf81b6b3cfdba1
