import React, { useState, useEffect, useContext } from "react";
//import CircularProgress from "@material-ui/core/CircularProgress";
import { sendEventToAlooma } from "../../../utilities/ManageEvents";
import serverApiHandler from "../../apiHandler/serverApiHandler";
import ErrorScreen from "../../error/error";
import Payment from "./payments.js";
import Loader from "../../common/Loader";
import { AppContext } from "../../../App";

function ClearedCredit(props) {
  const context = useContext(AppContext);
  const [cleared, setCleared] = useState({
    line: null,
    loading: false,
    errorcame: false,
  });
  useEffect(() => {
    clearedCraditData();
  }, []);
  const sendFirstEvent = (bzid) => {
    var eventName = "ON_LOAD_CLEARED_TRANSACTIONS";
    var endTime = new Date().getTime();
    var eventData = {
      business_id: bzid,
      timestamp: endTime,
    };
    sendEventToAlooma(eventName, eventData);
  };

  const sendEvent = (bzid, startTime) => {
    var eventName = "ON_CLEARED_TRANSACTIONS_API_RESPONSE_LOADED";
    var endTime = new Date().getTime();
    var loadtime = endTime - startTime;
    var eventData = {
      business_id: bzid,
      load_time: loadtime,
      timestamp: endTime,
    };
    sendEventToAlooma(eventName, eventData);
  };
  const setTheResp = (resp) => {
    if (resp.creditLineAndTransactionsDetailsList.length > 0) {
      setCleared({
        line: resp.creditLineAndTransactionsDetailsList[0].creditLineDetails,
      });
      window.clearedCreditLineData =
        resp.creditLineAndTransactionsDetailsList[0].creditLineDetails;
    }
  };
  const clearedCraditData = () => {
    //var bzid = context.user.get.bzId;
    // var clearedCreditLineData = window.clearedCreditLineData;
    // if (clearedCreditLineData) {
    //   this.setState({ line: clearedCreditLineData });
    // } else {
    // this.sendFirstEvent(bzid);
    //console.log(bzid);
    var startTime = new Date().getTime();
    setCleared({ loading: true });
    serverApiHandler
      //.getClearedCredit('BZID-1304438756')
      .getClearedCredit(context.user.get.bzId)
      .then((resp) => {
        setCleared({ loading: false });
        setTheResp(resp);
        // sendEvent(bzid, startTime);
      })
      .catch((error) => {
        // this.setState({ errorcame: true });
      })
      .finally(() => {
        // setCleared({ loading: false });
      });
    // }
  };
  return (
    <div>
      {cleared.line ? (
        <div>
          {cleared.line.transactionGroupsList &&
          cleared.line.transactionGroupsList.length > 0 ? (
            <div style={{ marginTop: "20px" }}>
              <Payment
                payment={cleared.line.transactionGroupsList[0]}
                provider={cleared.line.creditProductName}
              />
            </div>
          ) : (
            <div>
              <div className="no-past-scheme">
                You have no completed transaction
              </div>
            </div>
          )}
        </div>
      ) : null}
      {cleared.loading ? (
        <div className="vertical-middle centralize" style={{ width: "100%" }}>
          {/* <CircularProgress
              style={{ color: "#000", textAlign: "center" }}
              size={60}
              thickness={4}
            /> */}
          <Loader />
        </div>
      ) : null}
      {cleared.errorcame ? (
        <div>
          <ErrorScreen />
        </div>
      ) : null}
    </div>
  );
}

export default React.memo(ClearedCredit);
