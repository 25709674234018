import React, {useEffect } from 'react';
import { sendEventToAlooma } from "../../../utilities/ManageEvents";
import banner from "../../../assets/unlock-credit-banner.jpg";
import money from "../../../assets/money-bag.svg";
import percentage from "../../../assets/percentage.svg";
import calendar from "../../../assets/unlock-credit-calendar.svg";
import pointer from "../../../assets/mouse-pointer.svg";
import file from "../../../assets/file-text.svg";
import check from "../../../assets/check-circle.svg";
import arrow from "../../../assets/arrow-right.svg";
function UnlockCredit(props) {
    const rupifiLink = `https://rupifi.in/z29pj?leadId=${window.bzid}`;
    const eventNames = {
        CIBIL_BTN: "ON_CLICK_CREDIT_CHECK_CIBIL",
        CIBIL_BTN_IN_DIALOG: "ON_CLICK_CREDIT_CHECK_CIBIL_IN_READ_MORE",
        READ_MORE: "ON_CLICK_CREDIT_READ_MORE_ABT_CIBIL",
        INSTRUCTIONS: "ON_CLICK_CREDIT_ENABLE_INSTRUCTIONS",
        CLICK_VIDEO: "ON_CLICK_CIBIL_CHECK_VIDEO",
        CLICK_HTA: "ON_CLICK_CREDIT_HTA",
    };
    useEffect(() => {
        document.title = "Jpay Later";
    }, [])

    const getYTLink = () => {
        switch (props.language) {
            case "te":
                return "https://www.youtube.com/embed/4YVuawAvkRA";
            case "kn":
            default:
                return "https://www.youtube.com/embed/le18x9nRtj0";
        }
    }

    const handleVideoClick = () => {
        if (document.activeElement === document.getElementById("credit-video")) {
            sendEvent(eventNames.CLICK_VIDEO);
        }
    }
    useEffect(() => {
        window.addEventListener("blur", handleVideoClick);
    }, [])

    const sendEvent = (eventName) => {
        var eventData = {
            timestamp: new Date().getTime(),
            source: "UNLOCK_CREDIT",
        };
        sendEventToAlooma(eventName, eventData);
    }

    const creditInfo = {
        benefits: [
            {
                image: calendar,
                title: "Upto 7 days Free Credit",
                text: "1-7 days free credit based on your orders",
                alt: "calendar",
            },
            {
                image: money,
                title: "Credit upto 1 Lakh",
                text: "Grow your business with zero investment",
                alt: "money",
            },
            {
                image: percentage,
                title: "Low Interest Rate",
                text: "Minimum interest with no hidden charges",
                alt: "percentage",
            },
        ],
        steps: [
            { image: pointer, text: "Click on Apply Now", alt: "pointer" },
            { image: file, text: "Fill in your details", alt: "file" },
            { image: check, text: "Submit for approval", alt: "check" },
        ],
        stepsDivider: <img className="hta-img arrow" src={arrow} alt="arrow" />,
    };

    return (
        <div className="unlock-credit">
            <div className="unlock-credit-banner">
                <img className="banner-img" src={banner} alt="credit banner" />
                <div className="unlock-credit-text title-text">
                    UNLOCK CREDIT TODAY
                </div>
            </div>
            <div
                className="benefits"
                onClick={() => sendEvent(eventNames.INSTRUCTIONS)}
            >
                {creditInfo.benefits.map((creditBenefit) => {
                    return (
                        <div className="benefit">
                            <div className="benefit-img-wrapper">
                                <img
                                    className="benefit-img"
                                    src={creditBenefit.image}
                                    alt={creditBenefit.alt}
                                />
                            </div>
                            <div className="benefit-text">
                                <div className="large-text">{creditBenefit.title}</div>
                                <div className="small-text">{creditBenefit.text}</div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div
                className="hta"
                onClick={() => sendEvent(eventNames.CLICK_HTA)}
            >
                <div className="title-text underline">HOW TO APPLY</div>
                <div className="hta-items">
                    {creditInfo.steps.map((creditStep, ind) => {
                        return (
                            <React.Fragment>
                                <div className="hta-item">
                                    <img
                                        className="hta-img"
                                        src={creditStep.image}
                                        alt={creditStep.alt}
                                    />
                                    <div className="hta-text">{creditStep.text}</div>
                                    <div className="hta-item-num">{ind + 1}</div>
                                </div>
                                {ind < creditInfo.steps.length - 1
                                    ? creditInfo.stepsDivider
                                    : null}
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
            <div className="video-wrapper">
                <iframe
                    id="credit-video"
                    title="credit-video"
                    width="560"
                    height="315"
                    src={getYTLink()}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
            <a
                className="apply-now-cta"
                href={rupifiLink}
                onClick={() => sendEvent(eventNames.CIBIL_BTN)}
            >
                APPLY NOW
            </a>
        </div>
    )
}

export default React.memo(UnlockCredit)
