import React, { useContext } from "react";
import Transaction from "./transaction.js";

function payments(props) {
  const trans = props.payment.transactionDetailsList.map((txn, index) => (
    <div>
      <Transaction
        txn={txn}
        type={props.payment.groupType}
        provider={props.provider}
        key={index}
        bzid={props.bzid}
        //bzid='BZID-1304438756'
        // bzid={this.props.bzid}
      />
    </div>
  ));
  const numfor = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });
  return (
    <div>
      <div className="payments-box">
        <div className="row">
          <div className="jt-col-100">
            <div className="payments-info">
              <div className="pull-left payment-name">
                {props.payment.groupTypeText}
              </div>
              <div
                className={
                  "pull-right payment-amount " +
                  (props.payment.groupType === "SUBMITTED_TO_BANK"
                    ? "submittedAmount"
                    : "")
                }
              >
                {props.payment.groupType !== "RECENTLY_CLEARED" ? (
                  <div>{numfor.format(props.payment.totalAmount)}</div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {props.payment.groupType === "SUBMITTED_TO_BANK" ? (
          <div className="submitted-bank-info">
            Kindly ensure to keep sufficient balance in your A/C
          </div>
        ) : null}
        <div>{trans}</div>
      </div>
    </div>
  );
}

export default React.memo(payments);
