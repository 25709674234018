import React, { useEffect, useState,useContext } from 'react'
import Txndata from "./txn-data.js";
import { sendEventToAlooma } from "../../../utilities/ManageEvents";
import './Transaction.css';
import { AppContext } from "../../../App";
function TransactionDetail(props) {
  const context = useContext(AppContext)
  const [snackbarOpen, setsnackbarOpen] = useState(false)
  const { transaction } = props.location.state;
  const { provider } = props.location.state;
  const { type } = props.location.state;
  const { bzid } = props.location.state;
  const showCTA = type === "DELAYED";
  console.log(transaction.paymentInstrumentDetails)

  useEffect(() => {
    context.header.set({ type: "PAGE_NAME", text: "JPay Later" });
    document.title = "Payment Details";
    sendOnLoadEvent(transaction.paymentInstrumentDetails);
  }, [])

  const sendOnLoadEvent = (pi) => {
    var eventName = "ON_LOAD_CREDIT_TXN_DETAILS";
    var eventData = {
      business_id: window.bzid,
      payment_instrument_id: pi.paymentInstrumentId,
      pi_status: pi.paymentInstrumentStatus,
      timestamp: new Date().getTime(),
    };
    sendEventToAlooma(eventName, eventData);
  }

  return (
    <div style={{ backgroundColor: '#f6f6f6', minHeight: '100vh' }}>
      <Txndata txn={transaction} provider={provider} />
    </div>
  )
}

export default React.memo(TransactionDetail)

