import React, { useState } from 'react'
import arrow from "../../../assets/right-arrow.svg"
import TxnState from "./txn-state"
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import PenaltyDialoge from "./penalty-dialog.js";
import { sendEventToAlooma } from "../../../utilities/ManageEvents";

function TxnData(props) {
  const [txndata, setTxndata] = useState(false)
  function Transition(props) {
    return <Slide direction="down" {...props} />;
  }
  const handleClickOpen = () => {
    setTxndata(true);
  };

  const handleClose = () => {
    setTxndata(false);
  };
  const clickPenalty = () => {
    var eventName = "PENALTY_DETAILS";
    var eventData = {
      // business_id: window.bzid,
      business_id: 'BZID-1304438756',
      pi_type: props.txn.paymentInstrumentDetails.paymentInstrumentType,
      pi_status:
        props.txn.paymentInstrumentDetails.paymentInstrumentStatus,
      position: "TXN_HEADER",
    };
    sendEventToAlooma(eventName, eventData);
  }

  const clickCall = () => {
    var eventName = "ON_CLICK_CALL_US_WEB";
    var eventData = {
      // business_id: window.bzid,
      business_id: 'BZID-1304438756',
      condition:
        props.txn.paymentInstrumentDetails.paymentInstrumentStatus,
      timestamp: new Date().getTime(),
      page: "CREDIT_TXN_SCREEN",
    };
    sendEventToAlooma(eventName, eventData);
  }
  //<!----------------------- Formatore (CURRENCY, TIME) -----------------------!>
  const numfor = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });

  const datefor = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
  //<!----------------------- Formatore Ends -----------------------!>
  var priceBreakUpList = props.txn.paymentBreakupList;
  var statusList = props.txn.paymentInstrumentDetails.paymentInstrumentStatusList;
  //     //<!----------------------- Calculating Amount (PENALTY, INTEREST and TOTAL_SHOW_AMOUNT) -----------------------!>
  var interest = 0;
  for (var i of priceBreakUpList) {
    if (i.paymentType === "INTEREST") {
      interest += i.amount;
    } else if (i.paymentType === "CONVENIENCE_FEE") {
      interest += i.amount;
    } else if (i.paymentType === "CONVENIENCE_FEE_TAX") {
      interest += i.amount;
    }
  }
  //     //<!----------------------- Amount Calculated -----------------------!>

  //     //<!----------------------- Other Details That Can Be Shown in State -----------------------!>
  var pi = props.txn.paymentInstrumentDetails;
  var piDetails = {
    paymentInstrumentType: pi.paymentInstrumentType,
    piRefId: pi.piRefId,
    ifscCode: pi.ifscCode,
    penalty: props.txn.totalPenalty,
    dueDate: props.txn.dueDate,
    chequeAmount: props.txn.originalAmount,
    piStatus: pi.paymentInstrumentStatus,
    repaidPenalty: props.txn.repaidPenalty,
    txnStatus: props.txn.status,
  };
  if (
    props.txn.paymentInstrumentDetails.chequeBouncedText &&
    props.txn.paymentInstrumentDetails.chequeBouncedText
      .reasonForChequeBounce
  ) {
    piDetails.bounceReason =
      props.txn.paymentInstrumentDetails.chequeBouncedText.reasonForChequeBounce;
  }

  var dateType;
  var dateVal;
  switch (props.txn.status) {
    case "BOUNCED":
      dateType = "Bounced on";
      dateVal = props.txn.paymentInstrumentDetails.bouncedDate;
      break;
    case "REPAID":
      dateType = "Repaid on";
      dateVal = props.txn.paymentInstrumentDetails.repaidDate;
      break;
    case "IN_HUB":
      if (props.txn.paymentInstrumentDetails.collectedDate) {
        dateType = "Collected on";
        dateVal = props.txn.paymentInstrumentDetails.collectedDate;
      } else {
        dateType = "Due date";
        dateVal = props.txn.dueDate;
      }
      break;
    case "CLEARED":
      dateType = "Cleared on";
      dateVal = props.txn.paymentInstrumentDetails.clearedDate;
      break;
    default:
      dateType = "Due date";
      dateVal = props.txn.dueDate;
  }
  //     //<!----------------------- Other Details Added -----------------------!>
  //     //<!----------------------- Adding Extra Faded States -----------------------!>
  var fadedStates = [];
  var lastState = statusList[statusList.length - 1];
  var addingClear = [
    "Submitted to bank",
    "Cheque Can be Represented By Bank",
    "Altered Cheque",
  ];
  var addingRepaid = [
    "Bounced",
    "Partially Repaid",
    "Dpn Collected",
    "Repayment delayed",
  ];
  var addingBoth = ["Cheque Collected"];
  if (addingClear.includes(lastState.statusName)) {
    var obj1 = {
      statusName: "Cleared",
      faded: true,
    };
    fadedStates.push(obj1);
  } else if (addingRepaid.includes(lastState.statusName)) {
    var obj2 = {
      statusName: "Repaid",
      faded: true,
    };
    fadedStates.push(obj2);
  } else if (addingBoth.includes(lastState.statusName)) {
    var obj3 = {
      statusName: "Submitted to the bank",
      faded: true,
    };
    var obj4 = {
      statusName: "Cleared",
      faded: true,
    };
    fadedStates.push(obj3);
    fadedStates.push(obj4);
  }
  //console.log(this.props)
  //     //<!----------------------- Added Faded States -----------------------!>
  //     //<!----------------------- Iterating States Of Transaction -----------------------!>
  var states = statusList.map((pn, index) => (
    <TxnState

      state={pn}
      pi={piDetails}
      showSideLine={statusList.length > index + 1}
      showFadedLine={fadedStates.length > 0}
      onnShowPenalty={handleClickOpen}
      key={index}

    />
  ));
  console.log(fadedStates)
  var fadedstates = fadedStates.map((pn, index) => (
    <TxnState
      state={pn}
      showFadedLine={fadedStates.length > index + 1}
      key={index}
    />
  ));


  console.log(dateType)
  console.log(props.txn.status)
  return (
    <div>
      <div className="txn-data-box">
        <span className={"txn-status-box " + props.txn.status}>
          {props.txn.highlightedStatusText
            ? props.txn.highlightedStatusText
            : "Pending"}
        </span>

        <div className="amount-due">
          Amount Due
        </div>
        {dateType && dateVal ? (
          <div className="top-ten center">
            <span>
              <span className="credit-values-label">{dateType} </span>
              <span className="credit-values-item">
                {datefor.format(dateVal)}
              </span>
            </span>
          </div>
        ) : null}
        {props.txn.status === "PARTIALLY_REPAID" ? (
          <div className="available-balance top-ten center">
            {numfor.format(
              props.txn.originalAmount - props.txn.repaidAmount
            )}
            <span className="small-total-balance">
              {" "}
              / {numfor.format(props.txn.originalAmount)}
            </span>
          </div>
        ) : (
          <div className="available-balance center">
            {numfor.format(props.txn.originalAmount)}
          </div>
        )}

        {props.txn.totalPenalty > 0 &&
          (props.txn.repaidPenalty == null ||
            props.txn.status === "PARTIALLY_REPAID" ||

            props.txn.status === "BOUNCED"
          ) ? (

          <div className="top-ten center" onClick={handleClickOpen}>
            <span
              className="bounced-reason-box"
              onClick={clickPenalty()}
            >
              <span>Penalty to be paid </span>
              <span className="penalty-amount">
                {numfor.format(props.txn.totalPenalty)}
              </span>
              <span className="outer-slider">
                <span className="sider-new">
                  <img src={arrow} width="24px" alt=">" />
                </span>
              </span>
            </span>
          </div>
        ) : null}
        {props.txn.repaidPenalty !== null &&

          props.txn.status !== "PARTIALLY_REPAID" &&
          props.txn.status !== "BOUNCED" ? (
          <div style={{ marginTop: '7px' }} className="center">

            <span className="bounced-reason-box-paid">
              <span>Penalty paid </span>
              <span className="penalty-amount">
                {numfor.format(props.txn.repaidPenalty)}
              </span>
            </span>
          </div>
        ) : null}
      </div>

      <Dialog
        open={txndata}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="xs"
        classes={{ paperWidthXs: "MuiDialog-paper" }}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <PenaltyDialoge penalties={props.txn.paymentBreakupList} />
        <div className="penalty-closer red-ok" onClick={handleClose}>
          OK
        </div>
      </Dialog>

      {props.txn.paymentInstrumentDetails.showChequeBouncedText ? (
        <div className="white-bg-padding top-ten">
          <div className="cancelled-cehque-box">
            <div className="title">
              {
                props.txn.paymentInstrumentDetails.chequeBouncedText
                  .chequeCancelledInfoText
              }
            </div>
            <div className="desc">
              {
                props.txn.paymentInstrumentDetails.chequeBouncedText
                  .chequeCancelledReaffirmationText
              }
            </div>
          </div>
        </div>
      ) : null}
      <div className="white-bg-padding top-ten">
        <div className="credit-provider">
          <div className="row first-line">
            <div className="jt-col-40 customFont">Credit Provider</div>
            <div className="jt-col-60 customFont">
              <div className="pull-right">
                {props.txn.convenienceFeeEnabled
                  ? "Total Convenience Fee"
                  : "Interest"}{" "}
                {numfor.format(interest)}
              </div>
            </div>
          </div>
          <div className="row second-line">
            <div className="jt-col-50">
              <span className="credit-provider-name">
                {props.provider}
              </span>
            </div>
            <div className="jt-col-50">
              <div className="pull-right">
                {props.txn.showSubsidizedFree ? (
                  <span className="free">
                    {props.txn.subsidizedFreeText}
                  </span>
                ) : null}
                <span className="free-till">
                  {" "}
                  {props.txn.subsidizedDaysText}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="payment-detail-heading top-twenty">Payment Details</div>

      <div className="white-bg top-ten txn-cycle">
        {states}
        {fadedstates}
      </div>

      <div style={{ height: "30px" }}></div>

    </div>


  );
}

export default React.memo(TxnData)
