import React, { useEffect } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { ReactComponent as Close } from './../../assets/close-black.svg';
import curLoc from "./../../assets/current-location.svg"


export default function SearchAddress({ handleLocationSelection, handleCurrentLocation, open }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete();

  useEffect(() => {
    console.log(data)
  }, [data])

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions()
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      handleLocationSelection({ lat, lng, address });
    } catch (error) {
      console.log("getGeoCode failed", error);
    } finally {
    }
  };

  return (
    <div className="search-address">
      <Close onClick={() => open(false)} width="15px" className="close"></Close>
      <input
        className="search-address-input"
        type="text"
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="Search for Area, Street name"
      />
      <div className="suggestions-wrapper pick-cur-loc" onClick={handleCurrentLocation}>
        <img src={curLoc} alt={"current location"}/>
        <span className="lr-10">Pick Current Location</span>
      </div>
      {status === "OK" &&
        data.map(({ place_id: id, description }) => (
          <div key={id} className="suggestions-wrapper" onClick={() => handleSelect(description)}>
            {description}
          </div>
        ))}
    </div>
  );
}