import React, { useContext } from "react";
import right from "./../../assets/right.svg";
import cartIcon from "./../../assets/cart-dark.svg";
import { AppContext } from "./../../App.js";
import { formatToIndianCurrency } from "./../../utilities/Utils.js";
import { shouldShowItems } from "./../../utilities/CartUtils.js";
import { publishEvent, getCartBundle } from "./../../utilities/ManageEvents.js";
import "./Style.css";
import { Link } from "react-router-dom";

export default function Cart({ relativePos, handleCheckOut }) {
  let path = window.location.pathname;
  let isCart = false;
  if (path === "/cart") isCart = true;

  const context = useContext(AppContext);
  const cart = context.cart.get;
  const user = context.user.get;
  const addressExists =
    (user && user.addressSummaries.length > 0) ||
    localStorage.getItem("guestAddress");

  // const getTotalItems = () => {
  // 	return cart.cartDetails.activeCartItems.reduce((acc, obj) => { return acc + obj.selectedQuantity; }, 0);
  // }

  const getCartElement = () => {
    if (isCart) {
      if (handleCheckOut)
        return (
          <button className="cart-btn" onClick={handleCheckOut}>
            Place Order
          </button>
        );
      return user ? (
        <a href="/address">
          <button className="cart-btn">
            Proceed
          </button>
        </a>
      ) : (
        <Link
          to={{
            pathname: "/login",
            state: { from: "cartpage" },
          }}
        >
          <button className="cart-btn">
            Proceed
          </button>
        </Link>
      );
    }
    return (
      <a
        href="/cart"
        onClick={() => {
          return publishEvent("ON_TAP_CART_BOX", getCartBundle(cart));
        }}
      >
        <button className="cart-btn">
          GO TO CART
          <img src={right} alt={">"} className={"back-btn left-5"} />
        </button>
      </a>
    );
  };

  return (
    <div>
      {shouldShowItems(cart) ? (
        <div
          className="bottom-cart"
          style={{ position: relativePos ? "relative" : "fixed" }}
        >
          <div className="cart-inner">
            <div className="row">
              <div
                className="col-2"
                style={{ lineHeight: "47px", textAlign: "center" }}
              >
                <div className="c-items-indic">
                  {cart.cartDetails.activeCartItems.length}
                </div>
                <img
                  src={cartIcon}
                  alt={"cart"}
                  className={"bottom-cart-icon"}
                />
              </div>
              <div className="col-6">
                <div className="b-cart-amount">
                  {" "}
                  {formatToIndianCurrency(cart.cartDetails.cartAmount)}
                </div>
                <div className="b-cart-items">
                  {" "}
                  {cart.cartDetails.activeCartItems.length} items
                </div>
              </div>
              <div className="col-4">{getCartElement()}</div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
