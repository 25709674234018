import React, { useContext, useEffect, useState } from "react";
import jumbocoin from "./../../assets/jumbocoin.png";
import { AppContext } from "../../App";
import Loader from "../common/Loader";
import ErrorScreen from "../common/ErrorScreen";
import serverApiHandler from "./../apiHandler/serverApiHandler.js";
import {
  formatToIndianCurrencyRounded,
  formatDate,
} from "./../../utilities/Utils.js";
import "./Style.css";

export default function GreencardHistory() {
  const context = useContext(AppContext);
  const header = context.header;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [transcations, setTranscations] = useState(null);

  useEffect(() => {
    // header.set({ text: "Jumbocoins earned & spent", type: "PAGE_NAME" });
    //CODE:J24DEMO
    header.set({ text: "Jumbocash earned & spent", type: "PAGE_NAME" });
    setLoading(true);
    serverApiHandler
      .getLoyaltyHistory()
      .then((resp) => {
        setTranscations(resp.transactions);
      })
      .catch((error) => {
        setError(true);
      })
      .finally((resp) => {
        setLoading(false);
      });
  }, []);

  if (error) return <ErrorScreen />;
  return (
    <div className="gc-transactions">
      {loading ? (
        <Loader />
      ) : (
        <>
          {transcations && (
            <>
              <div className="row center fw-500 gc-txt-sm gc-header">
                <div className="col-4">Bill no. & time</div>
                <div className="col-4 centered">Bill Amount</div>
                <div className="col-4 right-text">Earnings & Redeems</div>
              </div>
              {transcations.map((transcation, index) => {
                return (
                  <div className="row transaction-row" key={index}>
                    <div className="col-4  gc-txt-sm">
                      <div>{transcation.orderId}</div>
                      <div>{formatDate(transcation.orderPlacedTime)}</div>
                    </div>
                    <div className="col-4 centered gc-txt-md">
                      {formatToIndianCurrencyRounded(transcation.orderAmount)}
                    </div>
                    <div className="col-4 gc-txt-md fw-500">
                      <div className={`row ${transcation.transactionType}`}>
                        <span>{transcation.jumboCoins}</span>
                        <img
                          src={jumbocoin}
                          className="jumbocoin"
                          alt="jumbocoin"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </>
      )}
    </div>
  );
}
