import React, { useState, useEffect, useContext } from "react";
import qs from "query-string";
import Target from "./Target.js";
import Button from "@material-ui/core/Button";
import ErrorScreen from "./../common/ErrorScreen.js";
import serverApiHandler from "./../apiHandler/serverApiHandler.js";
import { publishEvent } from "./../../utilities/ManageEvents.js";
import { Swiper, SwiperSlide } from "swiper/react";
import Loader from "./../common/Loader.js";
import { AppContext } from "../../App";
import "./Style.css";

function Tab({ name, active }) {
  return (
    <div className={`tab-option ${active ? "active" : ""}`}>
      <span>{name}</span>
    </div>
  );
}

function Tabs({ tabs, selected, onSelectTab }) {
  const [swiper, setSwiper] = useState(null);
  const context = useContext(AppContext);
  const header = context.header.get;

  if (selected > -1 && swiper) {
    swiper.slideTo(selected);
  }

  const clickedTab = (i) => {
    onSelectTab(i);
  };

  return (
    <div className={"tab"}>
      <Swiper
        effect="fade"
        slidesPerView={2}
        onSwiper={(swiper) => setSwiper(swiper)}
        onSlideChange={(swiper) => console.log(swiper.realIndex)}
      >
        {tabs.map((tab, i) => (
          <SwiperSlide
            key={i}
            onClick={() => {
              clickedTab(i);
            }}
          >
            <Tab key={i} name={tab} active={selected === i} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default function TargetScheme(props) {
  const [value, setValue] = useState(0);
  const [selected, setSelected] = useState(0);
  const [target, setTargets] = useState(null);
  const [pastTargets, setPastTargets] = useState(null);
  const [errorcame, setErrorcame] = useState(false);
  const [tabs, setTabs] = useState(["Running Schemes", "Past Schemes"]);
  const [swiper, setSwiper] = useState(null);
  const [currentLoader, setCurrentLoader] = useState(false);
  const [pastLoader, setPastLoader] = useState(false);
  const [autoHeight, setAutoHeight] = useState(false);
  const context = useContext(AppContext);

  useEffect(() => {
    document.title = "My Targets";
    context.header.set({ type: "PAGE_NAME", text: "Cashback Schemes" });
    fetchTargets();
    fetchPastTargets();
  }, []);

  const goToHome = () => {
    var eventName = "GO_HOME_TARGET_PAGE";
    var eventData = {
      bzid: window.bzid,
      timestamp: new Date().getTime(),
    };
    publishEvent(eventName, eventData);
    //window.Android.goHome();
    console.log("hello qqqqqqq");
    window.location.assign("/");
  };

  const trimmedResponse = (resp) => {
    var response = [];
    var targets = resp.schemeMemberTargets;
    for (var i in targets) {
      var obj = {};
      obj.schemeMemberTargetId =
        targets[i].schemeMemberTargetBO.schemeMemberTargetId;
      obj.currentValue = targets[i].schemeMemberTargetBO.currentValue;
      obj.targetSchemeId =
        targets[i].schemeMemberTargetBO.targetSchemeBO.targetSchemeId;
      obj.campaignId =
        targets[i].schemeMemberTargetBO.targetSchemeBO.campaignId;
      obj.displayData = targets[i].displayData;
      response.push(obj);
    }
    return { schemeMemberTargets: response };
  };

  const sendLoadEvent = (bzid, startTime, schemeType, pageResp) => {
    var time = new Date().getTime();
    var loadTime = time - startTime;
    var eventName = "ON_LOAD_PAGE";
    var eventData = {
      bzid: window.bzid,
      page_name: "SCHEME_LANDING_PAGE",
      ttms: loadTime,
      item_id: schemeType,
      page_object: this.trimmedResponse(pageResp),
      timestamp: new Date().getTime(),
    };
    publishEvent(eventName, eventData);
  };

  const sendFirstEvent = (bzid) => {
    var time = new Date().getTime();
    var eventName = "ON_SCHEME_LANDING_UI_RENDER";
    var eventData = {
      bzid: bzid,
      page_name: "SCHEME_LANDING_PAGE",
      timestamp: time,
    };
    publishEvent(eventName, eventData);
  };

  const sendClickEvent = (val) => {
    var screenName;
    if (val === 0) {
      screenName = "CURRENT_SCHEMES";
    } else if (val === 1) {
      screenName = "PAST_SCHEMES";
    }
    var eventName = "ON_CLICK_SCHEME_PAGE";
    var eventData = {
      bzid: window.bzid,
      item_id: screenName,
      timestamp: new Date().getTime(),
    };
    publishEvent(eventName, eventData);
  };

  const fetchTargets = () => {
    let pTargets = window.targets;
    if (!pTargets) {
      setCurrentLoader(true);
      var startTime = new Date().getTime();
      let businessId = context.user.get.bzId;
      //let businessId = "BZID-1304349815";
      // let businessId = "BZID-1304438756";
      // let businessId = context.store.get.businessId;
      // console.log(context);
      serverApiHandler
        .getRunningTargets(businessId, startTime)
        .then((resp) => {
          window.targets = resp.schemeMemberTargets;
          setTargets(resp.schemeMemberTargets);
          sendLoadEvent(businessId, startTime, "CURRENT_SCHEMES", resp);
        })
        .catch((error) => {
          setErrorcame(true);
        })
        .finally((data) => {
          setCurrentLoader(false);
        });
    } else {
      setTargets(pTargets);
    }
  };

  const fetchPastTargets = () => {
    var pPastTargets = window.pastTargets;
    if (!pPastTargets) {
      setPastLoader(true);
      var startTime = new Date().getTime();
      let businessId = context.user.get.bzId;
      //let businessId = "BZID-1304349815";
      // let businessId = "BZID-1304438756";
      // let businessId = context.store.get.businessId;
      serverApiHandler
        .getCompletedTargets(businessId, startTime)
        .then((resp) => {
          window.pastTargets = resp.schemeMemberTargets;
          setPastTargets(resp.schemeMemberTargets);
          sendLoadEvent(businessId, startTime, "PAST_SCHEMES", resp);
        })
        .catch((error) => {
          setErrorcame(true);
        })
        .finally((data) => {
          setPastLoader(false);
        });
    } else {
      setPastTargets(pPastTargets);
    }
  };

  const handleChange = (event, value) => {
    sendClickEvent(value);
    setValue(value);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const getRunningTargets = () => {
    if (currentLoader)
      return (
        <div style={{ minHeight: "80vh" }}>
          <Loader />
        </div>
      );
    if (target) {
      if (target.length > 0) {
        return target.map((tgt, idx) => {
          return (
            <div key={idx}>
              <Target target={tgt} page="RUNNINGMAIN" />
            </div>
          );
        });
      } else {
        return (
          <div className="no-targets-for-now">
            <div className="label">No schemes for now</div>
            <div className="text">
              {"We are working on new targets for you with exclusive rewards."}
            </div>
            <div className="home-btn">
              <Button
                variant="contained"
                size="medium"
                className="go-home-target"
                onClick={goToHome}
              >
                Go To Home Page
              </Button>
            </div>
          </div>
        );
      }
    }
    return null;
  };

  const getPastTargets = () => {
    if (pastLoader)
      return (
        <div style={{ minHeight: "80vh" }}>
          <Loader />
        </div>
      );
    if (pastTargets && pastTargets.length > 0) {
      let p = pastTargets;

      p.sort(function (a, b) {
        return a.schemeMemberTargetBO.targetSchemeBO.startTime <
          b.schemeMemberTargetBO.targetSchemeBO.startTime
          ? 1
          : b.schemeMemberTargetBO.targetSchemeBO.startTime <
            a.schemeMemberTargetBO.targetSchemeBO.startTime
          ? -1
          : 0;
      });

      return p.map((target, index) => {
        return (
          <div key={index}>
            <Target target={target} page="PASTMAIN" />
          </div>
        );
      });
    }
    return <div className="no-past-scheme">You have no past schemes</div>;
  };

  const tabSelected = (val) => {
    setSelected(val);
    swiper.slideTo(val);
  };

  const slideChanged = (i) => {
    setSelected(i);
  };

  const adjustHeight = () => {
    setAutoHeight(true);
  };

  return (
    <div>
      <div>
        <div>
          <Tabs tabs={tabs} selected={selected} onSelectTab={tabSelected} />
          <div className="top-twenty"></div>
          <div className="schemes-outer">
            <Swiper
              autoHeight={true}
              effect="fade"
              slidesPerView={1}
              onSwiper={(swiper) => {
                if (swiper != null) setSwiper(swiper);
              }}
              onSlideChange={(swiper) => {
                slideChanged(swiper.realIndex);
              }}
            >
              <SwiperSlide key={0}>
                <div>{getRunningTargets()}</div>
              </SwiperSlide>
              <SwiperSlide key={1}>
                <div>{getPastTargets()}</div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      {errorcame && !target ? (
        <div>
          <ErrorScreen />
        </div>
      ) : null}
    </div>
  );
}
