import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { sendEventToAlooma } from "../../../utilities/ManageEvents";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import arrow from "../../../assets/right-arrow.svg";
import { AppContext } from "../../../App";
function Transaction(props) {
  const context = useContext(AppContext);
  const [transaction, setTransaction] = useState({
    CHEQUE: "Cheque No.",
    DPN: "DPN",
    NACH: "NACH",
    snackbarOpen: false,
  });
  useEffect(() => {
    context.header.set({ type: "PAGE_NAME", text: "JPay Later" });
  }, []);
  const getClearBouncedAmtCTA = () => {
    const { txn: transaction, bzid } = props;
    // window.bzid = bzid;
    const sendCTAEvent = (eventName) => {
      const { paymentInstrumentDetails } = transaction;
      var eventData = {
        business_id: context.user.get.bzId,
        //business_id: "BZID-1304438756",
        payment_instrument_id: paymentInstrumentDetails.paymentInstrumentId,
        timestamp: new Date().getTime(),
      };
      sendEventToAlooma(eventName, eventData);
    };

    ///////Disable Pay Now feature
    return null;

    if (true) {
      return (
        <NavLink
          className="pay-now"
          to={{
            // pathname: "/pay",
            //CODE:J24DEMO
            pathname: "/",
            state: { ...transaction, bzid: bzid },
          }}
          onClick={() => sendCTAEvent("ON_CLICK_CREDIT_HOME_BOUNCE_PAYMENT")}
        >
          {transaction.onlinePaymentDisplayText}
        </NavLink>
      );
    }

    const onClickNotUpdatedCTA = () => {
      this.setState({ snackbarOpen: true });
      sendCTAEvent("ON_CLICK_CREDIT_HOME_BOUNCE_PAYMENT_UNSUPPORTED");
    };

    // showing a notify to update app
    return (
      <div className="pay-now" onClick={onClickNotUpdatedCTA}>
        PAY NOW
        <Snackbar
          open={transaction.snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setTransaction({ snackbarOpen: false })}
          className="test2"
        >
          <SnackbarContent
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
            className="test"
            message={
              <span id="client-snackbar">
                Please update your app to use this feature
              </span>
            }
          />
        </Snackbar>
      </div>
    );
  };

  const sendClickOnTxnEvent = (status) => {
    var eventName = "ON_CLICK_CREDIT_TXN_ITEM";
    var eventData = {
      //business_id: window.bzid,
      business_id: context.user.get.bzId,
      pi_status: props.txn.paymentInstrumentDetails.paymentInstrumentStatus,
    };
    sendEventToAlooma(eventName, eventData);
  };

  const numfor = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });

  const datefor = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
  //props.txn.canBePaidOnline
  if (true) {
    var showDueDateForBouncedDateLabel =
      props.txn.paymentInstrumentDetails.bouncedDate &&
      props.txn.paymentInstrumentDetails.bouncedDate != 0
        ? "Bounced Date :"
        : "Due Date :";
    return (
      <div className="transaction-box">
        <div className="row">
          <div className="jt-col-60">
            <div className="pull-left">
              <div className="delayed-inline">
                <div className="txn-amount">
                  {/* <div
                                        className={
                                            props.txn.highlightedStatusText &&
                                                props.txn.status !== "SUBMITTED_TO_BANK"
                                                ? ""
                                                : " vertical-middle-amount"
                                        }
                                    >
                                        {numfor.format(props.txn.totalPendingAmount)}
                                    </div> */}
                </div>
                {props.txn &&
                props.txn.highlightedStatusText &&
                props.txn.status !== "SUBMITTED_TO_BANK" ? (
                  <div className="pull-right">
                    <div className={"txn-status " + props.type}>
                      {props.txn.highlightedStatusText}
                    </div>
                  </div>
                ) : null}
              </div>
              <span>
                <div style={{ marginTop: "4px" }}>
                  <span className="txn-label">
                    {showDueDateForBouncedDateLabel}
                  </span>{" "}
                  <span className="txn-due-date">
                    {" "}
                    {datefor.format(
                      props.txn.paymentInstrumentDetails.bouncedDate &&
                        props.txn.paymentInstrumentDetails.bouncedDate != 0
                        ? props.txn.paymentInstrumentDetails.bouncedDate
                        : props.txn.dueDate
                    )}
                  </span>
                </div>

                <div style={{ marginTop: "4px" }}>
                  <span className="txn-label text-label-paymenttype">
                    {
                      transaction[
                        props.txn.paymentInstrumentDetails.paymentInstrumentType
                      ]
                    }{" "}
                    {props.txn.paymentInstrumentDetails.piRefId}
                  </span>
                </div>
              </span>
            </div>
          </div>
          <div className="jt-col-40 customFont">
            <div className="pull-right delayed-grp-ctas">
              {getClearBouncedAmtCTA()}
              <div onClick={sendClickOnTxnEvent()}>
                <NavLink
                  className="view-details"
                  to={{
                    pathname: "/txn",
                    state: {
                      transaction: props.txn,
                      provider: props.provider,
                      type: props.type,
                      bzid: props.bzid,
                    },
                  }}
                >
                  VIEW DETAILS
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  console.log(props.txn.paymentInstrumentDetails.paymentInstrumentType);

  return (
    <div onClick={sendClickOnTxnEvent()}>
      <NavLink
        to={{
          pathname: "/txn",
          state: {
            transaction: props.txn,
            provider: props.provider,
            type: props.type,
            bzid: props.bzid,
          },
        }}
      >
        <div className="transaction-box">
          <div className="row">
            <div className="jt-col-94">
              <div className="pull-left">
                {props.type === "SUBMITTED_TO_BANK" ? (
                  <div>
                    <span className="txn-label">Bank Deposit Date :</span>
                    <span className="txn-due-date">
                      {datefor.format(
                        props.txn.paymentInstrumentDetails.submittedToBankDate
                      )}
                    </span>
                  </div>
                ) : props.type === "DELAYED" ? (
                  <div>
                    <span className="txn-label">Bounce Date :</span>{" "}
                    <span className="txn-due-date">
                      {datefor.format(
                        this.props.txn.paymentInstrumentDetails.bouncedDate
                      )}
                    </span>
                  </div>
                ) : props.type === "RECENTLY_CLEARED" ? (
                  <div>
                    <span className="txn-label">Cleared Date :</span>{" "}
                    <span className="txn-due-date">
                      {" "}
                      {props.txn.paymentInstrumentDetails.clearedDate > 0
                        ? datefor.format(
                            props.txn.paymentInstrumentDetails.clearedDate
                          )
                        : datefor.format(
                            props.txn.paymentInstrumentDetails.repaidDate
                          )}
                    </span>
                  </div>
                ) : (
                  <div>
                    <span className="txn-label">Due Date :</span>
                    <span className="txn-due-date">
                      {datefor.format(props.txn.dueDate)}
                    </span>
                  </div>
                )}
                <div>
                  <span className="txn-label">
                    {transaction.CHEQUE}
                    {props.txn.paymentInstrumentDetails.piRefId}
                  </span>
                </div>
              </div>
              <div className="pull-right">
                <div className="txn-amount">
                  <div
                    className={
                      "pull-right" +
                      (props.txn.highlightedStatusText &&
                      props.txn.status !== "SUBMITTED_TO_BANK"
                        ? ""
                        : " vertical-middle-amount")
                    }
                  >
                    {numfor.format(props.txn.totalPendingAmount)}
                  </div>
                </div>
                {props.txn &&
                props.txn.highlightedStatusText &&
                props.txn.status !== "SUBMITTED_TO_BANK" ? (
                  <div>
                    <div className="pull-right" style={{ marginTop: "4px" }}>
                      <div className={"txn-status " + props.type}>
                        {props.txn.highlightedStatusText}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="jt-col-6">
              <div className="pull-right">
                <div className="sider">
                  <img src={arrow} width="30px" alt=">" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavLink>
    </div>
  );
}

export default React.memo(Transaction);
