import React, { useState, useEffect, useContext } from "react";
import Loader from "./../common/Loader.js";
import serverApiHandler from "./../apiHandler/serverApiHandler.js";
import {
  formatToIndianCurrencyRounded,
  formatDate,
  getLocalized,
} from "./../../utilities/Utils.js";
import {
  publishEvent,
  getMyOrderDetailClickBundle,
  getMyOrderDetailBundle,
} from "./../../utilities/ManageEvents.js";
import { AppContext } from "./../../App.js";
import { useLocation } from "react-router-dom";

const ItemValueDisplay = (props) => {
  const { header, value } = props;
  return (
    <div className="sales-invoice-item-div">
      <div className="row">
        <div className="col-5 hheader">{header}</div>
        <div className="col-7 vvalue">{value}</div>
      </div>
    </div>
  );
};

const SalesInvoiceItemCard = ({ name, img, qty, price }) => {
  return (
    <div className="sales-invoice-detail-row">
      <div className="row">
        <div className="col-3 centered">
          <div className="sales-invoice-img-div">
            <img className="sales-invoice-img" src={img} alt="Product" />
          </div>
        </div>
        <div className="col-9">
          <div className="header-text-div">
            <div className="header-text">{name}</div>
          </div>
          <div className="row">
            <div className="col-5">
              <div className="jt-col-100 sub-header">Qty</div>
              <div className="jt-col-100 sub-value">{qty}</div>
            </div>
            <div className="col-7">
              <div className="jt-col-100 sub-header">Total Price</div>
              <div className="jt-col-100 sub-value">
                {formatToIndianCurrencyRounded(price)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const renderSalesInvoiceItems = (items) => {
  return (
    <div className="sales-invoice-items-list-wrapper">
      <div className="hheader">Billed Items({items.length})</div>
      {items.map((item, index) => {
        return (
          <SalesInvoiceItemCard
            key={item.rowNumber}
            name={getLocalized(item.name)}
            img={item.imageUrl}
            qty={item.quantity}
            price={item.totalAmount}
          />
        );
      })}
    </div>
  );
};

const renderBillDetails = (bill, billNum) => {
  const pm = () => {
    if (bill.paymentMode) return bill.paymentMode;
    else return "N/A";
  };

  const billDetailsHelper = {
    "Invoice Amount": formatToIndianCurrencyRounded(bill.orderAmount),
    //"Bill No.": bill.billNumber,
    "Bill No.": billNum,
    Date: formatDate(bill.orderTimeStamp),
    "Payment Mode": pm(),
    Channel: bill.channel,
  };

  return (
    <>
      <div className="bill-hheader">Selected Bill Details</div>
      <div className="bill-detail-div-wrapper row-with-wrap">
        {Object.keys(billDetailsHelper).map((k) => {
          return (
            <ItemValueDisplay key={k} header={k} value={billDetailsHelper[k]} />
          );
        })}
      </div>
    </>
  );
};

const OrderDetail = ({ id, origin, billNumber }) => {
  const [orderDetail, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const context = useContext(AppContext);
  const location = useLocation();
  if (location.state) {
    billNumber = location.state.billNum;
  }

  useEffect(() => {
    let search = window.location.search;
    if (id && origin) {
      search = `?origin=${origin}&orderId=${id}`;
    } else {
      context.header.set({ type: "PAGE_NAME", text: "Order Details" });
    }
    if (!search) return;

    publishEvent(
      "ON_CLICK_ORDERS_DETAILS",
      getMyOrderDetailClickBundle(window.location.search)
    );
    setLoading(true);
    let startTime = new Date().getTime();
    serverApiHandler
      .getOrderDetails(search)
      .then((resp) => {
        let ttms = new Date().getTime() - startTime;
        publishEvent(
          "ON_LOAD_ORDER_DETAILS_PAGE",
          getMyOrderDetailBundle(resp),
          ttms
        );
        setOrderDetails(resp);
      })
      .catch((error) => {
        let ttms = new Date().getTime() - startTime;
        console.log(error);
        setOrderDetails(null);
        publishEvent("ON_FAILURE_ORDER_DETAILS_PAGE", null, ttms);
      })
      .finally((resp) => {
        setLoading(false);
      });
  }, [id, origin]);

  return (
    <React.Fragment>
      <div className="j24-sales-invoice-details-wrapper">
        {loading ? <Loader /> : null}
        {orderDetail ? (
          <div>
            {renderBillDetails(orderDetail, billNumber)}
            {renderSalesInvoiceItems(orderDetail.orderItems)}
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default OrderDetail;
