import React, { useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { AppContext } from '../../../App.js';
import CoreCard from './../card/CoreCard.js';

export default function HorizontalScrollWithLeftTitleLogo({val}) {
	const {childNodes} = val;
	const {showWebView} = useContext(AppContext)
	console.log(showWebView? "auto": 2.1)
	return(
		<div className="group">
			<div className="group-left-title">{val.entityData.displayTitle.englishText}</div>
			<div className="HSWLTL-children">
				<Swiper effect="fade" slidesPerView={showWebView? "auto": 2.1} spaceBetween={15} style={{padding: '10px'}}>
  					{childNodes.map((val, i) => { 
  						return <SwiperSlide key={i}><CoreCard val = {val} /></SwiperSlide>
  					})}
				</Swiper>	
				</div>
		</div>
	)
}
