import React, { useEffect, useContext } from 'react';
import SearchDisplay from './../search/SearchDisplay.js';
import HomeUiNode from './HomeUiNode.js';
import StoreCover from './../store/StoreCover.js';
import StoreCoverMobile from './../store/StoreCoverMobile.js';
import { AppContext } from './../../App.js';
import { publishEvent } from './../../utilities/ManageEvents.js';

export default function HomePage() {
    const context = useContext(AppContext);
    const store = context.store.get;
    const header = context.header;
    const { showWebView } = context;

    useEffect(() => {
        document.title = 'J24 Stores - Your Store Next Door';
        document.head.innerHTML += `
        <meta name='description' content='Network of Modern Convenience Grocery stores solving for your daily needs.'/>`;
        header.set({
            type: showWebView ? 'STORE_NAME_DESKTOP' : 'STORE_NAME',
            text: '',
        });
    }, [showWebView]);

    useEffect(() => {
        publishEvent('ON_LOAD_HOME_SCREEN');
    });

    return (
        <div>
            {showWebView ? (
                <StoreCover store={store} />
            ) : (
                <StoreCoverMobile store={store} />
            )}
            <HomeUiNode />
        </div>
    );
}
