import React, { useState, useEffect, useContext } from 'react';
import serverApiHandler from './../apiHandler/serverApiHandler.js';
import {AppContext} from './../../App.js';
import UINode from './../uinode/UINode.js';
import ProductListPage from './ProductListPage.js';
import Loader from './../common/Loader.js';
import ErrorScreen from './../common/ErrorScreen.js';
import Cart from './../cart/Cart.js';
import {getLocalized} from './../../utilities/Utils.js';
import {publishEvent, getBrowseBundle} from './../../utilities/ManageEvents.js';
import {shouldShowItems} from './../../utilities/CartUtils.js';

const getUiNodeRender = (node) => {
	switch(node.pageType) {
		case 'BROWSE' : 
			return <UINode node = {node.browseResponse} />;
		case 'PRODUCT' :
			return <ProductListPage list = {node.productListResponse} />; 
		default:
			return <UINode node = {node.browseResponse} />;
	}
}

const BrowsePage = () => {
	const [browseResponse, setBrowseResponse] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [title, setTitle] = useState('Product List');

	const context = useContext(AppContext)
	const {cart, header, showWebView} = context;

	const handleResponse = (response) => {
		//console.log(response);
		if(response.browseResponse && response.browseResponse.entityData) {
			setTitle(getLocalized(response.browseResponse.entityData.displayTitle));
		} 
		setBrowseResponse(response);
	}

	useEffect(() => {
		header.set({ 
			type: showWebView ? 'SEARCH_STORE_NAME_DESKTOP' : 'SEARCH_PAGE_NAME',
			text: title })
	}, [showWebView, title])

	useEffect(() => {

		let params = window.location.search;
		setLoading(true);
		let startTime = new Date().getTime();
		serverApiHandler.getBrowseResponse(params)
			.then(response => {
				let ttms = new Date().getTime() - startTime;
				handleResponse(response)
				publishEvent('ON_LOAD_PRODUCT_LIST_SCREEN', getBrowseBundle(response), ttms)
				//setBrowseResponse(response);
			}).catch(error => {
				let ttms = new Date().getTime() - startTime;
				publishEvent('ON_FAILURE_LOAD_PRODUCT_LIST_SCREEN', null, ttms);
				setError(true);
			}).finally(() => {
				setLoading(false);
			})
	}, []);

	return(<div>
		{(browseResponse) ? <div className={shouldShowItems(cart.get) ? 'bt-mar-55' : 'h'}>{getUiNodeRender(browseResponse)}</div>: null}
		{(loading) ? <Loader /> : null}
		{(error) ? <ErrorScreen /> : null}
		{!showWebView && <Cart />}
	</div>)	
}

export default BrowsePage;