import React, { useState, useEffect } from 'react'
import Progress from '../../common/Progress'
import Slide from "@material-ui/core/Slide";
import info from "../../../assets/blue-info.svg"
import call from "../../../assets/call.png";
// import redWarning from '../../assets/img/red-warning.svg';
import Button from "@material-ui/core/Button";
import { sendEventToAlooma } from "../../../utilities/ManageEvents";

import Dialog from "@material-ui/core/Dialog";
function BusinessCredit(props) {
  const [businessCredit, setsBusinessCredit] = useState({
    appData: {},
    open: false,
    cdNum: "08045654565",
  })
  useEffect(() => {
    fetchCDNUmberFromApp()
  }, [])
  const fetchCDNUmberFromApp = () => {
    if (window.Android) {
      var number = window.Android.getHelplineNumber();
      if (number) {
        this.setState({ cdNum: number });
      }
    }
  }
  function Transition(props) {
    return <Slide direction="down" {...props} />;
  }
  const showBankDialog = () => {
    setsBusinessCredit({ open: true });
  }
  const handleClose = () => {
    setsBusinessCredit({ open: false });
  }
  const numfor = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });

  console.log(props)
  var width = 0;
  if (props.credit) {
    width = Math.abs(
      (props.credit.currentlyUsedLimit / props.credit.totalLimit) *
      100
    );
  }
  const getTotalLimit = () => {
    var credit = props.credit;
    return (
      <div>
        <div className="total-limit-dialog-header">
          <div className="pull-left">Available credit balance</div>
          <div className="pull-right">
            {numfor.format(credit.totalLimit - credit.currentlyUsedLimit)}
          </div>
        </div>
        <div className="total-limit-dialog-content">
          <div className="information" style={{ marginTop: "9px" }}>
            Total available credit balance includes unused limit and amount in
            process with bank.
          </div>
          <div
            className="information"
            style={{ height: "20px", marginTop: "20px" }}
          >
            <div className="pull-left">Available credit</div>
            <div className="pull-right">
              {numfor.format(
                credit.totalLimit -
                credit.currentlyUsedLimit -
                (credit.submittedToBankAmount
                  ? credit.submittedToBankAmount
                  : 0)
              )}
            </div>
          </div>
          <div
            className="information"
            style={{ height: "20px", marginTop: "5px" }}
          >
            <div className="pull-left">Submitted to Bank</div>
            <div className="pull-right">
              + {numfor.format(credit.submittedToBankAmount)}
            </div>
          </div>
          <div
            style={{
              borderTop: "1px solid #e3e3e3",
              marginTop: "5px",
              padding: "10px 0",
            }}
            className="information"
          >
            <div className="pull-left">Total</div>
            <div className="pull-right" style={{ fontWeight: "600" }}>
              {numfor.format(credit.totalLimit - credit.currentlyUsedLimit)}
            </div>
          </div>
        </div>
      </div>
    );
  }
  //subsidizedDays//
  console.log(props.credit.tenure)
  let repayment_Tenure = props.credit.tenure
  let repayment_subsidizedDays = props.credit.subsidizedDays
  let repayment_interestRate = props.credit.interestRate
  let repayment_iconvenienceCharges = props.credit.convenienceCharges
  return (
    <div >
      {props.credit ? (
        <div>
          <div className="business-credit-box">
            <div>
              {props.credit.isAvailable ||
                props.credit.isAvailable == null ? (
                <div>
                  <div className="centralize">
                    <div>
                      <div>
                        <div>
                          {!props.credit.isMaxReached ? (
                            <div>
                              <div className="available-label">
                                Available Credit Balance
                              </div>
                              <div className="available-balance">
                                {numfor.format(
                                  props.credit.totalLimit -
                                  props.credit.currentlyUsedLimit
                                )}
                                {true ? (
                                  <span onClick={showBankDialog}>
                                    <img
                                      src={info}
                                      alt="info"
                                      align="center"
                                      style={{
                                        width: "25px",
                                        height: "30px",
                                      }}
                                    />
                                  </span>
                                ) : null}
                              </div>
                              <Dialog
                                open={businessCredit.open}
                                TransitionComponent={Transition}
                                keepMounted
                                maxWidth="xs"
                                classes={{ paperWidthXs: "MuiDialog-paper" }}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                              >
                                {getTotalLimit()}
                                <div
                                  className="penalty-closer green-ok"
                                  onClick={handleClose}
                                >
                                  OK
                                </div>
                              </Dialog>
                            </div>
                          ) : (
                            <div>
                              <div className="unavailabel-credit top-forty">
                                {props.credit.maxReachedText}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    // <div className="upcoming-delivery">
                    //   <div>
                    //     <div className="pull-left">
                    //       <span className="credit-values-label">Upcoming delivery :</span><span className="upcoming-date"> 20 July 2018</span>
                    //     </div>
                    //     <div className="pull-right">
                    //       <div className="pull-right upcoming-amount">{numfor.format(15000)}</div>
                    //     </div>
                    //   </div>
                    // </div>
                  }
                  <div style={{ marginTop: "20px" }}>
                    <Progress width={width} />
                  </div>
                  <div className="credit-values">
                    <div>
                      <div className="pull-left">
                        <div className="credit-values-label">Used</div>
                        {!props.credit.isMaxReached ? (
                          <div className="credit-values-item">
                            {numfor.format(
                              props.credit.currentlyUsedLimit
                            )}
                          </div>
                        ) : (
                          <div className="exceeded-used ">
                            {numfor.format(
                              props.credit.currentlyUsedLimit
                            )}
                          </div>
                        )}
                      </div>
                      <div className="pull-right">
                        <div className="credit-values-label">
                          Total Credit Limit
                        </div>
                        <div className="credit-values-item pull-right">
                          {numfor.format(props.credit.totalLimit)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="repaymentTenure" style={repayment_iconvenienceCharges === null && repayment_Tenure == null && repayment_subsidizedDays == null && repayment_interestRate == null ? { display: 'none' } : { display: 'block' }}>
                    <div style={repayment_Tenure == null ? { display: 'none' } : { display: 'block' }} className="repaymentTenuretxt"><span >Credit Period</span><span className="pull-right repaymentTenurevalue">{repayment_Tenure} days</span></div>
                    <div style={repayment_subsidizedDays == null ? { display: 'none' } : { display: 'block' }} className="repaymentTenuretxt"><span >Free For</span><span className="pull-right repaymentTenurevalue">First {repayment_subsidizedDays} days</span></div>
                    {
                      repayment_interestRate == 0 ? (<div style={repayment_iconvenienceCharges == null ? { display: 'none' } : { display: 'block' }} className="repaymentTenuretxt"><span >Convenience Charges (after free days)</span><span className="pull-right repaymentTenurevalue">{repayment_iconvenienceCharges}% / day</span></div>) : (<div style={repayment_interestRate == null ? { display: 'none' } : { display: 'block' }} className="repaymentTenuretxt"><span >Interest Rate (after free days)</span><span className="pull-right repaymentTenurevalue">{repayment_interestRate}% / day</span></div>)
                    }
                  </div>
                </div>
              ) : (
                <div className="centralize">
                  <div style={{ marginBottom: "20px" }}>
                    <div className="unavailabel-credit">
                      {props.credit.unavailableText}
                    </div>
                    {props.credit.unavailableReasonText ? (
                      <div className="top-ten">
                        <span className="unavailabel-reason">
                          {props.credit.unavailableReasonText}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* {this.props.credit && this.props.credit.showCallUsCTA ? (
              <div className="centralize request-call-back">
                <div className="call-label">
                  {this.props.credit.restartUsingCreditText}
                </div>
                <div
                  style={{ marginTop: "5px" }}
                  onClick={this.sendCallUsEvent.bind(this)}
                >
                  <a
                    href={"tel:" + this.state.cdNum}
                    className="no-decor-hiper"
                  >
                    <Button
                      variant="contained"
                      size="medium"
                      className="call-btn"
                    >
                      <img src={call} width="18px" alt="call" />{" "}
                      <span style={{ marginLeft: "10px" }}>Call Us</span>
                    </Button>
                  </a>
                </div>
              </div>
            ) : null} */}
        </div>
      ) : null}
      {
        // <div className="warning-banner">
        //   <div className="row">
        //     <div className="jt-col-25" style={{marginTop:'1px'}}>
        //       <img src={redWarning} alt="redWarning" width="28px"align="center" style={{marginLeft:'10px'}}/>
        //     </div>
        //     <div className="jt-col-75 warning-text">
        //       Due to repeated bank holidays, cheque
        //       clearance status is delayed by bank.
        //     </div>
        //   </div>
        // </div>
      }
    </div>
  );

}

export default React.memo(BusinessCredit);
