import React, { useEffect, useState } from "react";
import AddProductQty from "./AddProductQty.js";
import "./style.css";
import { getLocalized } from "./../../utilities/Utils.js";

export default function Product({
  product,
  inCartWebView,
  cartDropdown,
  style,
}) {
  const [discount, setDiscount] = useState(null);
  const { title, imageUrls } = product;
  const img = imageUrls[0].thumbnail;

  useEffect(() => {
    // extracting the payout to create the discount banner
    let wl_Id = product.winningListingId;
    if (product.listingDetailsMap[wl_Id]) {
      let tmp1 = product.listingDetailsMap[wl_Id];
      //check if dicount offers exists
      if (tmp1.jtOfferBOList.length > 0) {
        let tmp2 = tmp1.jtOfferBOList[0];
        let payout = Number(tmp2.jtOfferPayout["payout"]);
        // if the discount is in percentage
        if (
          tmp2.jtOfferPayout["offerPayoutType"] ===
          "PER_UNIT_PERCENTAGE_DISCOUNT"
        ) {
          let payout_percentage = Math.round(payout * 100);
          let discount_message = `${payout_percentage}% off`;
          setDiscount(discount_message);
        }
        // if the discount is in cash
        if (
          tmp2.jtOfferPayout["offerPayoutType"] === "PER_UNIT_CASH_DISCOUNT"
        ) {
          let discount_message = `₹${payout}% off`;
          setDiscount(discount_message);
        }
      }
    }
  }, []);

  const cardClicked = () => {
    window.location.assign("/product?jpin=" + product.jpin);
  };

  // in cart - web view
  if (inCartWebView)
    return (
      <div className={"product-card"} style={style} onClick={cardClicked}>
        <div className={"row"} style={{ alignItems: "center" }}>
          <div className={"col-2 centered"}>
            <img
              className="card-img"
              src={img}
              align="center"
              alt={"product"}
            />
          </div>
          <div className={"col-4"}>
            <div className={"pad-left-15"}>
              <div className={"product-card-text"}>
                <h1 className={"card-title"}>{getLocalized(title)}</h1>
              </div>
            </div>
          </div>
          <div className={"col-6"}>
            <AddProductQty
              product={product}
              inCartWebView={true}
              cartDropdown={cartDropdown}
            />
          </div>
        </div>
      </div>
    );
  // default
  return (
    <div className={"product-card"} style={style} onClick={cardClicked}>
      <div className={"row"}>
        <div className={"col-3 centered"}>
          {discount ? <div className="discount-banner">{discount}</div> : null}
          <img className="card-img" src={img} align="center" alt={"product"} />
        </div>
        <div className={"col-9"}>
          <div className={"pad-left-15"}>
            <div className={"product-card-text"}>
              <h1 className={"card-title"}>{getLocalized(title)}</h1>
            </div>
            <div>
              <AddProductQty product={product} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
