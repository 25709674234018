import React from 'react';

import StoreOffer1 from '../../assets/offers/dec-1.jpeg';
import StoreOffer2 from '../../assets/offers/dec-2.jpeg';
import StoreOffer3 from '../../assets/offers/dec-3.jpeg';
import StoreOffer4 from '../../assets/offers/dec-4.jpeg';

import './offers.css';

const StoreOffer = () => {
    return (
        <div className='offer-container'>
            <img
                className='hennure-offer-img'
                src={StoreOffer1}
                alt='offer-image'
            />
            <img
                className='hennure-offer-img'
                src={StoreOffer2}
                alt='offer-image'
            />
            <img
                className='hennure-offer-img'
                src={StoreOffer3}
                alt='offer-image'
            />
            <img
                className='hennure-offer-img'
                src={StoreOffer4}
                alt='offer-image'
            />
        </div>
    );
};

export default StoreOffer;
