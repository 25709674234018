import {getLocalized} from './Utils.js';
import Package from './../../package.json';

let events = {
  SESSION_START : {en: 'SESSION_START', etype: 'BG', cat: 'APP LAUNCH'},
  LAUNCH_APP : {en: 'LAUNCH_APP', etype: 'UA', cat: 'APP LAUNCH'},
  ON_SUBMIT_LOGIN_REQUEST : {en: 'ON_SUBMIT_LOGIN_REQUEST', etype: 'UA', cat: 'LOGIN'},
  ON_SUBMIT_LOGOUT_REQUEST : {en: 'ON_SUBMIT_LOGOUT_REQUEST', etype: 'UA', cat: 'LOGIN'},
  ON_LOAD_LOGIN_SCREEN : {en: 'ON_LOAD_LOGIN_SCREEN', etype: 'UI', cat: 'LOGIN'},
  ON_FOCUS_PHONE_NUMBER : {en: 'ON_FOCUS_PHONE_NUMBER', etype: 'UA', cat: 'LOGIN'},
  ON_SUBMIT_PHONE_NUMBER : {en: 'ON_SUBMIT_PHONE_NUMBER', etype: 'UA', cat: 'LOGIN'},
  ON_GENERATED_OTP : {en: 'ON_GENERATED_OTP', etype: 'BG', cat: 'LOGIN'},
  ON_INVALID_PHONE_NUMBER : {en: 'ON_INVALID_PHONE_NUMBER', etype: 'UI', cat: 'LOGIN'},
  ON_LOAD_OTP_SCREEN : {en: 'ON_LOAD_OTP_SCREEN', etype: 'UI', cat: ''},
  ON_FOCUS_OTP : {en: 'ON_FOCUS_OTP', etype: 'UI', cat: 'REGISTRATION/LOGIN'},
  ON_SUCCESS_OTP_VERIFICATION : {en: 'ON_SUCCESS_OTP_VERIFICATION', etype: 'BG', cat: 'REGISTRATION/LOGIN'},
  ON_FAILURE_OTP_VERIFICATION : {en: 'ON_FAILURE_OTP_VERIFICATION', etype: 'BG', cat: 'REGISTRATION/LOGIN'},
  ON_SUBMIT_RESEND_OTP : {en: 'ON_SUBMIT_RESEND_OTP', etype: 'UA', cat: 'REGISTRATION/LOGIN'},
  ON_SUBMIT_MANUAL_OTP : {en: 'ON_SUBMIT_MANUAL_OTP', etype: 'UA', cat: 'REGISTRATION/LOGIN'},
  ON_LOAD_LOCATION_PERMISSION : {en: 'ON_LOAD_LOCATION_PERMISSION', etype: 'UI', cat: 'LOCATION'},
  ON_SUBMIT_ACCEPT_LOCATION_PERMISSION : {en: 'ON_SUBMIT_ACCEPT_LOCATION_PERMISSION', etype: 'UA', cat: 'LOCATION'},
  ON_SUBMIT_DENY_LOCATION_PERMISSION : {en: 'ON_SUBMIT_DENY_LOCATION_PERMISSION', etype: 'UA', cat: 'LOCATION'},
  ON_LOAD_STORE_LIST : {en: 'ON_LOAD_STORE_LIST', etype: 'UI', cat: 'STORE LOGIN'},
  ON_SUBMIT_STORE : {en: 'ON_SUBMIT_STORE', etype: 'UA', cat: 'STORE LOGIN'},
  ON_LOAD_HOME_SCREEN : {en: 'ON_LOAD_HOME_SCREEN', etype: 'UI', cat: 'HOME'},
  ON_FAILURE_HOME_SCREEN : {en: 'ON_FAILURE_HOME_SCREEN', etype: 'UI', cat: 'HOME'},
  ON_LOAD_HOME_PAGE_UINODE : {en: 'ON_LOAD_HOME_PAGE_UINODE', etype: 'UI', cat: 'HOME'},
  ON_CLICK_BROWSE_ITEM : {en: 'ON_CLICK_BROWSE_ITEM', etype: 'UA', cat: 'BROWSE'},
  ON_SUBMIT_SEARCH : {en: 'ON_SUBMIT_SEARCH', etype: 'UA', cat: 'SEARCH'},
  ON_LOAD_SEARCH_SCREEN : {en: 'ON_LOAD_SEARCH_SCREEN', etype: 'UI', cat: 'SEARCH'},
  ON_FAILURE_LOAD_SEARCH_SCREEN : {en: 'ON_FAILURE_LOAD_SEARCH_SCREEN', etype: 'UI', cat: 'SEARCH'},
  ON_ERROR_EMPTY_SEARCH_SCREEN : {en: 'ON_ERROR_EMPTY_SEARCH_SCREEN', etype: 'UI', cat: 'SEARCH'},
  ON_CLICK_SEARCH_ITEM : {en: 'ON_CLICK_SEARCH_ITEM', etype: 'UA', cat: 'SEARCH'},
  ON_CLICK_FILTER : {en: 'ON_CLICK_FILTER', etype: 'UA', cat: 'FILTER'},
  ON_LOAD_FILTER_SCREEN : {en: 'ON_LOAD_FILTER_SCREEN', etype: 'UI', cat: 'FILTER'},
  ON_FAILURE_FILTER_SCREEN : {en: 'ON_FAILURE_FILTER_SCREEN', etype: 'UI', cat: 'FILTER'},
  ON_CLICK_ONLY_OFFER : {en: 'ON_CLICK_ONLY_OFFER', etype: 'UA', cat: 'FILTER'},
  ON_EXPAND_FAVOURITE_BRANDS : {en: 'ON_EXPAND_FAVOURITE_BRANDS', etype: 'UA', cat: 'FILTER'},
  ON_LOAD_EXPAND_FAVOURITE_BRANDS : {en: 'ON_LOAD_EXPAND_FAVOURITE_BRANDS', etype: 'UI', cat: 'FILTER'},
  ON_FAILURE_LOAD_EXPAND_FAVOURITE_BRANDS : {en: 'ON_FAILURE_LOAD_EXPAND_FAVOURITE_BRANDS', etype: 'UI', cat: 'FILTER'},
  ON_SELECT_PACK_SIZE : {en: 'ON_SELECT_PACK_SIZE', etype: 'UA', cat: 'FILTER'},
  ON_SUBMIT_FILTER : {en: 'ON_SUBMIT_FILTER', etype: 'UA', cat: 'FILTER'},
  ON_SUBMIT_CLEAR_FILTER : {en: 'ON_SUBMIT_CLEAR_FILTER', etype: 'UA', cat: 'FILTER'},
  ON_SELECT_PV_FILTER : {en: 'ON_SELECT_PV_FILTER', etype: 'UA', cat: 'FILTER'},
  ON_LOAD_PRODUCT_IMAGE : {en: 'ON_LOAD_PRODUCT_IMAGE', etype: 'UI', cat: 'PRODUCT'},
  ON_FALIURE_PRODUCT_IMAGE : {en: 'ON_FALIURE_PRODUCT_IMAGE', etype: 'UI', cat: 'PRODUCT'},
  ON_LOAD_PRODUCT_LIST_SCREEN : {en: 'ON_LOAD_PRODUCT_LIST_SCREEN', etype: 'UI', cat: 'PRODUCT'},
  ON_LOAD_PRODUCT_LIST_RESPONSE : {en: 'ON_LOAD_PRODUCT_LIST_RESPONSE', etype: 'BG', cat: 'PRODUCT'},
  ON_FAILURE_LOAD_PRODUCT_LIST_SCREEN : {en: 'ON_FAILURE_LOAD_PRODUCT_LIST_SCREEN', etype: 'UI', cat: 'PRODUCT'},
  ON_LOAD_PRODUCT_SCREEN : {en: 'ON_LOAD_PRODUCT_SCREEN', etype: 'UI', cat: 'PRODUCT'},
  ON_ERROR_PRODUCT_NOT_FOUND : {en: 'ON_ERROR_PRODUCT_NOT_FOUND', etype: 'UI', cat: 'PRODUCT'},
  ON_ZOOM_IN_PRODUCT_IMAGE : {en: 'ON_ZOOM_IN_PRODUCT_IMAGE', etype: 'UA', cat: 'IMAGE'},
  ON_ZOOM_OUT_PRODUCT_IMAGE : {en: 'ON_ZOOM_OUT_PRODUCT_IMAGE', etype: 'UA', cat: 'IMAGE'},
  ON_SUBMIT_ADD_PRODUCT : {en: 'ON_SUBMIT_ADD_PRODUCT', etype: 'UA', cat: 'ORDER'},
  ON_SUBMIT_INCREASE_QUANTITY : {en: 'ON_SUBMIT_INCREASE_QUANTITY', etype: 'UA', cat: 'ORDER'},
  ON_SUBMIT_DECREASE_QUANTITY : {en: 'ON_SUBMIT_DECREASE_QUANTITY', etype: 'UA', cat: 'ORDER'},
  ON_FOCUS_QUANTITY : {en: 'ON_FOCUS_QUANTITY', etype: 'UA', cat: 'ORDER'},
  ON_MODIFY_QUANTITY : {en: 'ON_MODIFY_QUANTITY', etype: 'UA', cat: 'ORDER'},
  ON_SUBMIT_QUANTITY : {en: 'ON_SUBMIT_QUANTITY', etype: 'UA', cat: 'ORDER'},
  ON_TAP_GO_TO_CART : {en: 'ON_TAP_GO_TO_CART', etype: 'UA', cat: 'ORDER'},
  ON_TAP_CART_BOX : {en: 'ON_TAP_CART_BOX', etype: 'UA', cat: 'CART'},
  ON_LOAD_CART_VIEW : {en: 'ON_LOAD_CART_VIEW', etype: 'UI', cat: 'CART'},
  ON_LOAD_CART_DETAILS_SCREEN : {en: 'ON_LOAD_CART_DETAILS_SCREEN', etype: 'UI', cat: 'CART'},
  ON_FAILURE_CART_VIEW : {en: 'ON_FAILURE_CART_VIEW', etype: 'UI', cat: 'CART'},
  ON_MODIFY_CART_QUANTITY : {en: 'ON_MODIFY_CART_QUANTITY', etype: 'UA', cat: 'CART'},
  ON_REMOVE_CART_ITEM : {en: 'ON_REMOVE_CART_ITEM', etype: 'UA', cat: 'CART'},
  ON_EXPAND_CART_SUMMARY : {en: 'ON_EXPAND_CART_SUMMARY', etype: 'UA', cat: 'CART'},
  ON_COLLAPSE_CART_SUMMARY : {en: 'ON_COLLAPSE_CART_SUMMARY', etype: 'UA', cat: 'CART'},
  ON_CLICK_DELIVERY_BREAKUP_DETAILS : {en: 'ON_CLICK_DELIVERY_BREAKUP_DETAILS', etype: 'UA', cat: 'CART'},
  ON_VIEW_CART_CHANGE_ALERT : {en: 'ON_VIEW_CART_CHANGE_ALERT', etype: 'UI', cat: 'CART'},
  ON_SUBMIT_OKAY_CART_CHANGE_ALERT : {en: 'ON_SUBMIT_OKAY_CART_CHANGE_ALERT', etype: 'UA', cat: 'CART'},
  ON_SUBMIT_DISMISS_CART_CHANGE_ALERT : {en: 'ON_SUBMIT_DISMISS_CART_CHANGE_ALERT', etype: 'UA', cat: 'CART'},
  ON_SUBMIT_ACCEPT_CART_CHANGE : {en: 'ON_SUBMIT_ACCEPT_CART_CHANGE', etype: 'UA', cat: 'CART'},
  ON_SUBMIT_REJECT_CART_CHANGE : {en: 'ON_SUBMIT_REJECT_CART_CHANGE', etype: 'UA', cat: 'CART'},
  ON_CANCEL_CART_CHANGE : {en: 'ON_CANCEL_CART_CHANGE', etype: 'UA', cat: 'CART'},
  ON_CLICK_DELIVERY_NOTE : {en: 'ON_CLICK_DELIVERY_NOTE', etype: 'UA', cat: 'ORDER'},
  ON_LOAD_DELIVERY_DETAILS : {en: 'ON_LOAD_DELIVERY_DETAILS', etype: 'UI', cat: 'ORDER'},
  ON_FOCUS_DELIVERY_ADDRESS : {en: 'ON_FOCUS_DELIVERY_ADDRESS', etype: 'UA', cat: 'ORDER'},
  ON_SUBMIT_DELIVERY_ADDRESS : {en: 'ON_SUBMIT_DELIVERY_ADDRESS', etype: 'UA', cat: 'ORDER'},
  ON_SUBMIT_PLACE_ORDER : {en: 'ON_SUBMIT_PLACE_ORDER', etype: 'UA', cat: 'ORDER'},
  ON_LOAD_ORDER_CONFIRMATION : {en: 'ON_LOAD_ORDER_CONFIRMATION', etype: 'UA', cat: 'ORDER'},
  ON_SUCCESS_ORDER : {en: 'ON_SUCCESS_ORDER', etype: 'BG', cat: 'ORDER'},
  ON_FAILURE_ORDER : {en: 'ON_FAILURE_ORDER', etype: 'BG', cat: 'ORDER'},
  ON_LOAD_LOYALTY_POINTS : {en: 'ON_LOAD_LOYALTY_POINTS', etype: 'UI', cat: 'LOYALTY'},
  ON_SUBMIT_GO_TO_HOME : {en: 'ON_SUBMIT_GO_TO_HOME', etype: 'UA', cat: 'NAVIGATION'},
  ON_ERROR_INTERNET_OFF : {en: 'ON_ERROR_INTERNET_OFF', etype: 'UI', cat: 'INTERNET'},
  ON_ERROR_NO_CONNECTION : {en: 'ON_ERROR_NO_CONNECTION', etype: 'UI', cat: 'INTERNET'},
  ON_TAP_BACK_BUTTON : {en: 'ON_TAP_BACK_BUTTON', etype: 'UA', cat: 'NAVIGATION'},
  ON_TAP_HOME_BUTTON : {en: 'ON_TAP_HOME_BUTTON', etype: 'UA', cat: 'NAVIGATION'},
  ON_CLICK_MENU : {en: 'ON_CLICK_MENU', etype: 'UA', cat: 'MENU'},
  ON_SUBMIT_SHOP_BUTTON : {en: 'ON_SUBMIT_SHOP_BUTTON', etype: 'UA', cat: 'MENU'},
  ON_SUBMIT_CHANGE_STORE_BUTTON : {en: 'ON_SUBMIT_CHANGE_STORE_BUTTON', etype: 'UA', cat: 'MENU'},
  ON_SUBMIT_MY_ORDER_BUTTON : {en: 'ON_SUBMIT_MY_ORDER_BUTTON', etype: 'UA', cat: 'MENU'},
  ON_SCROLL_PRODUCT_LIST_VIEW : {en: 'ON_SCROLL_PRODUCT_LIST_VIEW', etype: 'UA', cat: 'NAVIGATION'},
  ON_SCROLL_HORIZONTAL : {en: 'ON_SCROLL_HORIZONTAL', etype: 'UA', cat: 'NAVIGATION'},
  ON_SUBMIT_MY_ORDERS : {en: 'ON_SUBMIT_MY_ORDERS', etype: 'UA', cat: 'NAVIGATION'},
  DEVICE_BACK : {en: 'DEVICE_BACK', etype: 'UA', cat: 'NAVIGATION'},
  ON_LOAD_MY_ORDERS_SCREEN : {en: 'ON_LOAD_MY_ORDERS_SCREEN', etype: 'UI', cat: 'HISTORY'},
  ON_CLICK_WHATSAPP_ICON : {en: 'ON_CLICK_WHATSAPP_ICON', etype: 'UA', cat: 'CONTACT'},
  ON_FOCUS_SEARCH_BOX : {en: 'ON_FOCUS_SEARCH_BOX', etype: 'UA', cat: 'SEARCH'},
  ON_ENTER_SEARCH_TEXT : {en: 'ON_ENTER_SEARCH_TEXT', etype: 'UA', cat: 'SEARCH'},
  ON_LOAD_AUTO_SUGGEST : {en: 'ON_LOAD_AUTO_SUGGEST', etype: 'UI', cat: 'SEARCH'},
  ON_LOAD_BROWSE_SCREEN : {en: 'ON_LOAD_BROWSE_SCREEN', etype: 'UI', cat: 'BROWSE'},
  ON_FAILURE_LOAD_BROWSE_SCREEN : {en: 'ON_FAILURE_LOAD_BROWSE_SCREEN', etype: 'UI', cat: 'BROWSE'},

  ON_LOAD_MY_ORDERS_PAGE : {en: 'ON_LOAD_MY_ORDERS_PAGE', etype: 'UI', cat: 'ORDER'},
  ON_ERROR_MY_ORDERS_PAGE : {en: 'ON_ERROR_MY_ORDERS_PAGE', etype: 'UI', cat: 'ORDER'},
  ON_CLICK_ORDERS_DETAILS : {en: 'ON_CLICK_ORDERS_DETAILS', etype: 'UA', cat: 'ORDER'},
  ON_LOAD_ORDER_DETAILS_PAGE : {en: 'ON_LOAD_ORDER_DETAILS_PAGE', etype: 'UI', cat: 'ORDER'},
  ON_FAILURE_ORDER_DETAILS_PAGE : {en: 'ON_FAILURE_ORDER_DETAILS_PAGE', etype: 'UI', cat: 'ORDER'},


  ON_SLIDE_PRODUCT_LIST : {en: 'ON_SLIDE_PRODUCT_LIST', etype: 'UA', cat: 'PRODUCT'},
  ON_CLICK_SUB_CATEGORY : {en: 'ON_CLICK_SUB_CATEGORY', etype: 'UA', cat: 'PRODUCT'},

  ON_SCROLL_PRODUCT_LIST_VIEW : {en: 'ON_SCROLL_PRODUCT_LIST_VIEW', etype: 'UA', cat: 'SCROLL'},

  ON_CLICK_BROWSE_CARD : {en: 'ON_CLICK_BROWSE_CARD', etype: 'UA', cat: 'BROWSE'}
}


export function generateUUID() {
  var d = new Date().getTime();
  if (window.performance && typeof window.performance.now === "function") {
    d += performance.now(); //use high-precision timer if available
  }
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
    c
  ) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    // eslint-disable-next-line
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

// function detectMob() {
//     const toMatch = [
//         /Android/i,
//         /webOS/i,
//         /iPhone/i,
//         /iPad/i,
//         /iPod/i,
//         /BlackBerry/i,
//         /Windows Phone/i
//     ];

//     return toMatch.some((toMatchItem) => {
//         return navigator.userAgent.match(toMatchItem);
//     });
// }


// const getDeviceInfo = () => {
//   let userAgent = navigator.userAgent;
//   if(userAgent) {
//     let sp = .match(/\((.*?)\)/)[1];
//     if(detectMob) {
//       let device = sp[2];
//       let os = sp[1];
//     } else {
//       let device = sp[0];
//       let os = sp[1];
//     }
//   }
// }


const createEventBundle = (en, ed, ttms) => {
  let existingUser = JSON.parse(localStorage.getItem('user'));
  let sessionId = JSON.parse(localStorage.getItem('session'));
  let store = JSON.parse(localStorage.getItem('selectedStore'));
  let connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
  let event = events[en];

  let bundle = {
    sid: sessionId,
    eid: generateUUID(),
    en: en,
    ets: new Date().getTime(),
    ct: connection ? connection.effectiveType : null,
    uid: existingUser ? existingUser.customerId : sessionId,
    etype: event ? event.etype : null,
    cat: event ? event.cat : null,
    cn: existingUser ? existingUser.customerName : null,
    grp: 'J24',
    av: Package.version,
    ttms: ttms
  }

  if(store) {
    bundle['bzid'] = store.businessId;
    bundle['business_id'] = store.businessId;
    bundle['sn'] = store.storeName;
  }

  if(ed) {
    for(let i in ed) {
      bundle[i] = ed[i];
    }
  }

  return bundle;
}

export function publishEvent(eventName, eventData, ttms) {
  let bundle = createEventBundle(eventName, eventData, ttms);

  var requestData = {
    event: "b2c_events",
    properties: bundle,
  };


  //console.log(requestData);

  //https://in-webhook.hevo.io/t/sjelhwtapr

  var xhttp = new XMLHttpRequest();

  xhttp.onreadystatechange = function () {
    if (this.readyState === 4 && this.status === 200) {
      //console.log("Data successfully sent");
    }
  };

  var webhookUrl = "https://in-webhook.hevo.io/t/sjelhwtapr";

  //var webhookUrl = "";

  xhttp.open("POST", webhookUrl, true);
  xhttp.setRequestHeader("Content-type", "application/json");
  xhttp.send(JSON.stringify(requestData));
}

//  Create Bundles 
export function productBundle(product) {
  if(product) {
    let listing = product.winningListingId && product.listingDetailsMap ? product.listingDetailsMap[product.winningListingId] : {};
    return {
      pid: product.jpin, 
      bc: product.ean,
      lid: product.winningListingId,
      mrp: listing.mrp,
      sp: listing.sellingPrice,
      ofid: [],
      pt: getLocalized(product.title),
      iots: listing.availableQuantity < listing.minOrderQuantity ? true : false,
      moq: listing.minOrderQuantity,
      moqm: listing.minOrderQuantityMultiples,
      qtyprlst: listing.availableQuantity
    }
  }
  return {}
}

export function getCartBundle(cart) {
  return {
    crtid : cart.cartDetails.cartID,
    totcrtit : cart.cartDetails.activeCartItems.length,
    crtamt : cart.cartDetails.cartAmount,
    delsbt : 0
  }
}

export function getMyOrdersBundle(orders) {
  return {
    totordr: orders.length
  }
}

export function getMyOrderDetailClickBundle(search) {
  let params = new URLSearchParams(search);
  let id = params.get('orderId');
  let origin = params.get('origin');
  return {
    ordid: id,
    origin: origin
  }
}

export function getMyOrderDetailBundle(order) {
  return {
    a : 'a'
  };
}

export function getOrderBundle(order) {
  return {
    orid : order.orderID,
    orstatus : order.status,
    totorit : order.numberOfOrderItems,
    totoramt : order.orderAmount
  };
}

export function getOrderItemBundle(orderItem) {
  return {
    lid : orderItem.listingId,
    qty : orderItem.quantity,
    fsp : orderItem.sellingPrice,
    oritid : orderItem.boltOrderItemId
  };
}

export function getBrowseBundle(browseItem) {
  return {
    entype : 'a',
    enid : 'a',
    grpmetaarr : 'a'
  }
  // getGroupMetadataBundle();
  // }
}

export function getGroupMetadataBundle(grp, index) {
  let obj = {}
  obj['grpid'+ index] = grp.id;
  obj['grpname'+ index] = grp.name;
  obj['cardcount'+ index] = grp.childNodes.length;
  return obj;
}

export function getAutosuggetBundle(resp) {
  return {};
}

export function getSubCategoryBundle(resp) {
  return {
    cidx: resp.index,
    ctitle: getLocalized(resp.name)
  };
}

export function getBrowseCardClickBundle(resp) {
  let e = resp.entityData;
  if(!e) return {cparams: resp.params}
  return {
    ctype: e.browseNodeType,
    clogo: e.displayLogo,
    ctitle: getLocalized(e.displayTitle),
    ceid: e.entityID,
    cparams: resp.params
  };
}

// credit manageevent

export function sendEventToAlooma(eventName, eventData) {
  eventData.eid = generateUUID();
  eventData.bzid = window.bzid;
  eventData.business_id = window.bzid;
  eventData.event_name = eventName;
  if(!eventData.timestamp) eventData.timestamp = new Date().getTime()

  var requestData = {
    event: "webapp_events",
    properties: eventData,
  };

  var xhttp = new XMLHttpRequest();

  xhttp.onreadystatechange = function () {
    if (this.readyState === 4 && this.status === 200) {
      console.log("Data successfully sent");
    }
  };

  var webhookUrl =
    "https://in.hevo.io/v1.0/receiver/eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIyNzAiLCJzb3VyY2VfaWQiOjI3MCwic291cmNlX3R5cGUiOiJXRUJIT09LIiwic291cmNlX25hbWUiOiJjdXN0b21lcl93ZWJhcHAifQ.wP6VkC3EOeVve6DnM4d3fbWAaU62cd7RVON06cyk42U";

  xhttp.open("POST", webhookUrl, true);
  xhttp.setRequestHeader("Content-type", "application/json");
  xhttp.send(JSON.stringify(requestData));
}