const CONF = {
    //URL_END_POINT: "https://hackathon.jumbotail.com/",
    URL_END_POINT: 'https://j24.store/',
    // URL_END_POINT: 'http://j24.localhost.jumbotail.com:3000/',
    ROOT_PATH: 'api/',
    DRAWER_MENU: [
        {
            id: 'MY_QR',
            // title: 'Greencard',
            //CODE:J24DEMO
            title: 'My QR',
            icon: 'qr',
            // event: 'ON_SUBMIT_MY_ORDER_BUTTON',
            href: '/qr',
        },
        {
            id: 'SHOP',
            title: 'Shop',
            icon: 'cartDark',
            event: 'ON_SUBMIT_SHOP_BUTTON',
            href: '/',
        },
        {
            id: 'SWITCH_STORE',
            title: 'Select Store',
            icon: 'storeDark',
            event: 'ON_SUBMIT_CHANGE_STORE_BUTTON',
            action: 'switchStore',
        },
        {
            id: 'MY_ORDERS',
            title: 'My Orders',
            icon: 'myOrder',
            event: 'ON_SUBMIT_MY_ORDER_BUTTON',
            href: '/order',
        },
        {
            id: 'TARGET_SCHEMES',
            // title: 'Target Schemes',
            //CODE:J24DEMO
            title: 'Cashback Schemes',
            icon: 'myOrder',
            event: 'ON_SUBMIT_TARGET_SCHEME_BUTTON',
            href: '/target-schemes',
        },
        // {
        //   id: "CREDIT",
        //   // title: 'Credit',
        //   //CODE:J24DEMO
        //   title: "JPay Later",
        //   icon: "myOrder",
        //   event: "ON_SUBMIT_CREDIT_BUTTON",
        //   href: "/credit",
        // },
        {
            id: 'GREENCARD',
            // title: 'Greencard',
            //CODE:J24DEMO
            title: 'Super Club',
            icon: 'star',
            // event: 'ON_SUBMIT_MY_ORDER_BUTTON',
            href: '/greencard',
        },

        {
            id: 'LOGIN',
            title: 'Login',
            icon: 'user',
            event: 'ON_SUBMIT_LOGIN_REQUEST',
            href: '/login',
        },
        {
            id: 'LOG_OUT',
            title: 'Log out',
            icon: 'logout',
            event: 'ON_SUBMIT_LOGOUT_REQUEST',
            action: 'logout',
        },
    ],
};
export default CONF;
