import React, { useContext, useEffect, useState } from 'react';
import InputFloating from "./../common/InputFloating.js"
import {publishEvent} from './../../utilities/ManageEvents.js';
import { AppContext } from '../../App.js';
import { useHistory } from 'react-router-dom';

export default function AddAdress(props) {
	const [addressName, setAddressName] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [flatNo, setFlatNo] = useState('');
	const [addressLine1, setAddressLine1] = useState('');
	const [validation, setValidation] = useState(false);
	const {user} = useContext(AppContext)
	const history = useHistory()

	const updateField = (e, name, val) => {
		switch(name || e.target.name ) {
			case 'addressName' :
				setAddressName(e.target.value);
				break;
			case 'phoneNumber' :
				setPhoneNumber(e.target.value);
				break;
			case 'addressLine1' :
				setAddressLine1(val || e.target.value);
				break;
			case 'flatNo' :
					setFlatNo(e.target.value);
					break;
			default :
				break;
		}
	}

	useEffect(() => {
		if(props.address) {
			updateField(null, "addressLine1", props.address)
		}
	}, [props])

	const isInvalidPhone = () => {
		if (user.get) return;
		if(phoneNumber.length !== 10) return true;
		return false;
	}

	const addNewAddress = () => {
		if(!addressName || !addressLine1 || isInvalidPhone()) {
			setValidation(true);
			return;
		}
		let address = {
			addressName: addressName,
			addressLine1: [flatNo, addressLine1].join(", "),
			phoneNumber: phoneNumber || user.get.phoneNumber
		}
		localStorage.setItem("guestAddress", JSON.stringify(address))
		return history.push("cart")
	}

	return(
		<div className="address-container">
			{!user.get && <div className="top30">
				<InputFloating type="number" name="phoneNumber" label="Phone Number *" onChange={updateField} value={phoneNumber} onFocus={()=>{publishEvent('ON_FOCUS_DELIVERY_PHONE_NUMBER')}}/>
				{(validation && isInvalidPhone()) ? <div className="s-error-text">Please add a valid Phone Number.</div> : null}
			</div>}
			<div className="top30">
				<InputFloating type="text" name="flatNo" label="House No/ Flat No/ Block No *" onChange={updateField} value={flatNo} onFocus={()=>{publishEvent('ON_FOCUS_DELIVERY_ADDRESS')}}/>
				{(validation && !flatNo) ? <div className="s-error-text">Please add your address.</div> : null}
			</div>
			<div className="top30">
				<InputFloating type="text" name="addressLine1" label="Address *" onChange={updateField} value={addressLine1} onFocus={()=>{publishEvent('ON_FOCUS_DELIVERY_ADDRESS')}}/>
				{(validation && !addressLine1) ? <div className="s-error-text">Please add your address.</div> : null}
			</div>
			<div className="top30">
				<InputFloating type="text" name="addressName" label="Save As *" onChange={updateField} value={addressName} onFocus={()=>{publishEvent('ON_FOCUS_DELIVERY_ADDRESS_NAME')}}/>
				{(validation && !addressName) ? <div className="s-error-text">Please add a Name.</div> : null}
			</div>
			<div className="confirm-btn-box">
				<div className="btm-btn-wrapper">
					<button name="addAddress" className="btn-address-place" onClick={addNewAddress}>Confirm Delivery Address</button>
				</div>	
			</div>
		</div>
	);

}