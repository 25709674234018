import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import serverApiHandler from "./../apiHandler/serverApiHandler.js";
import Loader from "./../common/Loader.js";
import { ReactComponent as Close } from "./../../assets/close-black.svg";
import check from "../../assets/check.svg";
import Address from "./Address.js";

const getGuestAddr = () => {
  const addr = JSON.parse(localStorage.getItem("guestAddress"))
  if(addr && addr.addressId) delete addr.addressId
  return addr
};

export default function SelectAddress({ setOrderAddress }) {
  const context = useContext(AppContext);
  let cart = context.cart.get;
  let session = context.session.get;
  let user = context.user.get;
  const { showWebView } = context;
  const [showList, setShowList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(
    getGuestAddr() || (user && user.addressSummaries[0])
  );

  useEffect(() => {
    if (!showList) return;
    if (!user) return setAddressList([getGuestAddr()]);
    setLoading(true);
    let userId = user ? user.customerId : session;
    serverApiHandler
      .getCustomerAddress(userId)
      .then((resp) => {
        if (resp && resp.addressSummaries && resp.addressSummaries.length > 0) {
          const guestAddr = getGuestAddr();
          if (guestAddr) setAddressList([guestAddr, ...resp.addressSummaries]);
          else setAddressList(resp.addressSummaries);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally((resp) => {
        setLoading(false);
      });
  }, [showList]);

  useEffect(() => {
    if (!selectedAddress) return;
    setShowList(false);
    setOrderAddress(selectedAddress);
  }, [selectedAddress]);

  const selectAddress = (address) => {
    setSelectedAddress(address);
    setOrderAddress(address);
  };

  const deliversTo = (
    <div className={"quick-address"}>
      <div className="flex justify-content-between">
        <div className="left-text delivers-to">Delivers to</div>
        <div
          className="right-text change-addr"
          onClick={() => setShowList(true)}
        >
          Change
        </div>
      </div>
      <div className="address-info">
        <div className="customer-main-info flex">
          {selectedAddress.addressName}{" "}
          <img className="check" src={check} alt={"check"} />
        </div>
        <div className="address-attr trunc-text-overflow">
          {selectedAddress.addressLine1}
        </div>
      </div>
    </div>
  );

  const selectAddressList = (
    <div className="address-list-wrapper">
      <div className="address-list-inner">
        <div className="flex justify-content-between address-list-header">
          <div>Select Address</div>
          <Close
            width="14px"
            fill="black"
            className={"back-btn"}
            onClick={() => {
              setShowList(false);
            }}
          />
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div>
            <div className="address-wrapper add-address">
              <a href="/address">+ Add Address</a>
            </div>
            {addressList.map((ad, i) => (
              <Address
                address={ad}
                key={i}
                selectAddress={selectAddress}
                selected={selectedAddress}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      {(showWebView || !showList) && deliversTo}
      {showList && selectAddressList}
    </>
  );
}
