import React from 'react'

function PenaltyDialoge(props) {
  const numfor = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });

  var totalPenalties = 0;
  var types = {};
  var totalPenalties = 0;
  var types = {};

  var notAllowed = ["BASE", "INTEREST", "CONVENIENCE_FEE_TAX", "CONVENIENCE_FEE"];

  props.penalties.forEach((item) => {
    if (!notAllowed.includes(item.paymentType)) {
      types[item.paymentType] = types[item.paymentType]
        ? types[item.paymentType] + item.amount
        : item.amount;
      totalPenalties += item.amount;
    }
  });
  const penaltiesData = Object.keys(types).map((pn, index) => (
    <div className="line flow" key={index}>
      <div className="pull-left smallFont">{pn}</div>
      <div className="pull-right pn-amount">{numfor.format(types[pn])}</div>
    </div>
  ));
  return (
    <div>
      <div className="penalty-header flow">
        <div className="pull-left">Total Penalty</div>
        <div className="pull-right">
          {numfor.format(totalPenalties.toFixed(2))}
        </div>
      </div>

      <div className="penalties-lines">{penaltiesData}</div>

      <div className="divider"></div>
    </div>
  )
}

export default React.memo(PenaltyDialoge)
