import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { AppContext } from './../../App.js';
import serverApiHandler from './../apiHandler/serverApiHandler.js';
import Drawer from './../drawer/Drawer.js';
import PopUp from './../drawer/PopUp.js';
import back from './../../assets/back.svg';
import arrow from './../../assets/down-arrow.svg';
import cartIcon from './../../assets/cart.svg';
import close from './../../assets/close.svg';
import userImg from './../../assets/user.svg';
import dot from './../../assets/three-dot.svg';
import marker from './../../assets/marker.svg';
import whatsapp from './../../assets/whatsapp.svg';
import search from './../../assets/search.svg';
import call from './../../assets/call.svg';
import callWhite from './../../assets/call-white.svg';
import graycall from './../../assets/gray-call.svg';
import homeWhite from './../../assets/home.svg';

import whatsappgreen from './../../assets/whatsapp_green.svg';
import graywhatsapp from './../../assets/gray-whatsapp.svg';

import menu from './../../assets/menu.svg';
import blackmenu from './../../assets/black_menu.svg';

import { RandomUUIDGenrator } from './../../utilities/Utils.js';
import { publishEvent } from './../../utilities/ManageEvents.js';
import './Style.css';
import SearchBoxDesktop from '../search/SearchBoxDesktop.js';
import SearchBox from '../search/SearchBox.js';

import CartDropdown from '../cart/CartDropdown.js';
import cn from 'classnames';
import trackPathForAnalytics from '../../utilities/TrackPageForAnalytics';

import mobileBanner from './../../assets/J24-Mobile-Banner.jpg';
import desktopBanner from './../../assets/J24-Desktop-Banner.jpg';

import SearchDisplay from './../search/SearchDisplay.js';

const j24ImgUrl =
    'https://jtctsmcatimages.s3.ap-south-1.amazonaws.com/logo192.png';
const mobileCoverUrl =
    'https://j24storelogoandcovers.s3.ap-southeast-1.amazonaws.com/coverpages/j24-mob.jpg';

const goBack = () => {
    publishEvent('ON_TAP_BACK_BUTTON');
    window.history.back();
};

const goHome = () => {
    publishEvent('ON_TAP_HOME_BUTTON');
    window.location.assign('/');
};

const TextHead = ({ text, showSearch }) => {
    return (
        <div>
            <div className='row'>
                <div className='col-1'>
                    <img
                        src={back}
                        alt={'back'}
                        className={'back-btn'}
                        onClick={goBack}
                    />
                </div>
                <div className='col-1'>
                    <img
                        src={homeWhite}
                        alt={'home'}
                        className={'back-btn'}
                        onClick={goHome}
                    />
                </div>
                <div className='col-9'>
                    <div className='dotted-text'>{text}</div>
                </div>
                {showSearch && (
                    <div className='col-1'>
                        <a href={'/search'}>
                            <img
                                src={search}
                                alt={'search'}
                                className={'back-btn'}
                                onClick={goBack}
                            />
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

const DefaultHeader = ({ store }) => {
    const location = useLocation();
    const showStore = location.pathname === '/hennur-launch-offers';

    return (
        <div>
            <img src={j24ImgUrl} className='head-img' alt='j24' />{' '}
            {!showStore && (store ? store.storeName : '')}
        </div>
    );
};

const LogoutDots = ({ logout }) => {
    const [open, setOpen] = useState(false);

    const changeOpen = () => {
        setOpen(!open);
    };

    return (
        <div>
            <div onClick={changeOpen}>
                <img src={dot} alt={'dots'} className={'back-btn'} />
            </div>
            {open ? (
                <div className='lgt-cta' onClick={logout}>
                    Logout
                </div>
            ) : null}
        </div>
    );
};

const handleScroll = (event) => {
    let scrollTop = document.documentElement.scrollTop;
    if (scrollTop < 90) {
        document.getElementById('ser-b-name').style.opacity = scrollTop / 50;
    } else {
        document.getElementById('ser-b-name').style.opacity = 1;
    }

    if (scrollTop < 216) {
        document.getElementById('drawer-st').style.opacity = 0;
        document.getElementById('drawer-st').style.visibility = 'hidden';
        document.getElementById('drawer-wt').style.opacity = 1;
    } else {
        let ops = (scrollTop - 216) / 84;
        document.getElementById('drawer-st').style.opacity = ops;
        document.getElementById('drawer-st').style.visibility = 'visible';
        document.getElementById('drawer-wt').style.opacity = 1 - ops;
    }
};

const StoreNameScroll = ({ store, switchStore }) => {
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        document.getElementById('drawer-st').style.visibility = 'hidden';
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div id='ser-b-name' onClick={switchStore}>
            {store ? store.storeName : 'J24'} <img src={arrow} alt={'ar'} />{' '}
        </div>
    );
};

const StoreInfo = ({ store, switchStore, showAddress }) => {
    return (
        <>
            <div onClick={switchStore}>
                {store ? store.storeName : 'J24'}
                <img className='left-5' src={arrow} alt={'ar'} />
            </div>
            {showAddress && store ? (
                <div className='store-s-address'>
                    <img src={marker} className='marker' alt={'mrk'} />
                    {store.storeShortAddress}
                </div>
            ) : null}
        </>
    );
};

const StoreContactInfo = ({ store, switchStore }) => {
    return (
        <>
            <div className='store-contact-wrapper'>
                <div className='row'>
                    <div className='col-9'>
                        <div
                            className='store-info-name dotted-text'
                            style={{ fontWeight: '500' }}
                        >
                            {store.storeName}
                        </div>
                        <div
                            className='top-six store-s-address'
                            style={{ color: '#8F8F8F' }}
                        >
                            {store.storeShortAddress}
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className='row'>
                            <div className='col-6 centered'>
                                {store && store.phoneNumber ? (
                                    <a
                                        href={'tel:' + store.phoneNumber}
                                        rel='noopener noreferrer'
                                        onClick={() => {
                                            return publishEvent(
                                                'ON_CLICK_CALL_ICON',
                                                {
                                                    num: store.phoneNumber,
                                                }
                                            );
                                        }}
                                    >
                                        <img
                                            src={call}
                                            alt={'call'}
                                            className={'back-btn'}
                                        />
                                    </a>
                                ) : null}
                            </div>
                            <div className='col-6 centered'>
                                {store && store.phoneNumber ? (
                                    <a
                                        href={
                                            'https://wa.me/' + store.phoneNumber
                                        }
                                        rel='noopener noreferrer'
                                        onClick={() => {
                                            return publishEvent(
                                                'ON_CLICK_WHATSAPP_ICON',
                                                {
                                                    num: store.phoneNumber,
                                                }
                                            );
                                        }}
                                        target='_blank'
                                    >
                                        <img
                                            src={whatsappgreen}
                                            alt={'whatsapp'}
                                            className={'back-btn'}
                                        />
                                    </a>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const ContactPopUp = ({ store, open, closeAction }) => {
    const context = useContext(AppContext);
    const { showWebView } = context;
    return (
        <PopUp open={open} close={closeAction}>
            <div
                style={{
                    minWidth: showWebView ? '700px' : 'calc(100% - 20px)',
                }}
            >
                <div className='top-twenty centered'>
                    <div className='touch-head'>Get In Touch</div>
                    <div className='touch-line'>
                        Here’s how you can reach out to us.
                    </div>
                </div>
                <div
                    className='top-fifty centered'
                    style={{ marginTop: '80px' }}
                >
                    <div className='row'>
                        <div className='col-6'>
                            <div>
                                <img src={graycall} />
                            </div>
                            <div className='touch-line'>
                                Call us directly at
                            </div>
                            <div className='touch-number top-ten'>
                                +{store.phoneNumber}
                            </div>
                        </div>
                        <div className='col-6'>
                            <div>
                                <img src={graywhatsapp} />
                            </div>
                            <div className='touch-line'>Chat with us at</div>
                            <div className='touch-number top-ten'>
                                +{store.whatsappNumber}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='top-twenty'></div>
            </div>
        </PopUp>
    );
};

export default function Header({ type, pageText }) {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [openContactPopUp, setOpenContactPopUp] = useState(false);
    const history = useHistory();
    const { pathname, search } = useLocation();
    console.log(process.env.NODE_ENV, 'process');

    if (pathname.includes('/n/')) {
        document.body.style.display = 'block';
        window.location.replace(
            `https://j24.store/n/?pathname=${pathname}&from=old`
        );
    } else {
        document.body.style.display = 'block';
    }

    console.log(`pathname: ${pathname} and search is: ${search}`);

    const context = useContext(AppContext);
    const store = context.store.get;
    const user = context.user.get;
    const cart = context.cart.get;
    const session = context.session.get;

    if (!session) {
        let sessionId = RandomUUIDGenrator();
        localStorage.setItem('session', JSON.stringify(sessionId));
        context.session.set(sessionId);
        publishEvent('SESSION_START');
    }

    useEffect(() => {
        if (!cart && store && (user || session)) {
            let customerId = user ? user.customerId : session;
            serverApiHandler
                .fetchCart(customerId)
                .then((response) => {
                    localStorage.setItem('cart', JSON.stringify(response));
                    context.cart.set(response);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally((response) => {});
        }
    });

    const analytics = useCallback(() => {
        trackPathForAnalytics({
            path: pathname,
            search: search,
            title: pathname.split('/')[1],
        });
    }, [pathname, search]);

    useEffect(() => {
        analytics();
    }, [analytics, pathname]);

    const logout = () => {
        localStorage.removeItem('user');
        window.location.assign('/');
        context.user.set(null);
        localStorage.removeItem('guestAddress');
    };

    const switchStore = () => {
        publishEvent('ON_CLICK_STORE_NAME');
        context.store.set(null);
        localStorage.removeItem('selectedStore');
        history.push('/storeselector');
    };

    const clickeFn = (itm) => {
        if (itm.id === 'LOG_OUT') {
            logout();
        } else if (itm.id === 'SWITCH_STORE') {
            switchStore();
        }
    };

    const closeDrawer = () => {
        setOpenDrawer(false);
    };

    const openDrawerAction = () => {
        publishEvent('ON_CLICK_MENU');
        setOpenDrawer(true);
    };

    const withDrawer = (isScroll) => {
        return (
            <div>
                <div className='row'>
                    <div className='col-1'>
                        <img
                            src={menu}
                            alt={'menu'}
                            className={'back-btn'}
                            onClick={openDrawerAction}
                        />
                    </div>
                    <div className='col-8 dotted-text'>
                        {isScroll ? (
                            <StoreNameScroll
                                store={store}
                                switchStore={switchStore}
                            />
                        ) : (
                            <StoreInfo
                                store={store}
                                switchStore={switchStore}
                            />
                        )}
                    </div>
                    <div className='col-3'>
                        <div className='row'>
                            <div className='col-6 right-text'>
                                {/*{(store && store.phoneNumber) 
								?
								<a href={'https://wa.me/'+store.phoneNumber} rel="noopener noreferrer" onClick={() => {return publishEvent('ON_CLICK_WHATSAPP_ICON', {num: store.phoneNumber})}}target="_blank"><img src={callWhite} alt={'whatsapp'} className={'back-btn'}/></a>
								:
								null
							}		*/}
                                <div
                                    className='lr-20'
                                    onClick={showConnectOptions}
                                >
                                    <img
                                        src={callWhite}
                                        alt={'call'}
                                        className={'back-btn'}
                                    />
                                </div>
                            </div>
                            <div className='col-6 right-text'>
                                <a
                                    href={'/cart'}
                                    className='relative'
                                    onClick={() => {
                                        return publishEvent(
                                            'ON_TAP_GO_TO_CART'
                                        );
                                    }}
                                >
                                    {cart &&
                                    cart.cartDetails &&
                                    cart.cartDetails.activeCartItems.length >
                                        0 ? (
                                        <div className='ellipse'></div>
                                    ) : null}
                                    <img
                                        src={cartIcon}
                                        alt={'cart'}
                                        className={'back-btn'}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const withDrawerNew = (isScroll) => {
        return (
            <div style={{ margin: '-5px -16px', padding: '12px' }}>
                <div style={{ position: 'absolute' }} id='drawer-wt'>
                    <div className='row'>
                        <div className='col-2'>
                            <div
                                style={{
                                    backgroundColor: '#fff',
                                    height: '42px',
                                    width: '42px',
                                    borderRadius: '50%',
                                    lineHeight: '40px',
                                    paddingTop: '2px',
                                    textAlign: 'center',
                                    boxSizing: 'border-box',
                                    boxShadow:
                                        '0px 2px 2px rgba(0, 0, 0, 0.12)',
                                }}
                            >
                                <img
                                    src={blackmenu}
                                    alt={'menu'}
                                    className={'back-btn'}
                                    onClick={openDrawerAction}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        backgroundColor: '#62B04D',
                        margin: '-12px',
                        padding: '10px 12px',
                        opacity: '0',
                    }}
                    id='drawer-st'
                >
                    <div>{withDrawer(isScroll)}</div>
                </div>
            </div>
        );
        // let coverUrl = store && store.storeMobileCoverUrl ? store.storeMobileCoverUrl : mobileCoverUrl;
        // console.log(coverUrl);
        // return (<div>
        // <div className = {'store-cover-mob'} style={{backgroundImage : 'url('+coverUrl+')', height: '250px', margin: '-5px -16px'}}>
        // 	<div className="row">
        // 		<div className="col-2">
        // 			<div style={{backgroundColor: '#fff', height: '42px', width: '42px', borderRadius: '50%', lineHeight:'40px', paddingTop: '2px', textAlign: 'center', boxSizing: 'border-box', boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.12)'}}>
        // 			<img src={blackmenu} alt={'menu'} className={'back-btn'} onClick={openDrawerAction}/>
        // 			</div>
        // 		</div>
        // 		{/*<div className="col-8 dotted-text">
        // 			{(isScroll) ? <StoreNameScroll store={store} switchStore={switchStore}/> : <StoreInfo store={store} switchStore={switchStore}/>}
        // 		</div>*/}
        // 	</div>
        // 	<SearchDisplay />
        // 	</div>
        // 	{store ?
        // 	<div>
        // 		<StoreContactInfo store={store} switchStore={switchStore}/>
        // 	</div>:null
        // 	}
        // </div>);
    };

    const frontHead = () => {
        return (
            <div>
                <div className='row relative justify-content-between'>
                    <img src={j24ImgUrl} className='head-img' alt='j24' />
                    <div className='flex absolute center'>
                        <img
                            src={marker}
                            alt={'marker'}
                            className={'back-btn'}
                        />
                        <span className='left-5'>Bengaluru</span>
                    </div>
                    {user ? (
                        <LogoutDots logout={logout} />
                    ) : (
                        <a href='/login'>
                            <img
                                src={userImg}
                                alt={'user'}
                                className={'back-btn lr-10'}
                            />
                            <span className='lg-view'>Login | Signup</span>
                        </a>
                    )}
                </div>
            </div>
        );
    };

    const searchStoreNameWebView = (showAddress) => {
        const handleOnClickCart = () => {
            setOpenDropdown(!openDropdown);
            publishEvent('ON_TAP_GO_TO_CART');
        };
        return (
            <div>
                <div className='store-web-header'>
                    <img
                        src={menu}
                        alt={'menu'}
                        className={'back-btn'}
                        onClick={openDrawerAction}
                        style={{ width: '20px' }}
                    />
                    <img
                        src={homeWhite}
                        alt={'home'}
                        className={'back-btn'}
                        onClick={goHome}
                        style={{ marginLeft: '20px' }}
                    />
                    <div className='store-name'>
                        <div style={{ display: 'inline-block' }}>
                            <StoreInfo
                                store={store}
                                switchStore={switchStore}
                                showAddress={showAddress}
                            />
                        </div>
                    </div>
                    <div className='flex'>
                        <div>
                            {store && store.phoneNumber ? (
                                <a
                                    className='lr-10'
                                    href={'https://wa.me/' + store.phoneNumber}
                                    rel='noopener noreferrer'
                                    onClick={() => {
                                        return publishEvent(
                                            'ON_CLICK_WHATSAPP_ICON',
                                            {
                                                num: store.phoneNumber,
                                            }
                                        );
                                    }}
                                    target='_blank'
                                >
                                    <img
                                        src={whatsapp}
                                        alt={'whatsapp'}
                                        className={'back-btn'}
                                    />
                                </a>
                            ) : null}
                        </div>
                        <span
                            className={cn(
                                'relative lr-10',
                                { 'open-cart': openDropdown },
                                { 'close-cart': !openDropdown }
                            )}
                            onClick={handleOnClickCart}
                        >
                            <span className='relative'>
                                {cart &&
                                cart.cartDetails &&
                                cart.cartDetails.activeCartItems.length > 0 ? (
                                    <div className='ellipse'></div>
                                ) : null}
                                <img
                                    src={cartIcon}
                                    alt={'cart'}
                                    className={'back-btn'}
                                />
                            </span>
                            <span className='left-5'>Cart</span>
                            {openDropdown && <CartDropdown />}
                        </span>
                    </div>
                </div>
                <div className='header-search'>
                    <SearchBoxDesktop />
                </div>
            </div>
        );
    };

    const showConnectOptions = () => {
        setOpenContactPopUp(true);
    };

    const hideConnectOptions = () => {
        setOpenContactPopUp(false);
    };

    const searchStoreNameWebViewNew = (showAddress) => {
        const handleOnClickCart = () => {
            setOpenDropdown(!openDropdown);
            publishEvent('ON_TAP_GO_TO_CART');
        };
        return (
            <div
                id='xyz'
                style={{
                    margin: '-5px -16px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
                }}
            >
                <div
                    className='store-web-header'
                    style={{ padding: '5px 16px' }}
                >
                    <img
                        src={menu}
                        alt={'menu'}
                        className={'back-btn'}
                        onClick={openDrawerAction}
                        style={{ width: '20px' }}
                    />
                    <img
                        src={homeWhite}
                        alt={'home'}
                        className={'back-btn'}
                        onClick={goHome}
                        style={{ marginLeft: '20px' }}
                    />
                    <div className='store-name'>
                        <div style={{ display: 'inline-block' }}>
                            <StoreInfo
                                store={store}
                                switchStore={switchStore}
                                showAddress={showAddress}
                            />
                        </div>
                    </div>
                    <div className='flex'>
                        <div className='lr-20' onClick={showConnectOptions}>
                            <img
                                src={callWhite}
                                alt={'call'}
                                className={'back-btn'}
                            />
                        </div>
                        <span
                            className={cn(
                                'relative lr-10',
                                { 'open-cart': openDropdown },
                                { 'close-cart': !openDropdown }
                            )}
                            onClick={handleOnClickCart}
                        >
                            <span className='relative'>
                                {cart &&
                                cart.cartDetails &&
                                cart.cartDetails.activeCartItems.length > 0 ? (
                                    <div className='ellipse'></div>
                                ) : null}
                                <img
                                    src={cartIcon}
                                    alt={'cart'}
                                    className={'back-btn'}
                                />
                            </span>
                            <span className='left-5'>Cart</span>
                            {openDropdown && <CartDropdown />}
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const getMenu = () => {
        return (
            <img
                src={menu}
                alt={'menu'}
                className={'back-btn'}
                onClick={openDrawerAction}
                style={{ width: '20px' }}
            />
        );
    };

    const getHeaderRender = () => {
        switch (type) {
            case 'PAGE_NAME':
                return <TextHead text={pageText} />;
            case 'SEARCH_PAGE_NAME':
                return <TextHead text={pageText} showSearch />;
            // case 'STORE_NAME' :
            // 	return withDrawer(true);
            case 'STORE_NAME':
                return withDrawerNew(true);
            case 'STORE_NAME_DESKTOP':
                return searchStoreNameWebViewNew(true);
            case 'SEARCH_STORE_NAME':
                return withDrawer(false);
            case 'SEARCH_STORE_NAME_DESKTOP':
                return searchStoreNameWebView(false);
            case 'CITY_NAME':
                return frontHead();
            case 'MENU':
                return getMenu();
            case 'CART':
                return (
                    <div>
                        <div className='row'>
                            <div className='col-1'>
                                <img
                                    src={cartIcon}
                                    alt={'cart'}
                                    className={'back-btn'}
                                />
                            </div>
                            <div className='col-8'>
                                My Cart{' '}
                                {cart &&
                                cart.cartDetails &&
                                cart.cartDetails.activeCartItems.length > 0 ? (
                                    <span>
                                        (
                                        {
                                            cart.cartDetails.activeCartItems
                                                .length
                                        }{' '}
                                        items)
                                    </span>
                                ) : null}
                            </div>
                            <div className='col-3 right-text'>
                                <img
                                    src={close}
                                    alt={'close'}
                                    className={'back-btn'}
                                    onClick={goBack}
                                />
                            </div>
                        </div>
                    </div>
                );
            default:
                return <DefaultHeader store={store} />;
        }
    };

    return (
        <React.Fragment>
            <div
                className={cn('header', {
                    'transparent-bg':
                        type === 'MENU' ||
                        type === 'STORE_NAME_DESKTOP' ||
                        type == 'STORE_NAME',
                })}
            >
                {getHeaderRender(j24ImgUrl)}
            </div>
            <Drawer open={openDrawer} clicked={clickeFn} close={closeDrawer} />
            {store ? (
                <ContactPopUp
                    store={store}
                    open={openContactPopUp}
                    closeAction={hideConnectOptions}
                />
            ) : null}
        </React.Fragment>
    );
}
