import React, { Component } from "react";
import close from "./../../assets/close_white.png";
import "./Style.css";


export default class ProductDialog extends Component {
  getProducts(products) {
    return products.map((product, index) => {
      return (
        <div className="product" key={index}>
          <div className="title">{product.title}</div>
          <div className="image-box">
            <img
              src={product.imageUrls[0].thumbnail}
              className="image"
              alt={"productImage" + index}
            />
          </div>
        </div>
      );
    });
  }

  render() {
    return (
      <div>
        <div
          className="product-dialog-header"
          style={{ backgroundColor: this.props.backColor }}
        >
          {this.props.title}{" "}
          <img
            src={close}
            align="right"
            width="24px"
            alt="close"
            onClick={this.props.closeDialog}
          />
        </div>
        <div>{this.getProducts(this.props.products)}</div>
      </div>
    );
  }
}
