import q from "q";
import CONF from "./../config/config.js";

const createurl = (path) => CONF.URL_END_POINT + CONF.ROOT_PATH + path;

const getSiteId = () => {
  let store = JSON.parse(localStorage.getItem("selectedStore"));
  if (!store || !store.businessId) {
    return "BZID-j24";
  }
  return store.businessId;
  //return 'BZID-j24';
  //return 'BZID-1304451112';
};

const fetchTokenFromServer = async (req) => {
  const response = await fetch(createurl("customer/auth/generate"), {
    method: "POST",
    body: JSON.stringify(req),
    headers: { Accept: "application/json", "content-type": "application/json" },
  });
  const json = await response.json();
  return json.data.authToken;
};

const getToken = () => {
  let auth = JSON.parse(localStorage.getItem("auth"));
  if (auth) {
    return auth.val;
  }
};

async function setHeaders(method, url) {
  //console.log(url.substring(0, 29));
  let session = JSON.parse(localStorage.getItem("session"));
  let user = JSON.parse(localStorage.getItem("user"));
  method.headers = {
    Accept: "application/json",
    "content-type": "application/json",
  };
  method.headers["language"] = "en";
  method.headers["tenantId"] = "J24";
  method.headers["requestOrigin"] = "J24";
  method.headers["siteId"] = getSiteId();
  method.headers["pragma"] = "no-cache";
  method.headers["cache-control"] = "no-cache";
  // method.headers['appVersion'] = '134';
  // method.headers['experimentUserID'] = '0e6e214c-86e3-4596-9083-af812ed8dd52';

  // We don't want customerID in req headers for order details request due to some issue
  if (url.substring(0, 29) !== "order/order-items/description") {
    method.headers["customerID"] = user ? user.customerId : session;
  }

  method.credentials = "include";
  let token = getToken();
  if (!token) {
    if (session || user) {
      let jcId = user ? user.customerId : session;
      let appLaunchReq = {
        jcId: jcId,
        deviceId: session,
      };
      token = await fetchTokenFromServer(appLaunchReq);
      let expiry = new Date().getTime() + 3600000;
      let auth = {
        val: token,
        expiry: expiry,
      };
      localStorage.setItem("auth", JSON.stringify(auth));
      method.headers["Authorization"] = "Bearer " + token;
    }
    return method;
  } else {
    method.headers["Authorization"] = "Bearer " + token;
    return method;
  }
}

const http = async (url, method) => {
  method = await setHeaders(method, url);
  let deferred = q.defer();
  fetch(createurl(url), method)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.statusCode && response.statusCode !== 200) {
        deferred.reject({ error: response.error });
        return;
      }
      if (response.code && response.code !== 200) {
        let error = { error: response.message };
        deferred.reject({ error: error });
        return;
      }
      if (response.hasOwnProperty("data")) {
        deferred.resolve(response.data);
      } else {
        deferred.resolve(response);
      }
    })
    .catch(function (e) {
      deferred.reject(e);
    });
  return deferred.promise;
};

const http2 = async (url, method) => {
  method = await setHeaders(method, url);
  let deferred = q.defer();
  fetch(createurl(url), method)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.statusCode && response.statusCode !== 200) {
        deferred.reject({ error: response.error });
        return;
      }
      if (response.code && response.code !== 200) {
        let error = { error: response.message };
        deferred.reject({ error: error });
        return;
      }

      deferred.resolve(response);
    })
    .catch(function (e) {
      deferred.reject(e);
    });
  return deferred.promise;
};

const apiHandler = {
  // Alias to make POST API http calls.
  post: function (url, data) {
    return http(url, { method: "POST", body: JSON.stringify(data) });
  },

  // Alias to make PUT API http calls.
  put: function (url, data) {
    return http(url, { method: "PUT", body: JSON.stringify(data) });
  },

  // Alias to make GET API http calls.
  get: function (url, params) {
    return http(url, { method: "GET", ...params }, "application/json");
  },

  get2: function (url, params) {
    return http2(url, { method: "GET", ...params }, "application/json");
  },

  getcradit: function (url, params) {
    return http(url, { method: "GET" }, params);
  },
};

export default apiHandler;
