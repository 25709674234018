import React from "react";
import TabProductList from "./TabProductList";
import CarouselWithIndicator from "./CarouselWithIndicator.js";
import HorizontalScrollWithLeftTitleLogo from "./HorizontalScrollWithLeftTitleLogo.js";
import CarouselWithOutIndicator from "./CarouselWithOutIndicator.js";
import GridWith3Columns from "./GridWith3Columns.js";
import FullWidthCarousalWithoutIndicator from "./FullWidthCarousalWithoutIndicator.js";
// import HorizontalTabs from "./HorizontalTabs";
import {publishEvent, getBrowseBundle} from './../../../utilities/ManageEvents.js';

function getGroup(val) {
  switch (val.layoutType) {
    case "CWI":
      return <CarouselWithIndicator val={val} />;
    case "HSWLTL":
      return <HorizontalScrollWithLeftTitleLogo val={val} />;
    case "CWOI":
      return <CarouselWithOutIndicator val={val} />;
    case "GW3C":
      return <GridWith3Columns val={val} />;
    case "FWCWOI":
      return <FullWidthCarousalWithoutIndicator val={val} />;
    case "HT":
      return <TabProductList uinode={val} />;
      //return <HorizontalTabs val={val} />;
    case "TABPL":
      return <TabProductList uinode={val} />; 
    default :
      publishEvent('ON_ERROR_UNKNOWN_BROWSE_LAYOUT', getBrowseBundle(val));
      return '';
  }
}

export default function CoreGroup({val}) {
    return <div>{getGroup(val)}</div>;
}
