import React from 'react';
import ImageWith2TextMediumCard from './ImageWith2TextMediumCard.js';
import CIOLC from './CIOLC.js';
import Tab from './Tab.js';
import ImageWith1TextMediumCard from './ImageWith1TextMediumCard.js';
import RoundImageWith1TextMediumCard from './RoundImageWith1TextMediumCard.js';
import ImageOnlyBannerCard from './ImageOnlyBannerCard.js';
import { useHistory } from "react-router-dom";
import {publishEvent, getBrowseCardClickBundle} from './../../../utilities/ManageEvents.js';

const CoreCard = (props) => {
	let history = useHistory();

	const getCard = () => {
		const {val} = props;
		switch(val.layoutType) {
			case 'I_W2T_M_C' :
				return <ImageWith2TextMediumCard val={val} clicked={cardClicked}/> 
			case 'CIOLC' : 
				return <CIOLC val={val} clicked={cardClicked}/>
			case 'TAB' :
				return <Tab val={val} clicked={cardClicked}/> 
			case 'I_W1T_M_C' : 
			case 'IWSTLFC':
			case 'RI_W1T_M_C' :
				return <ImageWith1TextMediumCard val={val} clicked={cardClicked}/>
			// case 'RI_W1T_M_C' :
			// 	return <RoundImageWith1TextMediumCard val={val} clicked={cardClicked}/> 
			case 'IOBC' : 
				return <ImageOnlyBannerCard val={val} clicked={cardClicked}/>
			default : 
				return <ImageWith2TextMediumCard val={val} clicked={cardClicked}/> 
		}
	}

	const cardClicked = (card) => {
		publishEvent('ON_CLICK_BROWSE_CARD', getBrowseCardClickBundle(card));
		switch(card.nextPageType) {
			case 'PRODUCT':
				history.push('/product?'+card.params);
				break;
			case 'PRODUCTLIST':
				history.push('/list?'+card.params);
				break;
			case 'BROWSE': 
				history.push('/browse?'+card.params);
				break;
			default : 
				break;
		}
	}

	return( <div> {getCard()}</div> )
}

export default CoreCard;