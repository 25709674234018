import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import "bootstrap/dist/css/bootstrap.css";
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    // <React.StrictMode>
    <App />,
    // </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// serviceWorker.register({
//     onUpdate: async (registration) => {
//         if (registration && registration.waiting) {
//             // remove addressId from guestAddress if it exists
//             const guestAddress = JSON.parse(
//                 localStorage.getItem('guestAddress')
//             );
//             if (guestAddress && guestAddress.addressId) {
//                 delete guestAddress.addressId;
//                 localStorage.setItem(
//                     'guestAddress',
//                     JSON.stringify(guestAddress)
//                 );
//             }
//             await registration.unregister();
//             registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//             window.location.reload();
//         }
//     },
// });
