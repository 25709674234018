import React from 'react';

export default function ImageOnlyBannerCard(props) {

	const clicked = () => {
		props.clicked(props.val);
	}
	
	const {val} = props;
	return(
		<div className="iobc-wrapper" onClick={clicked}>
			<img src={val.entityData.displayLogo} alt={val.entityData.englishText} className="iobc-img"/>
		</div>)
}