import React from 'react';
import StoreOpeningStatusIndicator from './StoreOpeningStatusIndicator.js';
import marker from './../../assets/marker-grey.svg';
import './store.css';

export default function Store(props){
	const imgUrl = 'https://jtctsmcatimages.s3.ap-south-1.amazonaws.com/logo192.png';
	const labelUrl = 'https://j24storelogoandcovers.s3.ap-southeast-1.amazonaws.com/logos/j24-prof.jpg';
	const clicked = () => {
		props.storeSelected(props.st);
	}

	const {st, showDistance} = props;
	let url = st.storeLabelUrl ? st.storeLabelUrl : labelUrl;
	return(
		<div className="store-wrapper" onClick={clicked}>
			<div className="row">
				<div className="col-3" style={{lineHeight: '75px', textAlign: 'center'}}>
					<img src={url} alt={'j24-store'} style={{maxWidth:'100%', verticalAlign:'middle'}} className={'store-label-img'}/>
				</div>
				<div className="col-9">
					<div style={{paddingLeft: '15px'}}>
					<div className="store-name">{st.storeName}</div>
					<div className="store-address"><img src={marker} style={{height: '12px'}} alt={'store'}/> {st.storeShortAddress}</div>
					<div className="top-ten">
						<StoreOpeningStatusIndicator openTime={st.storeOpeningTime} closingTime={st.storeClosingTime}/>
						{(showDistance) ? <div className="distance-indicator">{st.distance} Kms</div> : null}
					</div>
					</div>
				</div>
			</div>
		</div>)
}