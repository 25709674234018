import React, {useContext} from 'react';
import BackDrop from './BackDrop.js';
import CONF from './../../config/config.js';
import cartDark from './../../assets/cart-dark.svg';
import storeDark from './../../assets/store-dark.svg';
import myOrder from './../../assets/my-order.svg';
import userIcon from './../../assets/user-dark.svg';
import logout from './../../assets/logout.svg';
import close from './../../assets/close-black.svg';
import {AppContext} from './../../App.js';
import {publishEvent} from './../../utilities/ManageEvents.js';
import './Style.css';

export default function PopUp(props){
	const {open} = props;
	let popUpClasses = (open) ? 'pop-up open' :'pop-up';
    const context = useContext(AppContext);
    const user = context.user.get;

    return(
		<>
			{(open)? 
			<>
				<BackDrop onClick={props.close}/> 
				<div className={popUpClasses}>
					<div className="close-popup">
						<img src={close} alt={'close'} style={{height:'12px', width: '12px'}} onClick={props.close}/>
					</div>
					{props.children}
				</div>
			</>
			: null}
		</>
	)
}
