import React, { useState, useEffect } from "react";
import TargetBar from "./TargetBar.js";
import { NavLink } from "react-router-dom";
import { publishEvent } from "./../../utilities/ManageEvents.js";
import "./Style.css";

export default function Target({ target, page }) {
  const [RUNNINGMAIN] = useState({
    image: "green",
    lineColor: "#58a159",
    backColor: "#d8d8d8",
    unAchivedFlag: "gray",
    completedColor: "#58a159",
    achievedMileTextColor: "#58a159",
    textColor: "#111111"
  });

  const [PASTMAIN] = useState({
    image: "black",
    lineColor: "#606060",
    backColor: "#d8d8d8",
    unAchivedFlag: "gray",
    completedColor: "#58a159",
    achievedMileTextColor: "#111111",
    textColor: "#111111"
  });

  const [RUNNINGDETAIL] = useState({
    image: "lightGreen",
    lineColor: "#6acc6c",
    backColor: "#ffffff",
    unAchivedFlag: "white",
    completedColor: "#6acc6c",
    achievedMileTextColor: "#6acc6c",
    textColor: "#ffffff"
  });

  const [PASTDETAIL] = useState({
    image: "lightBlack",
    lineColor: "#c4cfc4",
    backColor: "#ffffff",
    unAchivedFlag: "white",
    completedColor: "#6acc6c",
    achievedMileTextColor: "#ffffff",
    textColor: "#ffffff",
  });

  const [jcEarnedMessage, setJcEarnedMessage] = useState(null);

  useEffect(() => {

    let milestones = target.schemeMemberTargetBO.targetSchemeBO.milestoneBOs;

    if (milestones && milestones.length > 0) {
      let jumbocash_earned = 0;
      for (let i = 0; i < milestones.length; i++) {
        if (milestones[i].achievedMilestoneBO) {
          jumbocash_earned += milestones[i].achievedMilestoneBO.payoutDoneValue;
        }
      }
      let message = "Total JumboCash earned: " + jumbocash_earned;
      if (jumbocash_earned > 0) {
        setJcEarnedMessage(message);
      }
    }

  }, []);

  const sendClickEvent = () => {
    let eventName = "ON_CLICK_SCHEME_CARD";
    let days_left = parseInt(
      Math.ceil(
        (target.schemeMemberTargetBO.targetSchemeBO.endTime -
          new Date().getTime) /
        86400000
      ),
      10
    );
    let eventData = {
      bzid: window.bzid,
      item_id: target.schemeMemberTargetBO.targetSchemeBO.targetSchemeId,
      status: target.schemeMemberTargetBO.targetSchemeBO.status,
      days_left: days_left,
      timestamp: new Date().getTime(),
    };
    publishEvent(eventName, eventData);
  }

  const isNew = target => {
    let targetSchemeBO = target.schemeMemberTargetBO.targetSchemeBO;
    let currentValue = target.schemeMemberTargetBO.currentValue;
    let currentTime = new Date().getTime();
    let schemeRunningFrom = currentTime - targetSchemeBO.startTime;
    if (schemeRunningFrom > 0) {
      let runningDays = parseInt(Math.floor(schemeRunningFrom / 86400000), 10);
      if (runningDays <= 3 && currentValue === 0) {
        return <div className="new-target-alert pull-right">NEW</div>;
      }
    }
    return <span className="new-target-alert">NEW</span>;
  }

  const getRightAlert = () => {
    let targetSchemeBO = target.schemeMemberTargetBO.targetSchemeBO;
    let currentValue = target.schemeMemberTargetBO.currentValue;
    let milestoneBOs = targetSchemeBO.milestoneBOs;
    milestoneBOs.sort(function (a, b) {
      return a.atValue > b.atValue ? 1 : b.atValue > a.atValue ? -1 : 0;
    });

    let maxVal = milestoneBOs[milestoneBOs.length - 1].atValue;
    if (maxVal <= currentValue) {
      return <div className="target-completed-alert pull-right">Completed</div>;
    }

    let dates = target.displayData.subtitle.split(" ");

    dates.forEach(function (item, i, dates) {
      if (i === 0 || i === 1) dates[i] = "";
      else dates[i] += " ";
    });

    let currentTime = new Date().getTime();

    if (currentTime > targetSchemeBO.endTime) {
      return (
        <div className="target-validity" style={{ textAlign: "right" }}>
          Valid from
          <br />
          {dates.join("")}
        </div>
      );
    }

    let schemeDuration = targetSchemeBO.endTime - targetSchemeBO.startTime;
    let remainingTime = targetSchemeBO.endTime - currentTime;
    if ((remainingTime / schemeDuration) * 100 < 30) {
      let days = parseInt(Math.ceil(remainingTime / 86400000), 10);
      return <div className="days-left-alert pull-right">{days} days left</div>;
    }

    return (
      <div className="target-validity" style={{ textAlign: "right" }}>
        Valid from
        <br />
        {dates.join("")}
      </div>
    );
  }

  let milestoneBOs = target.schemeMemberTargetBO.targetSchemeBO.milestoneBOs;
  milestoneBOs.sort(function (a, b) {
    return a.atValue > b.atValue ? 1 : b.atValue > a.atValue ? -1 : 0;
  });

  let alterResponseWithJumboCoinsToJumboCash = (resText) => {
    let wordArray = resText.split(" ");
    let modifiedText = "";
    for (var i = 0; i < wordArray.length; i++) {
      if (wordArray[i] === "Jumbocoin" || wordArray[i] === "Jumbocoins") {
        wordArray[i] = "Jumbocash";
      }
    }
    return wordArray.join(" ").trim();
  }

  return (
    <div>
      <NavLink to={{
        pathname: "target-details",
        state: { target: target }, search: "?targetId=" + target.schemeMemberTargetBO.targetSchemeBO.targetSchemeId,
      }} style={{ textDecoration: "none" }}>
        <div onClick={sendClickEvent}>
          <div className="target-outer">
            <div className="row">
              <div className="jt-col-70">
                <div className="target-title">{target.displayData.title}</div>
                <div className="target-validity">{alterResponseWithJumboCoinsToJumboCash(target.displayData.description)}</div>
              </div>
              <div className="jt-col-30">{getRightAlert()}</div>
            </div>
            <div>
              <TargetBar schemeMemberTarget={target.schemeMemberTargetBO} page={page} />
            </div>

            {jcEarnedMessage ? (
              <div className="target-message">
                <div className="message-container">
                  {jcEarnedMessage}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </NavLink>
    </div>
  );
}