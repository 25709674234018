import apiHandler from "../../utilities/apiHandler.js";

const serverApiHandler = {
  appLaunch: function (request) {
    let URL = "customer/auth/generate";
    return apiHandler.post(URL, request);
  },

  getAllStoreToShow: function (params) {
    let URL = "pos-details/online-ordering/stores" + params;
    return apiHandler.get(URL);
  },

  generateOtp: function (request) {
    let URL = "otp/generate";
    return apiHandler.put(URL, request);
  },

  resendOtp: function (request) {
    let URL = "otp/resend";
    return apiHandler.put(URL, request);
  },

  validateOtp: function (request) {
    let URL = "customer/upsert";
    return apiHandler.post(URL, request);
  },

  getBrowseResponse: function (params) {
    var URL = "uinode/v3/layout" + params;
    return apiHandler.get(URL);
  },

  getProductListResponse: function (params) {
    var URL = "product-details/v3/list" + params;
    return apiHandler.get(URL);
  },

  getByJpin: function (jpin) {
    var URL = "product-details/v2/jpin/" + jpin;
    return apiHandler.get(URL);
  },

  fetchCart: function (customerId) {
    var URL = "carts/customer-id/v2/" + customerId;
    return apiHandler.get(URL);
  },

  syncCart: function (request) {
    var URL = "carts/v2";
    return apiHandler.post(URL, request);
  },

  getCustomerAddress: function (customerId) {
    var URL = "customer/summary/" + customerId;
    return apiHandler.get(URL);
  },

  placeOrder: function (request) {
    var URL = "order/place-order";
    return apiHandler.post(URL, request);
  },

  getOrderList: function (customerId) {
    var URL = "order/list/customer-id/" + customerId;
    return apiHandler.get(URL);
  },

  getPaginatedOrderList: function (params) {
    var URL = "order/all-order-summary" + params;
    return apiHandler.get2(URL);
  },

  getOrderDetails: function (params) {
    var URL = "order/order-items/description" + params;
    return apiHandler.get(URL);
  },

  // getOrderDetails: function(orderId) {
  //     var URL = "order/order-details/v2/order-id/"+orderId;
  //     return apiHandler.get(URL);
  // }

  getLoyaltyBalance: function () {
    const URL = "loyalty/customer/balance/";
    return apiHandler.get(URL);
  },

  getLoyaltySavings: function () {
    const URL = "loyalty/savings/";
    return apiHandler.get(URL);
  },

  getLoyaltyHistory: function () {
    const URL = "loyalty/history";
    return apiHandler.get(URL);
  },

  getRunningTargets: function (bzid, startTime) {
    const URL =
      "target-scheme/running?memberId=" + bzid + "&currentTime=" + startTime;
    return apiHandler.get(URL);
  },

  getCompletedTargets: function (bzid, startTime) {
    const URL =
      "target-scheme/completed?memberId=" + bzid + "&currentTime=" + startTime;
    return apiHandler.get(URL);
  },

  getTargetDetailsBySmtId: function (smtId) {
    const URL = "target-scheme/smtId/" + smtId;
    return apiHandler.get(URL);
  },

  getTargetDetailsByIdAndMember: function (targetId, bzid) {
    const URL = "target-scheme/schemeId/" + targetId + "/memberId/" + bzid;
    return apiHandler.get(URL);
  },

  getTransactionsForSmtIdAndTime: function (smtId, currentTime) {
    const URL =
      "target-scheme/transactions?smtId=" +
      smtId +
      "&currentTime=" +
      currentTime;
    return apiHandler.get(URL);
  },

  getProductListByUsingJpins: function (request) {
    const URL = "product-details/info/jpin/batch-get";
    return apiHandler.post(URL, request);
  },
  getCreditDetails: function (bzid) {
    var URL = "credit/details/" + bzid;
    return apiHandler.getcradit(URL);
  },
  setUpAutoPay: function (request) {
    var URL = "credit/emandate/register";
    return apiHandler.post(URL, request);
  },
  getClearedCredit: function (bzid) {
    var URL = "credit/details/" + bzid + "/completed";
    return apiHandler.get(URL);
  },
};

export default serverApiHandler;
