import React from 'react';
import {getLocalized} from './../../../utilities/Utils.js';

export default function Tab(props) {

	const clicked = () => {
		props.clicked(props.val);
	}

	const {val} = props;
	return(
		<div className="I_W2T_B_M_C-wrapper" onClick = {clicked}>
			<div>
				<img src={val.entityData.displayBackgroundImage} className="I_W2T_B_M_C-img" alt={'product'}/>
			</div>
			<div className="card-text">{getLocalized(val.entityData.displayTitle)}</div>
			<div className="card-sub-text"><div dangerouslySetInnerHTML={{__html:getLocalized(val.entityData.displaySubtext)}}></div></div>
		</div>
	)
}